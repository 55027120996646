export const findOrgFamilyUnits = ({
    parentOrgFamUnits = [],
    rootOrgFamUnits = [],
}) => {
    if (!parentOrgFamUnits && !rootOrgFamUnits) {
        console.log('No family units!!');
        return [];
    }
    if (parentOrgFamUnits && !rootOrgFamUnits) {
        return [...parentOrgFamUnits];
    }
    if (!parentOrgFamUnits && rootOrgFamUnits) {
        return [...rootOrgFamUnits];
    }
    return [...parentOrgFamUnits, ...rootOrgFamUnits];
};

export const extractEligibleChildrenFromFamilyUnits = ({
    familyUnits = [],
    // @ts-ignore
    group,
}) =>
    familyUnits
        ?.map((familyUnit) => {
            // @ts-ignore
            const familyUnitId = familyUnit?._id;

            // @ts-ignore
            return familyUnit?.kidsList.map((kid) => {
                const childCompoundKey = `${familyUnitId}_${kid?._id}`;

                return {
                    name: kid?.name,
                    dob: kid?.dob,
                    childCompoundKey,
                    groupMembership:
                        group?.groupMemberships.includes(childCompoundKey),
                };
            });
        })
        ?.flat();

export const findChildInAssignedChildren = ({
    assignedChildren = [],
    // @ts-ignore
    childId,
    // @ts-ignore
    familyUnitId,
}) =>
    assignedChildren.find(
        (assignedChild) =>
            // @ts-ignore
            assignedChild?.familyUnitId === familyUnitId &&
            // @ts-ignore
            assignedChild?.childId === childId
    );

export const getChildAge = (dob: string) => {
    const today = new Date();
    const birthDate = new Date(dob.split('/').join('-'));
    const yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    return monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ? yearDiff - 1
        : yearDiff;
};
