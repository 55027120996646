import { ENUM_ENTITY_TYPE } from '../../constants/common';
import {
    RewardEditableFieldsInterface,
    RewardInterface,
} from '../../models/OrganizationReward';
import { baseRequest } from '../baseRequest';

export function getRewards({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(`/rewards/created-by/${entityType}/${entityId}`, {
            method: 'GET',
        }) as Promise<{
            rewards: RewardInterface[];
        }>;
}

export function getRewardByIdForEntity({
    entityId,
    entityType,
    rewardId,
}: {
    entityId: string;
    entityType: string;
    rewardId: string;
}) {
    return () =>
        baseRequest(
            `/rewards/created-by/${entityType}/${entityId}/${rewardId}`
        ) as Promise<{ reward: RewardInterface }>;
}

export function createRewardForEntity(
    {
        entityType,
        entityId,
    }: {
        entityType: ENUM_ENTITY_TYPE;
        entityId: string;
    },
    reward: RewardEditableFieldsInterface
) {
    return baseRequest(`/rewards/created-by/${entityType}/${entityId}/create`, {
        method: 'POST',
        body: reward,
    }) as Promise<{
        reward: RewardInterface;
    }>;
}

export const editRewardForEntity =
    (
        {
            entityId,
            entityType,
            rewardId,
        }: { entityId: string; entityType: string; rewardId: string },
        rewardData: RewardEditableFieldsInterface
    ) =>
    () =>
        baseRequest(
            `/rewards/created-by/${entityType}/${entityId}/edit/${rewardId}`,
            {
                body: rewardData,
                method: 'POST',
            }
        );

// export const createRewardForEntity =
//     (
//         { entityId, entityType }: { entityId: string; entityType: string },
//         rewardData: Omit<IOrganizationReward, 'createdBy'>
//     ) =>
//     () =>
//         baseRequest(`/rewards/created-by/${entityType}/${entityId}/create`, {
//             body: rewardData,
//             method: 'POST',
//         });
