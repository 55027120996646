import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { IonIcons } from 'react-ion-icon';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { MODALS } from '../constants/common';
import kkLogo from '../images/logos/kk-logo-word-white.png';
import pandaAvatar from '../images/parent-avatar.png';
import appRepository from '../stores/AppDataStore';
import userRepository from '../stores/UserDataStore';
import { clearStorage } from '../utils/localStorage';
import KKIonIcon from './KKIonIcon';
import Modal from './modals/Modal';
import { NotificationModalContent } from './modals/NotificationModal';
import axios from 'axios';
import { KK_API_URL } from '../config';
import compressImage from '../utils/ImageCompressor/Image-Compressor';
import * as LocalStorage from '../../src/utils/localStorage';
import jwtDecode from 'jwt-decode';

interface UserDetails {
    firstName: '';
    lastName: '';
    picture: '';
}

const NavbarLink: React.FunctionComponent<{
    title: string;
    to: string;
    isActive: () => boolean;
    iconName: IonIcons;
}> = ({ title, to, isActive, iconName }) => (
    <NavLink
        to={to}
        isActive={isActive}
        className="group flex items-center py-4 text-kk-light-blue"
    >
        {isActive() && (
            <div className="h-[200%] w-2 rounded-r bg-kk-dark-green" />
        )}
        <div className={`flex items-center px-4`}>
            <div className="flex w-[60px] items-center justify-center">
                <KKIonIcon
                    customStrokeWidth
                    name={iconName}
                    className={`text-2xl ${
                        isActive()
                            ? 'stroke-extrabold text-white'
                            : 'group-hover:stroke-extrabold text-kk-light-blue group-hover:text-white'
                    }`}
                />
            </div>
            <div
                className={`font-nunito ${
                    isActive()
                        ? 'font-extrabold text-white'
                        : 'text-kk-light-blue group-hover:font-extrabold group-hover:text-white'
                }`}
            >
                {title}
            </div>
        </div>
    </NavLink>
);

const Navbar: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const currentYear = new Date().getFullYear();

    const [file, setFile] = React.useState(null);

    const fileInputRef: any = React.useRef(null);
    const [avatarUrl, setAvatarUrl] = useState('');
    const [userDetails, setUserDetails] = useState<UserDetails>();

    let [attempt, setAttempt] = useState(0);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event: any) => {
        setFile(fileInputRef.current.files[0]);

        if (fileInputRef.current.files[0]) {
            handleUpload();
        }
    };

    const getUserDetails = async () => {
        try {
            // Get the idToken from localStorage
            const idToken: string | null = LocalStorage.getIdToken();

            if (!idToken || typeof idToken === 'undefined') return false;

            // Decode the JWT token
            const decodedToken: any = jwtDecode(idToken);

            // Extract user details from the decoded token
            const userDetail: any = {
                firstName:
                    decodedToken['https://kiddiekredit.com/user_metadata']
                        .firstName,
                lastName:
                    decodedToken['https://kiddiekredit.com/user_metadata']
                        .lastName,
                picture: decodedToken?.picture,
            };

            setUserDetails(userDetail);
            userRepository.setUserData(userDetail);
            // Return the user details object
            return userDetail;
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    };

    useEffect(() => {
        getUserDetails();
    }, []);

    const handleUpload = async () => {
        const extension = fileInputRef.current.files[0].type.split('/')[1];
        let compressdFile = fileInputRef.current.files[0];

        if (extension !== 'gif') {
            compressdFile = await compressImage(fileInputRef.current.files[0]);
        }
        const formData = new FormData();
        formData.append('image', compressdFile as unknown as string);

        try {
            const response = await axios.post(
                `${KK_API_URL}/uploads?filename=${fileInputRef.current.files[0].name}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'authorization': `Bearer ${localStorage.getItem(
                            'idToken'
                        )}`,
                    },
                }
            );
            setAvatarUrl(response.data.imageUrl);
            userRepository.setEntityLogo(response.data.imageUrl);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const [isHovered, setIsHovered] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="flex h-screen overflow-hidden">
            <div className="flex h-screen w-[300px] shrink-0 flex-col bg-kk-dark-blue py-10">
                <div className="flex flex-col items-center justify-center">
                    <NavLink
                        to="/dashboard"
                        className="flex items-center justify-center"
                    >
                        <img
                            className="w-3/4"
                            src={kkLogo}
                            alt="Kiddie Kredit"
                        />
                    </NavLink>
                    <h3 className="my-4">For Organizations</h3>

                    <div className="tooltip-container  my-5 w-1/2 ">
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        {userRepository?.entityLogo ? (
                            <img
                                id="profile_pic"
                                style={{
                                    cursor: isHovered ? 'pointer' : 'default',
                                    width: '100%',
                                    height: 'auto',
                                }}
                                src={`${KK_API_URL}/uploads/${userRepository?.entityLogo}`}
                                alt="Parent Avatar"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={handleClick}
                                onError={(error) => {
                                    console.log('Error while loading image ');
                                    setTimeout(() => {
                                        const imageElement =
                                            document.getElementById(
                                                'profile_pic'
                                            ) as HTMLImageElement;
                                        if (imageElement && attempt < 3) {
                                            attempt++;
                                            setAttempt(attempt);
                                            imageElement.src = `${KK_API_URL}/uploads/${userRepository?.entityLogo}`;
                                        }
                                    }, attempt * 1000);
                                }}
                            />
                        ) : (
                            <img
                                style={{
                                    cursor: isHovered ? 'pointer' : 'default',
                                    width: '100%',
                                    height: 'auto',
                                }}
                                src={pandaAvatar}
                                alt="Parent Avatar 1"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={handleClick}
                            />
                        )}

                        <span className="tooltip">
                            Click to Change or Upload a profile pic
                        </span>
                    </div>

                    <h5 className="text-white">{`${
                        userDetails?.firstName ||
                        localStorage.getItem('entityName')
                    } ${userDetails?.lastName}`}</h5>
                    <h5 className="my-4 text-kk-dark-green">
                        {userRepository?.role}
                    </h5>
                </div>
                <div className="flex grow flex-col py-5">
                    {[
                        {
                            title: 'Dashboard',
                            to: `${userRepository?.defaultDashboardUrl}`,
                            iconName: 'home-outline',
                        },
                    ].map(({ title, to, iconName }) => (
                        <NavbarLink
                            key={`dashboard-link-${title}-to-${to}`}
                            title={title}
                            to={to}
                            iconName={iconName as IonIcons}
                            isActive={() => !!pathname.match(new RegExp(to))}
                        />
                    ))}
                    <div
                        onClick={() =>
                            appRepository.setActiveModal(MODALS.LOG_OUT)
                        }
                        className="group flex items-center px-4 py-4 font-nunito text-kk-light-blue hover:cursor-pointer hover:font-extrabold hover:text-white"
                    >
                        <div className="flex w-[60px] items-center justify-center">
                            <KKIonIcon
                                customStrokeWidth
                                className={`stroke-normal group-hover:stroke-extrabold text-2xl text-kk-light-blue group-hover:text-white`}
                                name="log-out-outline"
                                id="nav-icon"
                            />
                        </div>
                        Log Out
                    </div>
                </div>
                <div className="flex items-center border-t-[1px] border-solid border-t-kk-light-blue pl-4 pt-5 text-xs text-kk-light-blue">
                    © {currentYear} KiddieKredit, Inc.
                    <br />
                    All rights reserved.
                </div>
            </div>
            <Modal
                modalName={MODALS.LOG_OUT}
                options={[
                    {
                        text: 'Cancel',
                        type: 'secondary',
                        onClick: () => appRepository.setActiveModal(null),
                    },
                    {
                        text: 'Log Out',
                        type: 'primary',
                        onClick: () => {
                            clearStorage();
                            userRepository.reset();
                            history.push('/nonauth/log-in');
                        },
                    },
                ]}
            >
                <NotificationModalContent
                    icon="user"
                    title="Log Out?"
                    body=""
                />
            </Modal>
            <div className="relative grow overflow-auto">{children}</div>
        </div>
    );
};

export default observer(Navbar);
