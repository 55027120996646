import { ENUM_ENTITY_TYPE } from '../constants/common';
import { useEntityAndParentsQuery } from './queries/entities';

const useEntityAndParents = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) => {
    const { data: entityAndParents, isLoading } = useEntityAndParentsQuery({
        entityId,
        entityType,
    });

    return { entityAndParents, isLoading };
};

export default useEntityAndParents;
