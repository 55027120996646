import { useMutation, useQueryClient } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { ALERTS_QUERY_KEY } from '../queries/alerts';
import { AlertInterface } from '../../models/Alert';
import { createAlert } from '../../services/api/alertsApi';

export const useCreateAlertMutation = ({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: AlertInterface) =>
            createAlert({ entityType, entityId, alert: variables }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    ALERTS_QUERY_KEY,
                    { entityId, entityType },
                ]);
            },
        }
    );
};
