import { DeepPartial } from 'react-hook-form';
import { FamilyUnitChildInfoInterface } from '../../models/FamilyUnit';
import { GroupInterface } from '../../models/Group';
import { RewardInterface } from '../../models/OrganizationReward';
import { baseRequest } from '../baseRequest';

export function getAllGroups(queryParams: { showArchived?: boolean } = {}) {
    return baseRequest(`/groups`, {
        method: 'GET',
        params: queryParams,
    }) as Promise<{ groups: GroupInterface[] }>;
}

export function getGroupsForOrg(orgId: string) {
    return () =>
        baseRequest(`/groups/${orgId}/organizations`, {
            method: 'GET',
        });
}

export function getGroupById(groupId: string) {
    return () =>
        baseRequest(`/groups/${groupId}`, {
            method: 'GET',
        }) as Promise<{ group: GroupInterface }>;
}

export function getGroupRewardRedemptions(groupId: string) {
    return () =>
        baseRequest(`/reward-redemptions/get/group/${groupId}`, {
            method: 'GET',
        });
}

export function addGroupReward(
    groupId: string,
    reward: {
        name: string;
        notes?: string;
        redemptionLimit?: number;
        deleted?: boolean;
    }
) {
    return () =>
        baseRequest(`/groups/${groupId}/add-reward`, {
            body: { reward },
            method: 'POST',
        });
}

export function editGroupReward(groupId: string, reward: RewardInterface) {
    return () =>
        baseRequest(`/groups/${groupId}/edit-reward`, {
            body: { reward },
            method: 'PUT',
        });
}

export function editGroupOld(groupId: string, action: string, updateData: any) {
    return () =>
        baseRequest(`/groups/${groupId}?action=${action}`, {
            body: updateData,
            method: 'PUT',
        });
}

export const getGroupsByParentOrganizationId = (
    parentOrganizationId: string,
    queryParams: { showArchived?: boolean } = {}
) =>
    baseRequest(`/groups/by-parent-organization/${parentOrganizationId}`, {
        method: 'GET',
        params: queryParams,
    }) as Promise<{ groups: GroupInterface[] }>;

export const getGroupEnrolledChildren = (groupId: string) =>
    baseRequest(`/groups/${groupId}/enrolled-children`, {
        method: 'GET',
    }) as Promise<{ children: FamilyUnitChildInfoInterface[] }>;

export type EnrollableChildrenForGroupQuery = {
    groupId: string;
    includeEnrolled?: boolean;
};

export const getGroupEnrollableChildren = (
    query: EnrollableChildrenForGroupQuery
) =>
    baseRequest(
        `/groups/${
            query.groupId
        }/enrollable-children?includeEnrolled=${!!query.includeEnrolled}`
    ) as Promise<{
        children: FamilyUnitChildInfoInterface;
    }>;

export const createGroup = ({
    parentOrganizationId,
    data,
}: {
    parentOrganizationId: string;
    data: DeepPartial<GroupInterface>;
}) =>
    baseRequest(`/groups/create-for-organization/${parentOrganizationId}`, {
        method: 'POST',
        body: data,
    }) as Promise<{ group: GroupInterface }>;

export const editGroup = ({
    groupId,
    data,
}: {
    groupId: string;
    data: DeepPartial<GroupInterface>;
}) =>
    baseRequest(`/groups/${groupId}/edit`, {
        method: 'POST',
        body: data,
    }) as Promise<{ group: GroupInterface }>;

export const enrollChildrenToGroup = (data: {
    groupId: string;
    childrenToEnroll: string[];
}) =>
    baseRequest(`/groups/${data.groupId}/enroll-children`, {
        method: 'POST',
        body: { childrenToEnroll: data.childrenToEnroll },
    }) as Promise<{
        group: GroupInterface;
    }>;

export const archiveGroup = (groupId: string) =>
    baseRequest(`/groups/${groupId}/archive`, {
        method: 'POST',
    }) as Promise<{ group: GroupInterface }>;
