import axios from 'axios';
import { KK_API_URL } from '../config';
import {
    setAccessToken,
    setIdToken,
    setUserBasicInfo,
} from '../utils/localStorage';
import { baseRequest } from './baseRequest';

export const loginWithAuth0 = async (email, password) => {
    if (typeof email === 'string') {
        email = email.trim().toLowerCase();
    }
    let loginResult = await axios.post(
        `${KK_API_URL}/auth/login`,

        {
            email,
            password,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }
    );

    if (!loginResult.statusText) {
        throw loginResult;
    }

    loginResult = loginResult.data;

    setIdToken(loginResult.signInToken.id_token);
    setAccessToken(loginResult.signInToken.access_token);
    //setUserBasicInfo(loginResult.user);
    return loginResult;
};

export const registerWithAuth0 = async (
    email,
    password,
    firstName,
    lastName
) => {
    if (typeof email === 'string') {
        email = email.trim().toLowerCase();
    }

    let result = await axios.post(
        `${KK_API_URL}/auth/register`,

        {
            email,
            password,
            firstName,
            lastName,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        }
    );

    if (!result.statusText) throw result;

    result = result.data;
    return result;
};

export const fetchLoggedUserData = async () => {
    try {
        const userData = await baseRequest('/users/me', {
            method: 'GET',
        });
        return userData;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export async function getUserRole(signInToken, user) {
    try {
        // let response = await fetch(`${KK_API_URL}/users/roles`, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Authorization': `Bearer ${signInToken.id_token}`,
        //     },

        //     method: 'GET',
        // });
        let response = await axios.get(`${KK_API_URL}/users/roles`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${signInToken.id_token}`,
            },
        });

        const data = response.data;
        const dashboardUrl = data.roles[0].defaultDashboardUrl;
        const role = data.roles[0].role;
        user.defaultDashboardUrl = dashboardUrl;
        user.role = role;
        return user;
    } catch (error) {
        console.log('Error ==>', error);
        throw new Error(error);
    }
}
