import React from 'react';
import { IonIcons } from 'react-ion-icon';
import { ENUM_ENTITY_TYPE } from '../constants/common';
import { Entity } from '../models/Entity';
import KKIonIcon from './KKIonIcon';
import ListItem from './ListItem';
import EntityTile from './tiles/EntityTile';
import { KK_API_URL } from '../config';
import kkLogo from '../../src/images/logos/kk-square-512.png';

const EntityListItem: React.FunctionComponent<{
    entity: Entity;
    options?: {
        iconName: IonIcons;
        onClick: (...args: any) => void;
        bgColorClassName: string;
    }[];
    expanded?: boolean;
    expandedOptions?: {
        iconName: IonIcons;
        iconColorClassName: string;
        label: string;
        onClick: (...args: any) => void;
    }[];
}> = ({ entity, options = [], expanded = false, expandedOptions = [] }) => {
    return (
        <ListItem className={entity.data.archived ? 'opacity-50' : ''}>
            <EntityTile
                title={entity.data.basicInformation.name}
                body={
                    entity.type === ENUM_ENTITY_TYPE.ORGANIZATION
                        ? entity.data.basicInformation.contactEmail
                        : entity.data.ownerEmail
                }
                logo={
                    entity.data.basicInformation?.logo
                        ? !entity.data.basicInformation?.logo.includes('http')
                            ? `${KK_API_URL}/uploads/${entity.data.basicInformation.logo}`
                            : entity.data.basicInformation?.logo
                        : kkLogo
                }
                to={
                    entity.type === ENUM_ENTITY_TYPE.ORGANIZATION
                        ? `/dashboard/organizations/${entity.data._id}`
                        : `/dashboard/groups/${entity.data._id}`
                }
                rightContent={() => (
                    <div className="flex h-full">
                        {options.map(
                            ({ iconName, onClick, bgColorClassName }) => (
                                <div
                                    className={`flex h-full items-center justify-center px-5 ${bgColorClassName}`}
                                    key={iconName}
                                >
                                    <div onClick={onClick}>
                                        <KKIonIcon
                                            className="cursor-pointer text-xl"
                                            name={iconName}
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                )}
                expanded={expanded}
                expandedContent={() => (
                    <>
                        <div className="flex w-full flex-grow items-center border-t-2 border-solid border-t-kk-light-grey" />
                        <div className="m-4 flex items-center">
                            {expandedOptions.map(
                                ({
                                    iconName,
                                    iconColorClassName,
                                    label,
                                    onClick,
                                }) => (
                                    <div
                                        className="group flex cursor-pointer items-center gap-1 border-2 border-black"
                                        onClick={onClick}
                                    >
                                        <KKIonIcon
                                            customStrokeWidth
                                            className={`stroke-normal group-hover:stroke-medium ${iconColorClassName}`}
                                            name={iconName as IonIcons}
                                        />
                                        <p className="text-sm text-kk-status-red group-hover:font-bold">
                                            {label}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
            />
        </ListItem>
    );
};

export default EntityListItem;
