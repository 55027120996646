import { useParams } from 'react-router-dom';
import EntityAssignments from '../../../../components/EntityAssignments';
import { ENUM_ENTITY_TYPE } from '../../../../constants/common';

const OrganizationAssignments = () => {
    const { organizationId } = useParams<{ organizationId: string }>();

    return (
        <EntityAssignments
            entityId={organizationId}
            entityType={ENUM_ENTITY_TYPE.ORGANIZATION}
        />
    );
};

export default OrganizationAssignments;
