import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack, TextInput } from '../../components/fields';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';

const assignmentDataSchema = yup.object({
    name: yup.lazy((value) =>
        value === '' ? yup.string().nullable(true) : yup.string().min(3)
    ),
    notes: yup.string().optional().default(''),
});

type AssignmentDataSchema = yup.InferType<typeof assignmentDataSchema>;

interface AssignmentDataProps {
    name?: string | null;
    notes: string;
    defaultName: string;
    onNext: (args: { name?: string | null; notes: string }) => void;
    onPrevious: (args: { name?: string | null; notes: string }) => void;
    onClose: () => void;
}

const AssignmentData: React.FunctionComponent<AssignmentDataProps> = ({
    name: initialName,
    notes: initialNotes,
    defaultName,
    onNext,
    onPrevious,
    onClose,
}) => {
    const assignmentDataForm = useForm<AssignmentDataSchema>({
        defaultValues: assignmentDataSchema.getDefault(),
        resolver: yupResolver(assignmentDataSchema),
    });

    const onSubmit = (
        cb: AssignmentDataProps['onNext'] | AssignmentDataProps['onPrevious']
    ) =>
        assignmentDataForm.handleSubmit(({ name, notes }) => {
            cb({ name, notes });
        });

    useEffect(() => {
        assignmentDataForm.reset({
            name: initialName,
            notes: initialNotes,
        });
    }, [initialName, initialNotes]);

    return (
        <Modal
            modalName={MODALS.CREATE_ASSIGNMENT}
            title="Assignment Data"
            options={[
                {
                    text: 'Previous',
                    type: 'secondary',
                    onClick: () =>
                        onPrevious({ ...assignmentDataForm.getValues() }),
                },
                { text: 'Next', type: 'primary', onClick: onSubmit(onNext) },
            ]}
            onClose={onClose}
        >
            <Stack>
                <TextInput
                    form={assignmentDataForm}
                    name="name"
                    label="Assignment Name"
                    placeholder={defaultName}
                />
                <TextInput
                    form={assignmentDataForm}
                    name="notes"
                    label="Assignment Description"
                    multiline
                />
            </Stack>
        </Modal>
    );
};

export default AssignmentData;
