import { useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardContentSection from '../../components/DashboardContentSection';
import EntityListItem from '../../components/EntityListItem';
import Navbar from '../../components/Navbar';
import DashboardTiles from '../../components/tiles/DashboardTiles';
import { navTiles } from '../../components/tiles/navTiles';
import { ENUM_ENTITY_TYPE, MODALS } from '../../constants/common';
import {
    ARCHIVE_ORGANIZATION_MUTATION_TYPE,
    useArchiveOrganizationMutation,
} from '../../hooks/mutations/organizations';
import { GROUPS_QUERY_TYPE } from '../../hooks/queries/groups';
import {
    ORGANIZATIONS_QUERY_TYPE,
    useEnrolledFamilyCountQuery,
} from '../../hooks/queries/organizations';
import useGroupsList from '../../hooks/useGroupsList';
import useOrganizationsList from '../../hooks/useOrganizationsList';
import OrganizationFormModal from '../../forms/OrganizationFormModal';
import appRepository from '../../stores/AppDataStore';

const Dashboard = () => {
    const [expandedTile, setExpandedTile] = useState<string | null>(null);

    const organizationsList = useOrganizationsList({
        type: ORGANIZATIONS_QUERY_TYPE.ALL_ORGANIZATIONS,
    });

    const subOrganizationsList = useOrganizationsList({
        type: ORGANIZATIONS_QUERY_TYPE.ALL_SUBORGANIZATIONS,
    });

    const groupsList = useGroupsList({
        type: GROUPS_QUERY_TYPE.ALL_GROUPS,
    });

    const enrolledFamilyUnitsCount = useEnrolledFamilyCountQuery();

    const { mutateAsync: archiveOrganization } = useArchiveOrganizationMutation(
        {
            type: ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_ORGANIZATION_ADMIN,
        },
        { onSuccess: () => setExpandedTile(null) }
    );

    const expandTile = (tileId: string) => () =>
        setExpandedTile(expandedTile === tileId ? null : tileId);

    return (
        <Navbar>
            <Breadcrumbs />
            <DashboardContentSection>
                <h2>Dashboard</h2>
            </DashboardContentSection>
            <DashboardContentSection>
                <DashboardTiles
                    tiles={[
                        navTiles.organizations(
                            organizationsList.data?.filter(
                                ({ archived }) => !archived
                            ).length
                        ),
                        navTiles.subOrganizations(
                            subOrganizationsList.data?.filter(
                                ({ archived }) => !archived
                            ).length
                        ),
                        navTiles.groups(
                            groupsList.data?.filter(({ archived }) => !archived)
                                .length
                        ),
                        navTiles.familyUnits(enrolledFamilyUnitsCount.data),
                    ]}
                />
            </DashboardContentSection>
            <DashboardContentSection
                title="Organizations"
                headerButtons={[
                    {
                        label: '+ New Organization',
                        type: 'primary',
                        onClick: () => {
                            appRepository.setActiveModal(
                                MODALS.CREATE_ORGANIZATION
                            );
                        },
                    },
                    {
                        label: organizationsList.showArchived
                            ? 'Hide Archived'
                            : 'Show Archived',
                        type: 'secondary',
                        onClick: organizationsList.toggleShowArchived,
                    },
                ]}
                loading={organizationsList.isFetching}
            >
                {Array.isArray(organizationsList.data) &&
                    organizationsList.data.length > 0 &&
                    organizationsList.data
                        .sort((a, b) => Number(a.archived) - Number(b.archived))
                        .filter(({ archived }) =>
                            organizationsList.showArchived ? true : !archived
                        )
                        .map((entity) => (
                            <EntityListItem
                                key={entity._id}
                                entity={{
                                    data: entity,
                                    type: ENUM_ENTITY_TYPE.ORGANIZATION,
                                }}
                                expanded={expandedTile === entity._id}
                                options={[
                                    {
                                        iconName: 'pencil',
                                        onClick: expandTile(entity._id),
                                        bgColorClassName:
                                            'bg-kk-background-blue',
                                    },
                                ]}
                                expandedOptions={[
                                    {
                                        iconName: 'archive-outline',
                                        iconColorClassName:
                                            'text-kk-status-red',
                                        label: entity.archived
                                            ? 'Unarchive'
                                            : 'Archive',
                                        onClick: () =>
                                            archiveOrganization(entity._id),
                                    },
                                ]}
                            />
                        ))}
            </DashboardContentSection>
            <DashboardContentSection
                title="Sub-Organizations"
                headerButtons={[
                    {
                        label: subOrganizationsList.showArchived
                            ? 'Hide Archived'
                            : 'Show Archived',
                        type: 'secondary',
                        onClick: subOrganizationsList.toggleShowArchived,
                    },
                ]}
                loading={subOrganizationsList.isFetching}
            >
                {Array.isArray(subOrganizationsList.data) &&
                    subOrganizationsList.data.length > 0 &&
                    subOrganizationsList.data
                        .sort((a, b) => Number(a.archived) - Number(b.archived))
                        .filter(({ archived }) =>
                            subOrganizationsList.showArchived ? true : !archived
                        )
                        .map((entity) => (
                            <EntityListItem
                                key={entity._id}
                                entity={{
                                    data: entity,
                                    type: ENUM_ENTITY_TYPE.ORGANIZATION,
                                }}
                                expanded={expandedTile === entity._id}
                                options={[
                                    {
                                        iconName: 'pencil',
                                        onClick: expandTile(entity._id),
                                        bgColorClassName:
                                            'bg-kk-background-blue',
                                    },
                                ]}
                                expandedOptions={[
                                    {
                                        iconName: 'archive-outline',
                                        iconColorClassName:
                                            'text-kk-status-red',
                                        label: entity.archived
                                            ? 'Unarchive'
                                            : 'Archive',
                                        onClick: () =>
                                            archiveOrganization(entity._id),
                                    },
                                ]}
                            />
                        ))}
            </DashboardContentSection>
            <OrganizationFormModal
                onAfterSubmit={() => appRepository.setActiveModal(null)}
            />
        </Navbar>
    );
};

export default Dashboard;
