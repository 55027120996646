import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Input } from '../../components/fields/Input';
import KKButton from '../../components/KKButton';
import { useForm } from '../../hooks/useForm';
import kkLogo from '../../images/logos/kk-logo-word-gray.png';
import '../../pages/RegistrationPage.css';
import {
    loginWithAuth0,
    registerWithAuth0,
    getUserRole
} from '../../services/authorization';
import userRepository from '../../stores/UserDataStore';


const Register = () => {
    const history = useHistory();

    const [submitting, setSubmitting] = useState(false);
    const [registrationFlag, setRegistrationFlag] = useState(false);

    const initialValues = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
    };
    const validations = {
        email: {
            required: {
                value: true,
                message: 'Please enter a valid email',
            },
        },
        password: {
            required: {
                value: true,
                message: 'Please enter a password',
            },
        },
        firstName: {
            required: {
                value: true,
                message: 'Please enter your first name',
            },
        },
        lastName: {
            required: {
                value: true,
                message: 'Please enter your last name',
            },
        },
    };
    const handleFormSubmit = async ({ values }) => {
        try {
            setSubmitting(true);
            const registrationResult = await registerWithAuth0(
                values.email,
                values.password,
                values.firstName,
                values.lastName
            );

            // onAfterSubmit();
            const { signInToken, user } = await loginWithAuth0(
                values.email,
                values.password
            );

            await getUserRole(signInToken, user)
            userRepository.setUserData(user);

            const { defaultDashboardUrl } = userRepository;
            history.push({ defaultDashboardUrl });

            return registrationResult;
        } catch (err) {
            console.error(
                'Registration error: Something went wrong',
                err
            );
            setRegistrationFlag(true);
            setSubmitting(false);
            return false;
        }
    };
    const { values, errors, handleChange, handleBlur, handleSubmit } = useForm({
        initialValues,
        onSubmit: handleFormSubmit,
        validations,
    });

    return (
        <div className="registration-modal">
            <div className="registration-modal-content">
                <div className="registration-modal-header">
                    <img src={kkLogo} alt="Kiddie Kredit Logo" />
                </div>
                <div className="registration-modal-title">
                    Welcome to Kiddie Kredit!
                </div>
                <form
                    id={'signInForm'}
                    className="form-modal-form"
                    onSubmit={handleSubmit}
                    style={{ paddingTop: 'var(--md-spacing)' }}
                >
                    <Input
                        name="email"
                        type="text"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={errors.email}
                        value={values.email}
                        placeholder="Email"
                        showLabel={false}
                        autoComplete="new-password"
                    />
                    <Input
                        name="password"
                        type="password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={errors.password}
                        value={values.password}
                        placeholder="Password"
                        showLabel={false}
                        style={{ marginTop: 'var(--sm-spacing)' }}
                        autoComplete="new-password"
                    />
                    <Input
                        name="firstName"
                        type="firstName"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={errors.firstName}
                        value={values.firstName}
                        placeholder="First Name"
                        showLabel={false}
                        style={{ marginTop: 'var(--sm-spacing)' }}
                    />
                    <Input
                        name="lastName"
                        type="lastName"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={errors.lastName}
                        value={values.lastName}
                        placeholder="Last Name"
                        showLabel={false}
                        style={{ marginTop: 'var(--sm-spacing)' }}
                    />
                </form>
                {Object.keys(errors).length === 0 && registrationFlag && (
                    <span className="text-error">
                        There was a problem processing your registration
                    </span>
                )}
                <div className="registration-modal-button">
                    <KKButton
                        disabled={submitting}
                        onClick={handleSubmit}
                        type="primary"
                    >
                        Sign Up
                    </KKButton>
                </div>
                <div>
                    <span style={{ fontSize: 'var(--sm-font)' }}>
                        Already have an account?{' '}
                    </span>
                    <Link to="/nonauth/log-in" className="login-register-link">
                        Log In
                    </Link>
                </div>
                <div className="registration-modal-spacer"></div>
                <div className="registration-modal-spacer"></div>
                <div className="registration-modal-footer">
                    <p>
                        By signing up, you agree to our{' '}
                        <span>
                            <a href="#">Terms, Data Policy</a>
                        </span>{' '}
                        and{' '}
                        <span>
                            <a href="#">Cookies Policy</a>
                        </span>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
