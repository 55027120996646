import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashboardContentSection from '../../../../components/DashboardContentSection';
import { Col, Row, Stack } from '../../../../components/fields';
import Loading from '../../../../components/loading';
import Navbar from '../../../../components/Navbar';
import DashboardCard from '../../../../components/tiles/DashboardCard';
import { ENUM_ENTITY_TYPE, MODALS } from '../../../../constants/common';
import OrganizationFormModal from '../../../../forms/OrganizationFormModal';
import {
    ARCHIVE_ORGANIZATION_MUTATION_TYPE,
    useArchiveOrganizationMutation,
} from '../../../../hooks/mutations/organizations';
import { useEntityAndParentsQuery } from '../../../../hooks/queries/entities';
import { useOrganizationByIdQuery } from '../../../../hooks/queries/organizations';
import appRepository from '../../../../stores/AppDataStore';
import { KK_API_URL, KK_CONSUMER_BACKEND_API_URL } from '../../../../config';
import kkLogo from '../../../../images/logos/kk-square-512.png';

const OrganizationFields: React.FunctionComponent<{
    title: string;
    value: any;
}> = ({ title, value }) => (
    <>
        {!!value && (
            <div>
                <h6 className="break-words">{title}</h6>
                <p className="break-words">{value}</p>
            </div>
        )}
    </>
);

const OrganizationDetails: React.FunctionComponent = () => {
    const { organizationId } = useParams<{ organizationId: string }>();

    const entityAndParentsQuery = useEntityAndParentsQuery({
        entityId: organizationId,
        entityType: ENUM_ENTITY_TYPE.ORGANIZATION,
    });

    const organizationQuery = useOrganizationByIdQuery(organizationId);

    const archiveOrganizationMutation = useArchiveOrganizationMutation({
        type: ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_ORGANIZATION_ADMIN,
    });

    const [imageSource, setImageSource] = React.useState<null | string>(null);

    let [attempt, setAttempt] = useState(0);

    useEffect(() => {
        if (
            organizationQuery.data?.basicInformation?.logo &&
            organizationQuery.data?.basicInformation?.logo.includes('http')
        ) {
            setImageSource(organizationQuery.data.basicInformation.logo);
        } else if (organizationQuery.data?.basicInformation?.logo) {
            setImageSource(
                `${KK_API_URL}/uploads/${organizationQuery.data.basicInformation.logo}`
            );
        }

        appRepository.setActiveModal(null);
    }, []);

    if (organizationQuery.isLoading)
        return (
            <Navbar>
                <Loading />
            </Navbar>
        );

    return (
        <Navbar>
            <Breadcrumbs
                entities={entityAndParentsQuery.data}
                suffix={[{ name: 'Details' }]}
            />
            {organizationQuery.data && (
                <>
                    <DashboardContentSection>
                        <h2>
                            {organizationQuery.data?.basicInformation?.name}
                        </h2>
                    </DashboardContentSection>
                    <DashboardContentSection
                        title="Details"
                        headerButtons={[
                            {
                                label: 'Edit',
                                type: 'primary',
                                onClick: () =>
                                    appRepository.setActiveModal(
                                        MODALS.CREATE_ORGANIZATION
                                    ),
                            },
                            {
                                label: organizationQuery.data.archived
                                    ? 'Unarchive'
                                    : 'Archive',
                                type: 'danger',
                                onClick: () =>
                                    archiveOrganizationMutation.mutate(
                                        organizationId
                                    ),
                            },
                        ]}
                        loading={
                            organizationQuery.isFetching ||
                            archiveOrganizationMutation.isLoading
                        }
                    >
                        <Stack>
                            <Row>
                                <Col size={4} className="pr-2">
                                    <DashboardCard title="Organization Info">
                                        <Row>
                                            <Col
                                                size={4}
                                                className="flex items-center pr-2"
                                            >
                                                {organizationQuery.data
                                                    ?.basicInformation?.logo ? (
                                                    <img
                                                        src={
                                                            imageSource as string
                                                        }
                                                        className="rounded-md"
                                                        onError={(error) => {
                                                            console.log(
                                                                'Error fetching profilepic ==> ',
                                                                error
                                                            );
                                                            const imageElement =
                                                                document.getElementById(
                                                                    'profile_pic'
                                                                ) as HTMLImageElement;
                                                            if (
                                                                imageElement &&
                                                                attempt < 3
                                                            ) {
                                                                attempt++;
                                                                setAttempt(
                                                                    attempt
                                                                );
                                                                imageElement.src = `${KK_API_URL}/uploads/${organizationQuery.data.basicInformation.logo}`;
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={kkLogo}
                                                        className="rounded-md"
                                                    />
                                                )}
                                            </Col>

                                            <Col size={8} className="pl-2">
                                                <Stack>
                                                    {[
                                                        {
                                                            title: 'Name',
                                                            value: organizationQuery
                                                                .data
                                                                .basicInformation
                                                                .name,
                                                        },
                                                        {
                                                            title: 'Joined',
                                                            value: new Date(
                                                                organizationQuery.data.enrollmentDate
                                                            ).toLocaleString(),
                                                        },
                                                        {
                                                            title: 'Website',
                                                            value: organizationQuery
                                                                .data
                                                                .basicInformation
                                                                .website,
                                                        },
                                                    ].map(
                                                        ({ title, value }) => (
                                                            <OrganizationFields
                                                                title={title}
                                                                value={value}
                                                            />
                                                        )
                                                    )}
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </DashboardCard>
                                </Col>
                                <Col size={8} className="pl-2">
                                    <DashboardCard
                                        title="Contact Info"
                                        fullHeight
                                    >
                                        <Stack>
                                            <Row>
                                                {[
                                                    {
                                                        title: 'Name',
                                                        value: `${organizationQuery.data.basicInformation.contactFirstName} ${organizationQuery.data.basicInformation.contactLastName}`,
                                                        size: 3,
                                                    },
                                                    {
                                                        title: 'Email',
                                                        value: organizationQuery
                                                            .data
                                                            .basicInformation
                                                            .contactEmail,
                                                        size: 6,
                                                    },
                                                    {
                                                        title: 'Phone Number',
                                                        value: organizationQuery
                                                            .data
                                                            .basicInformation
                                                            .contactPhone,
                                                        size: 3,
                                                    },
                                                ].map(
                                                    ({
                                                        title,
                                                        value,
                                                        size,
                                                    }) => (
                                                        <Col
                                                            size={size}
                                                            className="px-2 first:pl-0 last:pr-0"
                                                        >
                                                            <OrganizationFields
                                                                title={title}
                                                                value={value}
                                                            />
                                                        </Col>
                                                    )
                                                )}
                                            </Row>
                                            <Row>
                                                {[
                                                    {
                                                        title: 'Address',
                                                        value: organizationQuery
                                                            .data
                                                            .basicInformation
                                                            .contactAddress,
                                                        size: 4,
                                                    },
                                                    {
                                                        title: 'City',
                                                        value: organizationQuery
                                                            .data
                                                            .basicInformation
                                                            .contactCity,
                                                        size: 4,
                                                    },
                                                    {
                                                        title: 'State',
                                                        value: organizationQuery
                                                            .data
                                                            .basicInformation
                                                            .contactState,
                                                        size: 2,
                                                    },
                                                    {
                                                        title: 'ZIP',
                                                        value: organizationQuery
                                                            .data
                                                            .basicInformation
                                                            .contactZip,
                                                        size: 2,
                                                    },
                                                ].map(
                                                    ({
                                                        title,
                                                        value,
                                                        size,
                                                    }) => (
                                                        <Col
                                                            size={size}
                                                            className="px-2 first:pl-0 last:pr-0"
                                                        >
                                                            <OrganizationFields
                                                                title={title}
                                                                value={value}
                                                            />
                                                        </Col>
                                                    )
                                                )}
                                            </Row>
                                        </Stack>
                                    </DashboardCard>
                                </Col>
                            </Row>
                            <Row>
                                <DashboardCard title="Admin List">
                                    <Stack>
                                        {organizationQuery.data.adminEmails
                                            .map((email) => ({
                                                title: 'Email',
                                                value: email,
                                            }))
                                            .map(({ title, value }) => (
                                                <Row>
                                                    <OrganizationFields
                                                        title={title}
                                                        value={value}
                                                    />
                                                </Row>
                                            ))}
                                    </Stack>
                                </DashboardCard>
                            </Row>
                        </Stack>
                    </DashboardContentSection>
                    <OrganizationFormModal
                        edit
                        organizationToUpdateId={organizationId}
                        onAfterSubmit={() => appRepository.setActiveModal(null)}
                    />
                </>
            )}
        </Navbar>
    );
};

export default OrganizationDetails;
