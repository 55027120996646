import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Switch } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './auth/protected-route';
import Loading from './components/loading';
import { sentryConfig } from './config/sentry';
import ScrollToTop from './hooks/ScrollToTop';
import AuthRouter from './pages/AuthRouter';
import NonAuthScreen from './pages/non-auth/NonAuthScreen';
import userRepository from './stores/UserDataStore';

Sentry.init(sentryConfig);

const queryClient = new QueryClient();

const App = observer(() => {
    const [loading, setLoading] = useState(true);

    const initializeApp = async () => {
        console.log('Hello App');
        await userRepository.isAuthenticated();
        setLoading(false);
    };

    useEffect(() => {
        initializeApp();
    }, []);

    {
        loading && <Loading />;
    }

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <ScrollToTop />
                <Switch>
                    <ProtectedRoute
                        path="/nonauth"
                        canActivate={() => !userRepository.authenticated}
                        redirectTo="/"
                        component={NonAuthScreen}
                    />
                    <ProtectedRoute
                        path="/"
                        canActivate={() => userRepository.authenticated}
                        redirectTo="/nonauth/log-in"
                        component={AuthRouter}
                    />
                </Switch>
            </QueryClientProvider>
        </div>
    );
});

export default App;
