import { useParams } from 'react-router-dom';
import EntityAssignments from '../../../../components/EntityAssignments';
import { ENUM_ENTITY_TYPE } from '../../../../constants/common';

const GroupAssignments = () => {
    const { groupId } = useParams<{ groupId: string }>();

    return (
        <EntityAssignments
            entityId={groupId}
            entityType={ENUM_ENTITY_TYPE.GROUP}
        />
    );
};

export default GroupAssignments;
