import { useMutation, useQueryClient } from 'react-query';
import {
    ENUM_ENTITY_TYPE,
    ORGANIZATION_TASK_COMPLETION_STATUS,
} from '../../constants/common';
import { TaskCompletionInterface } from '../../models/OrganizationTaskCompletion';
import { processTaskCompletion } from '../../services/api/taskCompletionsApi';
import { TASK_COMPLETIONS_BY_GROUP_QUERY_KEY } from '../queries/taskCompletions';

export const useProcessTaskCompletionMutation = ({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: {
            taskCompletionId: string;
            approvalStatus: ORGANIZATION_TASK_COMPLETION_STATUS;
            childId: string;
        }) => processTaskCompletion({ entityId, entityType }, variables)(),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    TASK_COMPLETIONS_BY_GROUP_QUERY_KEY,
                    { groupId: entityId },
                ]);
            },
        }
    );
};
