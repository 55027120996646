// @ts-nocheck
import { Column, useTable } from 'react-table';

const KKTable = <Data extends Record<string, any>>({
    columns = [],
    data = [],
}: {
    columns: Column<Data>[];
    data: Data[];
}) => {
    const tableInstance = useTable({
        columns,
        data,
    });
    return (
        <table className="w-full" {...tableInstance.getTableProps()}>
            <thead>
                {tableInstance.headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                            return (
                                <th
                                    style={{
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                    }}
                                    className="pb-4 font-black"
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...tableInstance.getTableBodyProps()}>
                {tableInstance.rows.map((row) => {
                    tableInstance.prepareRow(row);
                    {
                        return (
                            <tr
                                className="border-2 border-black"
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            style={{
                                                paddingRight: 20,
                                                paddingLeft: 20,
                                            }}
                                            className="py-1 align-middle"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    }
                })}
            </tbody>
        </table>
    );
};

export default KKTable;
