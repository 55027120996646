import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { KKCheckboxV2 } from './fields/KKCheckbox';

export interface CheckboxNode {
    nodeId: number | string;
    parentNodeId?: number | string | null;
    value: string;
    label: string;
    renderIcon?: () => React.ReactElement;
    hasChildren?: boolean;
    type: string;
    state: 'checked' | 'indeterminate' | 'unchecked';
}

interface CheckboxTreeProps {
    nodes: Omit<CheckboxNode, 'state'>[];
    selectedNodes: string[];
    parentId?: string | number;
    level?: number;
    checkboxTree?: CheckboxNode[];
    selectedNodesById?: { [nodeId: string]: boolean };
    nodeIndexById?: { [id: string]: number };
    childrenIndexesByParentId?: { [parentId: string]: number[] };
    onChange: (updatedSelectedNodes: string[]) => void;
}

const CheckboxTree: React.FunctionComponent<CheckboxTreeProps> = ({
    nodes,
    selectedNodes,
    parentId,
    level = 0,
    checkboxTree: passedCheckboxTree,
    selectedNodesById: passedSelectedNodesById,
    nodeIndexById: passedNodeIndexById,
    childrenIndexesByParentId: passedChildrenIndexesByParentId,
    onChange,
}) => {
    const selectedNodesById = useMemo(
        () =>
            passedSelectedNodesById ||
            selectedNodes.reduce((result, nodeId) => {
                result[nodeId] = true;
                return result;
            }, {} as { [nodeId: string]: boolean }),
        [selectedNodes, passedSelectedNodesById]
    );

    const nodeIndexById: { [id: string]: number } = useMemo(
        () =>
            passedNodeIndexById ||
            nodes.reduce((map, node, idx) => {
                map[node.nodeId] = idx;
                return map;
            }, {} as { [id: string]: number }),
        [nodes, passedNodeIndexById]
    );

    const childrenIndexesByParentId: { [parentId: string]: number[] } = useMemo(
        () =>
            passedChildrenIndexesByParentId ||
            nodes.reduce((map, node, idx) => {
                if (!map[node.nodeId]) map[node.nodeId] = [];
                if (!!node.parentNodeId && !map[node.parentNodeId])
                    map[node.parentNodeId] = [];
                if (!!node.parentNodeId) map[node.parentNodeId].push(idx);
                return map;
            }, {} as { [parentId: string]: number[] }),
        [nodes, passedChildrenIndexesByParentId]
    );

    const checkboxTree = useMemo(() => {
        if (!!passedCheckboxTree) return passedCheckboxTree;

        const rootNode = nodes.find(({ parentNodeId }) => !parentNodeId);

        if (!rootNode) return [];

        const nodeStateById: {
            [nodeId: string]: 'checked' | 'unchecked' | 'indeterminate';
        } = {};

        const setNodeStateById = (
            node: Omit<CheckboxNode, 'state'>
        ): 'checked' | 'unchecked' | 'indeterminate' => {
            const childrenNodesStates = childrenIndexesByParentId[
                node.nodeId
            ].map((childIdx) => setNodeStateById(nodes[childIdx]));

            const isNodeSelected = selectedNodesById[node.nodeId];

            if (isNodeSelected) {
                nodeStateById[node.nodeId] = 'checked';
                return 'checked';
            }

            const isNodeIndeterminate = !childrenNodesStates.every(
                (value) => value === 'unchecked'
            );

            const nodeResolvedState = isNodeIndeterminate
                ? 'indeterminate'
                : 'unchecked';

            nodeStateById[node.nodeId] = nodeResolvedState;
            return nodeResolvedState;
        };

        setNodeStateById(rootNode);

        return nodes.map((node) => ({
            ...node,
            state: nodeStateById[node.nodeId],
        }));
    }, [nodes, selectedNodesById]);

    const childTree = useMemo(
        () =>
            checkboxTree.filter((node) =>
                !!parentId ? node.parentNodeId === parentId : !node.parentNodeId
            ),
        [checkboxTree, parentId]
    );

    const onNodeChange = useCallback(
        (nodeToUpdate: CheckboxNode) => {
            const updatedNodeState = !!selectedNodesById[nodeToUpdate.nodeId]
                ? 'unchecked'
                : 'checked';

            const nodeSubtree: Omit<CheckboxNode, 'state'>[] = [];

            const setNodeSubtree = (node: Omit<CheckboxNode, 'state'>) => {
                childrenIndexesByParentId[node.nodeId].forEach((childIdx) =>
                    setNodeSubtree(nodes[childIdx])
                );
                nodeSubtree.push(node);
            };

            setNodeSubtree(nodeToUpdate);

            const updatedSelectedNodesById = {
                ...selectedNodesById,
                ...nodeSubtree.reduce((result, { nodeId }) => {
                    result[nodeId] = updatedNodeState === 'checked';
                    return result;
                }, {} as { [nodeId: string]: boolean }),
            };

            const nodeParentPath: Omit<CheckboxNode, 'state'>[] = [];

            const setNodeParentPath = (node: Omit<CheckboxNode, 'state'>) => {
                if (!node.parentNodeId) return;
                const parentIdx = nodeIndexById[node.parentNodeId?.toString()];
                const parentNode = nodes[parentIdx];

                updatedSelectedNodesById[parentNode.nodeId] =
                    childrenIndexesByParentId[parentNode.nodeId]
                        .map((childIdx) => nodes[childIdx])
                        .map(({ nodeId }) => !!updatedSelectedNodesById[nodeId])
                        .every((value) => !!value);

                nodeParentPath.push(parentNode);
                setNodeParentPath(parentNode);
            };

            setNodeParentPath(nodeToUpdate);

            const nodesIdsToUpdate = [...nodeParentPath, ...nodeSubtree].map(
                ({ nodeId }) => nodeId.toString()
            );

            const nodesIdsToAdd = nodesIdsToUpdate.filter(
                (nodeId) => !!updatedSelectedNodesById[nodeId]
            );

            const nodesToRemoveById = nodesIdsToUpdate.reduce(
                (result, nodeId) => {
                    result[nodeId] = !updatedSelectedNodesById[nodeId];
                    return result;
                },
                {} as { [nodeId: string]: boolean }
            );

            const updatedSelectedNodes = Array.from(
                new Set([...selectedNodes, ...nodesIdsToAdd])
            ).filter((selectedNodeId) => !nodesToRemoveById[selectedNodeId]);

            onChange(updatedSelectedNodes);
        },
        [onChange, selectedNodesById, childrenIndexesByParentId, nodeIndexById]
    );

    return (
        <>
            {childTree.map((node) => {
                return (
                    <div
                        className={`${classNames({
                            'my-0':
                                childrenIndexesByParentId[node.nodeId]
                                    .length === 0 || level === 0,
                            'my-4':
                                childrenIndexesByParentId[node.nodeId].length >
                                    0 && level > 0,
                        })}`}
                        key={node.nodeId}
                    >
                        <KKCheckboxV2
                            name={`${node.nodeId}`}
                            renderIcon={node.renderIcon}
                            label={node?.label}
                            state={node.state}
                            value={node.value}
                            onChange={() => onNodeChange(node)}
                            size="lg"
                        />
                        <div className="ml-6">
                            <CheckboxTree
                                level={level + 1}
                                nodes={nodes}
                                selectedNodes={selectedNodes}
                                checkboxTree={checkboxTree}
                                parentId={node.nodeId}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default CheckboxTree;
