import { TaskInterface } from '../../models/OrganizationTask';
import { baseRequest } from '../baseRequest';

export function getCurrentWeekChildTaskCompletionsByGroup(
    groupId: string,
    childCompundKey: string
) {
    return () =>
        baseRequest(
            `/taskcompletions/${groupId}?currentWeek=true&compoundKey=${childCompundKey}`,
            { method: 'GET' }
        );
}

export function getChildTasksByGroup(groupId: string) {
    return () =>
        baseRequest(`/tasks/${groupId}`, {
            method: 'GET',
        });
}

export function getChildTaskCompletionsByGroup(groupId: string) {
    return () =>
        baseRequest(`/taskcompletions/${groupId}`, {
            method: 'GET',
        });
}

export function updateOrgTaskCompletion(data: {
    taskCompletionId: string;
    approvalStatus: string;
    notes: string;
}) {
    return () =>
        baseRequest(`/taskcompletions/approval-process`, {
            method: 'POST',
            body: data,
        });
}

export function createOrgTaskCompletion(data: any) {
    return () =>
        baseRequest(`/taskcompletions`, {
            method: 'POST',
            body: data,
        });
}

export function createOrgTask(data: any) {
    return () =>
        baseRequest(`/tasks`, {
            method: 'POST',
            body: data,
        });
}

export function updateOrgTask(orgTaskId: string, data: any) {
    return () =>
        baseRequest(`/tasks/${orgTaskId}`, {
            method: 'PUT',
            body: data,
        });
}

export const createTaskForEntity =
    (
        { entityId, entityType }: { entityId: string; entityType: string },
        taskData: Omit<
            TaskInterface,
            'createdBy' | 'createdAt' | 'updatedAt' | '_id'
        >
    ) =>
    () =>
        baseRequest(`/tasks/created-by/${entityType}/${entityId}/create`, {
            body: taskData,
            method: 'POST',
        });

export const editTaskForEntity =
    (
        {
            entityId,
            entityType,
            rewardId,
        }: { entityId: string; entityType: string; rewardId: string },
        taskData: Omit<
            TaskInterface,
            'createdBy' | 'createdAt' | 'updatedAt' | '_id'
        >
    ) =>
    () =>
        baseRequest(
            `/tasks/created-by/${entityType}/${entityId}/edit/${rewardId}`,
            {
                body: taskData,
                method: 'POST',
            }
        );

export function getTaskByIdForEntity({
    entityId,
    entityType,
    taskId,
}: {
    entityId: string;
    entityType: string;
    taskId: string;
}) {
    return () =>
        baseRequest(
            `/tasks/created-by/${entityType}/${entityId}/${taskId}`
        ) as Promise<{ task: TaskInterface }>;
}
