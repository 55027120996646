import { ENUM_ENTITY_TYPE, ALERT_TYPE } from '../../constants/common';
import { AlertInterface } from '../../models/Alert';
import { baseRequest } from '../baseRequest';
import userRepository from '../../stores/UserDataStore';

export function createAlert({
    entityType,
    entityId,
    alert,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
    alert: AlertInterface;
}) {
    alert.createdBy['entityName'] = userRepository.entityName;
    alert.createdBy['entityLogo'] = userRepository.entityLogo;
    alert.createdBy[entityType.toLowerCase()] = entityId;

    return baseRequest(
        `/alerts/created-by/${entityType}/${entityId}/create`,
        {
            method: 'POST',
            body: alert,
        },
        'CONSUMER'
    ) as Promise<any>;
}

export function getAlertsCreatedByEntity({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(
            `/organizations/alerts/created-by/${entityType}/${entityId}`,
            {
                method: 'GET',
            },
            'CONSUMER'
        ) as Promise<any>;
}
