import { useQuery } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import {
    getOpenTaskCompletionsByGroupId,
    getOpenTaskCompletionsByAssignmentId,
} from '../../services/api/taskCompletionsApi';

export const TASK_COMPLETIONS_BY_ASSIGNMENT_QUERY_KEY =
    'tasks_completions_by_assignment';

export const TASK_COMPLETIONS_BY_GROUP_QUERY_KEY = 'tasks_completions_by_group';

export const useTasksCompletionsByAssignmentIdQuery = ({
    assignmentId,
    entityType,
    entityId,
}: {
    assignmentId: string;
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) =>
    useQuery(
        [TASK_COMPLETIONS_BY_ASSIGNMENT_QUERY_KEY, { assignmentId }],
        async () => {
            const { taskCompletions } =
                await getOpenTaskCompletionsByAssignmentId({
                    assignmentId,
                    entityId,
                    entityType,
                })();
            return taskCompletions;
        }
    );

export const useTasksCompletionsByGroupIdQuery = ({
    groupId,
}: {
    groupId: string;
}) =>
    useQuery([TASK_COMPLETIONS_BY_GROUP_QUERY_KEY, { groupId }], async () => {
        const { taskCompletions } = await getOpenTaskCompletionsByGroupId({
            entityId: groupId,
            entityType: ENUM_ENTITY_TYPE.GROUP,
        })();
        return taskCompletions;
    });
