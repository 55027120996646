import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useNestedEntityTree from '../../hooks/useNestedEntityTree';
import {
    AnyEntity,
    Entity,
    FamilyUnitChildEntityInterface,
    GroupEntityInterface,
} from '../../models/Entity';
import CheckboxTree from '../../components/CheckboxTree';
import { FieldError, Stack } from '../../components/fields';
import Modal from '../../components/modals/Modal';
import { ALERT_TYPE, ENUM_ENTITY_TYPE, MODALS } from '../../constants/common';
import { AlertFormData } from './shared';
import appRepository from '../../stores/AppDataStore';
import SelectEntities from './SelectEntities';
import AlertReview from './AlertReview';
import AlertMessage from './AlertMessage';

import { useCreateAlertMutation } from '../../hooks/mutations/alerts';

const AlertModal: React.FunctionComponent<{ entity: Entity }> = ({
    entity,
}) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState<AlertFormData>({
        selectedEntities: [],
        alertMessage: '',
        linkedWebpage: '',
    });

    const { mutateAsync: createAlert, isLoading: isCreateAlertLoading } =
        useCreateAlertMutation({
            entityId: entity.data._id,
            entityType: entity.type,
        });

    const onModalClose = useCallback(() => {
        appRepository.setActiveModal(null);
        setFormData({
            selectedEntities: [],
            alertMessage: '',
            linkedWebpage: '',
        });
        setStep(1);
    }, [step]);

    const onNext = useCallback(
        (values: Partial<AlertFormData>) => {
            try {
                setFormData({ ...formData, ...values });
                setStep(step + 1);
            } catch (error) {
                console.log('Error ', error);
            }
        },
        [step]
    );

    const onPrevious = useCallback(
        (values: Partial<AlertFormData>) => {
            try {
                setFormData({ ...formData, ...values });
                setStep(step - 1);
            } catch (error) {
                console.log('Error ', error);
            }
        },
        [step]
    );

    const onFinish = useCallback(async () => {
        try {
            const assignedTo = new Set();

            let alertType = '';

            formData.selectedEntities.forEach((selectedEntity) => {
                if (
                    selectedEntity.type === ENUM_ENTITY_TYPE.FAMILY_UNIT_CHILD
                ) {
                    assignedTo.add(selectedEntity.data.familyUnitId);
                    alertType = ALERT_TYPE.GROUP_ALERT;
                } else if (selectedEntity.type === ENUM_ENTITY_TYPE.GROUP) {
                    (alertType = ALERT_TYPE.ORG_ALERT),
                        selectedEntity.data.groupMemberships.map(
                            (compoundKey) => {
                                assignedTo.add(compoundKey.split('_')[0]);
                            }
                        );
                }
            });

            const result = await createAlert({
                message: formData.alertMessage,
                assignedTo: Array.from(assignedTo) as string[],
                linkedWebpage: formData.linkedWebpage,
                createdBy: {},
                alertType,
            });

            onModalClose();
        } catch (error) {
            console.error('Error ==> ', error);
        }
    }, [formData]);
    return (
        <React.Fragment>
            {step === 1 && (
                <SelectEntities
                    entity={entity}
                    selectedEntities={formData.selectedEntities}
                    onNext={onNext}
                    onClose={onModalClose}
                />
            )}
            {step === 2 && (
                <AlertMessage
                    alertMessage={formData.alertMessage}
                    linkedWebpage={formData.linkedWebpage}
                    onClose={onModalClose}
                    onNext={onNext}
                    onPrevious={onPrevious}
                />
            )}
            {step === 3 && (
                <AlertReview
                    formData={formData}
                    onPrevious={() => setStep(step - 1)}
                    onFinish={onFinish}
                    isCreateAlertLoading={isCreateAlertLoading}
                />
            )}
        </React.Fragment>
    );
};

export default AlertModal;
