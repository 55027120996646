import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({
    component: Component,
    canActivate,
    redirectTo,
    ...args
}) => (
    <Route
        render={() =>
            canActivate() ? <Component /> : <Redirect to={redirectTo} />
        }
        {...args}
    />
);

export default ProtectedRoute;
