import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Stack, TextInput } from '../../components/fields';
import { LocalLoading } from '../../components/loading';
import Modal from '../../components/modals/Modal';
import { ENUM_ENTITY_TYPE, MODALS } from '../../constants/common';
import {
    useCreateRewardMutation,
    useEditRewardMutation,
} from '../../hooks/mutations/rewards';
import { useRewardByIdQuery } from '../../hooks/queries/rewards';
import { rewardValidationSchema } from './schema';

interface RewardFormModalProps {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    onModalClose?: () => any;
    onAfterSubmit?: () => any;
    formId?: string;
    rewardId?: string | null;
    edit?: boolean;
    modalName?: MODALS;
}

const RewardFormModal: React.FunctionComponent<RewardFormModalProps> = ({
    entityId,
    entityType,
    onModalClose = () => null,
    onAfterSubmit = () => null,
    formId = 'reward-form',
    rewardId = null,
    edit,
    modalName,
}) => {
    const rewardForm = useForm({
        defaultValues: rewardValidationSchema.getDefault(),
        resolver: yupResolver(rewardValidationSchema),
    });

    const rewardByIdQuery = useRewardByIdQuery(
        { entityId, entityType, rewardId },
        {
            onSuccess: (rewardToUpdate) => {
                rewardForm.reset(rewardToUpdate);
            },
        }
    );

    const createRewardMutation = useCreateRewardMutation({
        entityId,
        entityType,
    });

    const editRewardMutation = useEditRewardMutation({
        entityId,
        entityType,
        rewardId,
    });

    const loading = useMemo(
        () =>
            rewardByIdQuery.isFetching ||
            createRewardMutation.isLoading ||
            editRewardMutation.isLoading,
        [
            rewardByIdQuery.isFetching,
            createRewardMutation.isLoading,
            editRewardMutation.isLoading,
        ]
    );

    const closeModalAndResetForm = useCallback(() => {
        rewardForm.reset(rewardValidationSchema.getDefault());
        onModalClose();
    }, [rewardForm, rewardValidationSchema]);

    const onSubmit = useCallback(
        async (values) => {
            try {
                if (!edit) await createRewardMutation.mutateAsync(values);
                if (edit) await editRewardMutation.mutateAsync(values);
                onAfterSubmit();
            } catch (error) {
                console.error(error);
            } finally {
                closeModalAndResetForm();
            }
        },
        [rewardForm, edit]
    );

    return (
        <Modal
            title={edit ? 'Edit Reward' : 'Add Reward'}
            modalName={modalName}
            options={[
                {
                    text: 'Cancel',
                    type: 'secondary',
                    onClick: closeModalAndResetForm,
                },
                { form: formId, text: 'Submit', disabled: loading },
            ]}
            onClose={closeModalAndResetForm}
        >
            {loading && <LocalLoading />}
            <form id={formId} onSubmit={rewardForm.handleSubmit(onSubmit)}>
                <Stack>
                    {[
                        { label: 'Reward Name', name: 'name' },
                        {
                            label: 'Reward Description',
                            name: 'notes',
                            multiline: true,
                        },
                    ].map(({ label, name, multiline }) => (
                        <TextInput
                            key={name}
                            form={rewardForm}
                            name={name}
                            label={label}
                            multiline={multiline}
                        />
                    ))}
                    {[
                        {
                            question:
                                'How many of these can you give out (optional)',
                            name: 'availableStock',
                        },
                        {
                            question:
                                'How much does it cost per reward (optional)',
                            name: 'cost.amount',
                        },
                    ].map(({ question, name }) => (
                        <Row key={name} className="items-center justify-center">
                            <Col size={5}>
                                <span className="font-nunito text-sm">
                                    {question}
                                </span>
                            </Col>
                            <Col size={1}>
                                <TextInput
                                    type="number"
                                    form={rewardForm}
                                    name={name}
                                />
                            </Col>
                        </Row>
                    ))}
                </Stack>
            </form>
        </Modal>
    );
};

export default RewardFormModal;
