import { useQuery, UseQueryOptions } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { PopulatedAssignmentInterface } from '../../models/Assignment';
import {
    getAssignmentById,
    getAssignmentsAssignedTo,
    getAssignmentsCreatedByEntity,
} from '../../services/api/assignmentsApi';

export const ASSIGNMENTS_QUERY_KEY = 'assignments';
export const ASSIGNMENTS_ASSIGNED_TO_QUERY_KEY = 'assignments_assigned_to';
export const ASSIGNMENT_QUERY_KEY = 'assignment';

export const useAssignmentsCreatedQuery = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) =>
    useQuery([ASSIGNMENTS_QUERY_KEY, { entityId, entityType }], async () => {
        const { assignments } = await getAssignmentsCreatedByEntity({
            entityId,
            entityType,
        })();

        return assignments;
    });

export const useAssignmentsAssignedToQuery = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) =>
    useQuery(
        [ASSIGNMENTS_ASSIGNED_TO_QUERY_KEY, { entityId, entityType }],
        async () => {
            const { assignments } = await getAssignmentsAssignedTo({
                entityId,
                entityType,
            })();
            return assignments;
        }
    );

export const useAssignmentByIdQuery = (
    {
        assignmentId,
        entityId,
        entityType,
    }: {
        assignmentId?: string | null;
        entityId: string;
        entityType: ENUM_ENTITY_TYPE;
    },
    options: UseQueryOptions<PopulatedAssignmentInterface, Error> = {}
) =>
    useQuery({
        queryKey: [ASSIGNMENT_QUERY_KEY, { assignmentId }],
        queryFn: async () => {
            if (!assignmentId) throw new Error('No assignment id provided');
            const { assignment } = await getAssignmentById({
                assignmentId,
                entityId,
                entityType,
            })();
            return assignment;
        },
        enabled: !!assignmentId,
        ...options,
    });
