export enum SHOULD_BE_APPROVED_BY {
    GROUP_ADMIN = 'GROUP_ADMIN',
    FAMILYUNIT_ADMIN = 'FAMILYUNIT_ADMIN',
}

export const SHOULD_BE_APPROVED_BY_LABEL = {
    [SHOULD_BE_APPROVED_BY.FAMILYUNIT_ADMIN]: 'Parent or Guardian',
    [SHOULD_BE_APPROVED_BY.GROUP_ADMIN]: 'Someone within our organization',
};

export enum SHOULD_BE_APPROVED_BY_DISPLAY {
    PARENT_OR_GUARDIAN = 'Parent or Guardian',
    ORGANIZATION = 'Someone within our organization',
}

export enum ORGANIZATION_TASK_SIZE {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
}

export enum ORGANIZATION_TASK_SIZE_DISPLAY {
    SMALL = '< 10 minutes',
    MEDIUM = '10 - 30 minutes',
    LARGE = '30+ minutes',
}

export enum ONE_TIME_DUE_DATE {
    NO_DUE_DATE = 'NO_DUE_DATE',
    DUE_BY_SPECIFIC_DATE = 'DUE_BY_SPECIFIC_DATE',
    DUE_ON_SPECIFIC_DATE = 'DUE_ON_SPECIFIC_DATE',
}
