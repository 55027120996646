import { useQuery, UseQueryOptions } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { RewardInterface } from '../../models/OrganizationReward';
import {
    getRewardByIdForEntity,
    getRewards,
} from '../../services/api/rewardsApi';

export const REWARDS_QUERY_KEY = 'rewards';

const getRewardsQueryOptions = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}): UseQueryOptions<RewardInterface[], Error> => ({
    queryKey: [REWARDS_QUERY_KEY, { entityId, entityType }],
    queryFn: async () => {
        const { rewards } = await getRewards({ entityId, entityType })();
        return rewards;
    },
});

export const useRewardsQuery = (
    {
        entityId,
        entityType,
    }: {
        entityId: string;
        entityType: ENUM_ENTITY_TYPE;
    },
    options: UseQueryOptions<RewardInterface[], Error> = {}
) =>
    useQuery({
        ...getRewardsQueryOptions({ entityId, entityType }),
        ...options,
    });

export const REWARD_BY_ID_QUERY_KEY = 'reward_by_id';

export const useRewardByIdQuery = (
    {
        entityId,
        entityType,
        rewardId,
    }: {
        entityId: string;
        entityType: string;
        rewardId?: string | null;
    },
    options: UseQueryOptions<RewardInterface, Error>
) =>
    useQuery<RewardInterface, Error>({
        queryKey: [REWARD_BY_ID_QUERY_KEY, { entityId, entityType, rewardId }],
        queryFn: async () => {
            if (!rewardId) throw new Error('No rewardId provided');
            const { reward } = await getRewardByIdForEntity({
                entityId,
                entityType,
                rewardId,
            })();
            return reward;
        },
        enabled: !!rewardId,
        ...options,
    });
