import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column, Row } from 'react-table';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import DashboardContentSection from '../../../../../components/DashboardContentSection';
import KKButton from '../../../../../components/KKButton';
import Navbar from '../../../../../components/Navbar';
import { ENUM_ENTITY_TYPE } from '../../../../../constants/common';
import { KKTableV2 } from '../../../../../forms/TaskFormModal/KKTable';
import { useAssignmentByIdQuery } from '../../../../../hooks/queries/assignments';
import useEntityAndParents from '../../../../../hooks/useEntityAndParents';
import { AssignmentInterface } from '../../../../../models/Assignment';
import { useTasksCompletionsByAssignmentIdQuery } from '../../../../../hooks/queries/taskCompletions';

const useApprovalRequestsTable = ({
    assignmentId,
    entityType,
    entityId,
}: {
    assignmentId: string;
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) => {
    const { data: ar } = useTasksCompletionsByAssignmentIdQuery({
        assignmentId,
        entityType,
        entityId,
    });
    const approvalRequests = [
        {
            childName: 'Bethany',
            taskName: 'task',
            approveOrDeny: 'd',
        },
    ];

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'childName',
                Header: 'Child name',
                Cell: ({
                    row: {
                        original: { childName },
                    },
                }: {
                    row: Row<any>;
                }) => <div>{childName}</div>,
            },
            {
                id: 'taskName',
                accessor: (approvalRequest) => approvalRequest.taskName,
                Header: 'Task Name',
            },
            {
                id: 'approveOrDeny',
                Header: () => (
                    <div className="flex justify-end">Approve // Deny</div>
                ),
                Cell: () => (
                    <div className="flex w-full items-center justify-end gap-2">
                        <KKButton type="primary">Approve</KKButton>
                        <KKButton type="danger">Deny</KKButton>
                    </div>
                ),
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () => (Array.isArray(approvalRequests) ? approvalRequests : []),
        [approvalRequests]
    );

    return { columns, data };
};
const useRewardsRedemptionsTable = () => {
    const rewardsRedemptions = [
        {
            childName: 'Martha',
            rewards: [
                {
                    name: 'Lemon Badge',
                    received: false,
                },
                {
                    name: 'Business Badge',
                    received: true,
                },
            ],
        },
        {
            childName: 'Martha',
            rewards: [
                {
                    name: 'Lemon Badge',
                    received: false,
                },
                {
                    name: 'Business Badge',
                    received: true,
                },
            ],
        },
    ];

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'childName',
                Header: () => <div>Child name</div>,
                accessor: (rewardRedemption) => rewardRedemption.childName,
            },
            {
                id: 'reward',

                Header: 'Reward',
                Cell: ({
                    row: {
                        original: { rewards },
                    },
                }: {
                    row: Row<any>;
                }) =>
                    rewards.map(
                        (reward: { name: string; received: boolean }) => (
                            <div className="mb-2">{reward.name}</div>
                        )
                    ),
            },
            {
                id: 'received',
                Header: 'Received?',
                Cell: ({
                    row: {
                        original: { rewards },
                    },
                }: {
                    row: Row<any>;
                }) =>
                    rewards.map(
                        (reward: { name: string; received: boolean }) => {
                            if (reward.received) {
                                return (
                                    <div className="mb-2 rounded-full px-2 text-xl text-green-600">
                                        ✓
                                    </div>
                                );
                            }
                            return (
                                <div className="mb-2 flex gap-1 pt-2">
                                    <button className="rounded-full bg-green-600  px-2 text-white">
                                        ✓
                                    </button>
                                    <button className="rounded-full bg-red-600  px-2 text-white">
                                        X
                                    </button>
                                </div>
                            );
                        }
                    ),
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () => (Array.isArray(rewardsRedemptions) ? rewardsRedemptions : []),
        [rewardsRedemptions]
    );

    return { columns, data };
};

const useAssigneesTable = () => {
    const assignees = [
        {
            childName: 'Bethany',
            status: 'In progress',
            redeemed: 'Not eligible!',
        },
        {
            childName: 'Martha',
            status: 'Complete',
            redeemed: 'Eligible!',
        },
    ];

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'childName',
                Header: () => <div>Child name</div>,
                Cell: ({
                    row: {
                        original: { childName },
                    },
                }: {
                    row: Row<any>;
                }) => <div>{childName}</div>,
            },
            {
                id: 'status',
                accessor: (assignee) => assignee.status,
                Header: () => <div className="">Status</div>,
            },
            {
                id: 'approveOrDeny',
                Header: () => <div className="">Reward(s) Redeemed</div>,
                Cell: ({
                    row: {
                        original: { redeemed },
                    },
                }: {
                    row: Row<any>;
                }) => <div className="">{redeemed}</div>,
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () => (Array.isArray(assignees) ? assignees : []),
        [assignees]
    );

    return { columns, data };
};

const getAssignmentInfoTableData = (
    assignmentProperty: string,
    assignment: AssignmentInterface | undefined
) => {
    if (!assignment) return [];
    switch (assignmentProperty) {
        case 'tasks':
            return assignment.tasks.map((task) => {
                if (typeof task.task !== 'string') {
                    return task.task.choreInformation?.name;
                }
                return '';
            });
        case 'reps':
            return assignment.tasks.map((task) => {
                if (typeof task.task !== 'string') {
                    return task.reps;
                }
                return '';
            });
        case 'rewards':
            return assignment.rewards.map((reward) => {
                if (typeof reward.reward !== 'string') {
                    return reward.reward.name;
                }
                return '';
            });

        default:
            return [];
    }
};

const Assignment = () => {
    const { groupId, assignmentId } =
        useParams<{ groupId: string; assignmentId: string }>();
    const { entityAndParents } = useEntityAndParents({
        entityId: groupId,
        entityType: ENUM_ENTITY_TYPE.GROUP,
    });
    const { data: assignment } = useAssignmentByIdQuery({
        assignmentId,
        entityId: groupId,
        entityType: ENUM_ENTITY_TYPE.GROUP,
    });

    const { columns: approvalRequestsColumns, data: approvalRequestsData } =
        useApprovalRequestsTable({
            assignmentId,
            entityType: ENUM_ENTITY_TYPE.GROUP,
            entityId: groupId,
        });

    const { columns: assigneesColumns, data: assigneesData } =
        useAssigneesTable();

    const { columns: rewardsRedemptionsColumns, data: rewardsRedemptionsData } =
        useRewardsRedemptionsTable();

    return (
        <Navbar>
            <Breadcrumbs
                entities={entityAndParents}
                suffix={[
                    {
                        link: `/dashboard/groups/${groupId}/assignments`,

                        name: 'My Assignments',
                    },
                    { name: assignment?.name || 'Assignment' },
                ]}
            />
            <DashboardContentSection>
                <h2>{assignment?.name}</h2>
            </DashboardContentSection>
            <DashboardContentSection
                title="Overview"
                // headerButtons={[
                //     {
                //         label: 'Edit Assignment',
                //         type: 'primary',
                //         onClick: () => {},
                //     },
                // ]}
            >
                <div className="mx-auto flex w-3/5 flex-col justify-between rounded-3xl border-2 border-solid border-black px-6 py-4">
                    <div className="flex w-full justify-between">
                        {['tasks', 'reps', 'rewards'].map((header, idx) => (
                            <div>
                                <h4
                                    key={`${header}-${idx}`}
                                    className="mb-6 uppercase tracking-widest"
                                >
                                    {header}
                                </h4>
                                {getAssignmentInfoTableData(
                                    header,
                                    assignment
                                ).map((data) => (
                                    <p className="text-center">{data}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </DashboardContentSection>
            <DashboardContentSection>
                <div className=" flex justify-center gap-6">
                    <div className="flex h-60 w-2/5 flex-col gap-4 rounded-2xl border-2 border-solid px-6 py-4">
                        <div className="flex justify-between">
                            <h3 className="text-black">{`Awaiting Approval (${approvalRequestsData.length})`}</h3>
                            <KKButton type="primary">Approve All</KKButton>
                        </div>
                        <KKTableV2
                            columns={approvalRequestsColumns}
                            data={approvalRequestsData}
                        />
                    </div>
                    <div className="flex h-60 w-2/5 flex-col gap-4 rounded-2xl border-2 border-solid px-6 py-4">
                        <h3 className="text-black">Rewards for Redemption</h3>
                        <KKTableV2
                            columns={rewardsRedemptionsColumns}
                            data={rewardsRedemptionsData}
                        />
                    </div>
                </div>
            </DashboardContentSection>
            <div className="mx-6 mb-4 flex h-60 rounded-2xl border-4 bg-kk-light-grey p-6">
                <h3>Assignees</h3>
                <div className="mt-20 flex  w-full flex-col">
                    <KKTableV2
                        columns={assigneesColumns}
                        data={assigneesData}
                    />
                </div>
            </div>
        </Navbar>
    );
};

export default Assignment;
