import { ENUM_ENTITY_TYPE } from '../../constants/common';
import {
    LegacyKKChoreInterface,
    TaskInterface,
} from '../../models/OrganizationTask';
import { baseRequest } from '../baseRequest';
import { SHOULD_BE_APPROVED_BY } from '../../constants/orgTasks';

export function getTasks({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(`/tasks/created-by/${entityType}/${entityId}`, {
            method: 'GET',
        }) as Promise<{
            tasks: TaskInterface[];
        }>;
}

export function createTask({
    entityType,
    entityId,
    task,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
    task: {
        shouldBeApprovedBy: SHOULD_BE_APPROVED_BY[];
        choreInformation: Pick<
            LegacyKKChoreInterface,
            'name' | 'notes' | 'description' | 'deleted'
        >;
        oneTime: boolean;
    };
}) {
    return baseRequest(`/tasks/created-by/${entityType}/${entityId}/create`, {
        method: 'POST',
        body: {
            task,
        },
    }) as Promise<{
        task: TaskInterface;
    }>;
}
