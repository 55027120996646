import { useMutation, useQueryClient } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { RewardEditableFieldsInterface } from '../../models/OrganizationReward';
import {
    createRewardForEntity,
    editRewardForEntity,
} from '../../services/api/rewardsApi';
import { REWARDS_QUERY_KEY, REWARD_BY_ID_QUERY_KEY } from '../queries/rewards';

export const useCreateRewardMutation = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: RewardEditableFieldsInterface) =>
            createRewardForEntity({ entityId, entityType }, variables),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    REWARDS_QUERY_KEY,
                    { entityId, entityType },
                ]);
            },
        }
    );
};

export const useEditRewardMutation = ({
    entityId,
    entityType,
    rewardId,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    rewardId: string | null;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: RewardEditableFieldsInterface) => {
            if (!rewardId) throw new Error('Reward Id not provided');
            return editRewardForEntity(
                { entityId, entityType, rewardId },
                variables
            )();
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    REWARDS_QUERY_KEY,
                    { entityId, entityType },
                ]);
                await queryClient.invalidateQueries([
                    REWARD_BY_ID_QUERY_KEY,
                    { entityId, entityType, rewardId },
                ]);
            },
        }
    );
};
