import { Link } from 'react-router-dom';
import './NonAuth.css';

import kkLogo from '../../images/logos/kk-logo-word.png';
import businessWomen from '../../images/business-women-computer.jpg';
import ctaLaptop from '../../images/cta-laptop.png';
import pandaScene from '../../images/PandaScene.png';
import { Input } from '../../components/fields/Input';
import { useForm } from '../../hooks/useForm';

const Landing = () => {
    const currentYear = new Date().getFullYear();

    const initialValues = {
        email: '',
    };

    const handleFormSubmit = async ({ values }) => {
        console.log('Handle form submit');
    };

    const { values, handleChange, handleSubmit, handleBlur } = useForm({
        initialValues,
        onSubmit: handleFormSubmit,
    });

    return (
        <div className="landing-style">
            <div className="landing-nav-container"></div>
            <div className="landing-nav">
                <img
                    src={kkLogo}
                    className="landing-logo"
                    alt="Kiddie Kredit Logo"
                />
                <div className="landing-nav-buttons">
                    <Link
                        to="/nonauth/log-in"
                        id="qsLoginBtn"
                        className="log-in-btn"
                    >
                        <h4>LOG IN / REGISTER</h4>
                    </Link>
                </div>
            </div>
            <div className="cta-background"></div>
            <div className="landing-cta-container">
                <div className="landing-cta-left-column">
                    <div>
                        <h1>Kiddie Kredit</h1>
                        <h1>for Organizations</h1>
                    </div>
                    <p>
                        Kiddie Kredit can work with your organization or
                        municipality to provide a robust solution that drives
                        engagement for your organization and/or city while
                        simultaneously building up the financial well-being of
                        your intended audience.
                    </p>
                    <form id="bookDemoForm" className="book-demo-email-form">
                        <Input
                            name="email"
                            type="text"
                            value={values.email}
                            className="landing-email-input"
                            placeholder="Email Address"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="book-demo-btn"
                        >
                            BOOK A DEMO
                        </button>
                    </form>

                    <h3>Sign up for early access.</h3>
                    <img
                        src={pandaScene}
                        alt="Panda Family Children Ice Cream"
                        className="panda-scene"
                    />
                </div>
                <div className="cta-images">
                    <img
                        src={businessWomen}
                        className="business-women-img"
                        alt="Business Women Computer"
                    />
                    <img
                        src={ctaLaptop}
                        className="cta-laptop"
                        alt="Laptop Dashboard"
                    />
                </div>
            </div>
            <div className="landing-footer-container"></div>
            <div className="landing-footer">
                <p>© 2018-{currentYear} All Rights Reserved. Kiddie Kredit</p>
                <div>
                    <a href="https://www.kiddiekredit.com/terms">
                        Terms of Service
                    </a>
                    <a href="https://www.kiddiekredit.com/privacy-policy">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Landing;
