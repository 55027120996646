import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashboardContentSection from '../../../../components/DashboardContentSection';
import { Col, Row, Stack } from '../../../../components/fields';
import Navbar from '../../../../components/Navbar';
import DashboardCard from '../../../../components/tiles/DashboardCard';
import { ENUM_ENTITY_TYPE, MODALS } from '../../../../constants/common';
import GroupFormModal from '../../../../forms/GroupFormModal';
import { useEntityAndParentsQuery } from '../../../../hooks/queries/entities';
import { useGroupByIdQuery } from '../../../../hooks/queries/groups';
import appRepository from '../../../../stores/AppDataStore';

const GroupDetails = () => {
    const { pathname: currentPathname } = useLocation();

    const { groupId } = useParams<{ groupId: string }>();

    const entityAndParentsQuery = useEntityAndParentsQuery({
        entityId: groupId,
        entityType: ENUM_ENTITY_TYPE.GROUP,
    });

    const groupQuery = useGroupByIdQuery(groupId);

    return (
        <Navbar>
            <Breadcrumbs
                prefix={[{ name: 'Dashboards', link: '/dashboard' }]}
                entities={entityAndParentsQuery.data}
                suffix={[
                    { name: 'Details', link: `${currentPathname}/detail` },
                ]}
            />
            <DashboardContentSection>
                <h2>{groupQuery.data?.basicInformation.name}</h2>
            </DashboardContentSection>
            <DashboardContentSection
                title="Details"
                headerButtons={[
                    {
                        label: 'Edit',
                        type: 'primary',
                        onClick: () =>
                            appRepository.setActiveModal(MODALS.CREATE_GROUP),
                    },
                ]}
            >
                <DashboardCard title="Group Info">
                    <Stack>
                        {[
                            {
                                title: 'Name',
                                value: groupQuery.data?.basicInformation.name,
                            },
                            {
                                title: 'Admin Name',
                                value: `${groupQuery.data?.basicInformation.adminFirstName} ${groupQuery.data?.basicInformation.adminLastName}`,
                            },
                            {
                                title: 'Admin Email',
                                value: groupQuery.data?.ownerEmail,
                            },
                        ].map(({ title, value }) => (
                            <Row>
                                <Col size={12}>
                                    <h6>{title}</h6>
                                    <p>{value}</p>
                                </Col>
                            </Row>
                        ))}
                    </Stack>
                </DashboardCard>
            </DashboardContentSection>
            <GroupFormModal edit groupId={groupId} />
        </Navbar>
    );
};

export default GroupDetails;
