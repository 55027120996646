import * as yup from 'yup';
import { RewardEditableFieldsInterface } from '../../models/OrganizationReward';

export const rewardValidationSchema: yup.SchemaOf<
    Omit<RewardEditableFieldsInterface, 'deleted' | 'redemptionLimit'>
> = yup.object({
    name: yup
        .string()
        .required('Reward name is required')
        .min(3, 'Reward name must have more than 3 characters')
        .default(''),
    notes: yup.string().notRequired().default(''),
    availableStock: yup.lazy((value) => {
        switch (value) {
            case '':
            case undefined:
                return yup.string().transform(() => null);
            default:
                return yup.number().integer().nullable().positive();
        }
    }) as any,
    cost: yup.object({
        amount: yup.lazy((value) =>
            !value && value !== 0
                ? yup
                      .string()
                      .optional()
                      .nullable()
                      .transform(() => 0)
                      .default('0')
                : yup.number().min(0).default(0)
        ) as any,
        currency: yup
            .string()
            .oneOf(['USD'])
            .default('USD')
            .required() as yup.StringSchema<string>,
    }),
});

export type RewardFormValues = yup.InferType<typeof rewardValidationSchema>;
