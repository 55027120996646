import { IonIcons } from 'react-ion-icon';
import KKIonIcon from '../KKIonIcon';
import './NavTile.css';

const NavTile = ({
    to,
    color,
    iconName,
    title,
    number,
}: {
    to?: string;
    color: string;
    iconName: IonIcons;
    title: string;
    number: string | number;
}) => (
    <div className="flex min-h-full min-w-full overflow-hidden rounded-lg shadow-md">
        <div
            style={{ backgroundColor: color }}
            className="flex min-h-full items-center justify-center p-4"
        >
            <KKIonIcon className="text-4xl text-white" name={iconName} />
        </div>
        <div className="flex flex-grow flex-col items-center justify-center p-4">
            <h5 className="mb-1">{title}</h5>
            <h2>{number.toString()}</h2>
        </div>
    </div>
);

export default NavTile;
