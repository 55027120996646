import React from 'react';
import { Link } from 'react-router-dom';
import { ENUM_ENTITY_TYPE } from '../constants/common';
import { BreadcrumbLinkInterface } from '../models/BreadcrumbLink';
import { Entity } from '../models/Entity';
import KKIonIcon from './KKIonIcon';

interface BreadcrumbsProps {
    breadcrumbs?: BreadcrumbLinkInterface[];
    prefix?: BreadcrumbLinkInterface[];
    suffix?: BreadcrumbLinkInterface[];
    entities?: Entity[];
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
    prefix = [{ name: 'Dashboard', link: '' }],
    suffix = [],
    entities = [],
}) => (
    <>
        <div className="fixed z-10 flex h-12 w-full items-center bg-kk-background-blue/95 px-6 py-4">
            {[
                ...prefix,
                ...entities.map((entity) => {
                    switch (entity.type) {
                        case ENUM_ENTITY_TYPE.ORGANIZATION:
                            return {
                                name: entity.data.basicInformation.orgName,
                                link: `/dashboard/organizations/${entity.data._id}`,
                            };
                        case ENUM_ENTITY_TYPE.GROUP:
                            return {
                                name: entity.data.basicInformation.groupName,
                                link: `/dashboard/groups/${entity.data._id}`,
                            };
                        default:
                            throw new Error('Invalid Entity Type');
                    }
                }),
                ...suffix,
            ].map(({ name, link }, index, array) => {
                const isLastElement = index === array.length - 1;
                return (
                    <div className="flex items-center" key={`${name}-${index}`}>
                        <KKIonIcon
                            className="text-sm text-kk-grey"
                            name="chevron-forward-outline"
                        />
                        {!isLastElement && (
                            <Link
                                to={link || '/'}
                                className="mx-2 text-xs text-kk-grey hover:underline"
                            >
                                {name}
                            </Link>
                        )}
                        {isLastElement && (
                            <p className="mx-2 cursor-pointer text-xs font-semibold text-black">
                                {name}
                            </p>
                        )}
                    </div>
                );
            })}
        </div>
        <div className="h-12" />
    </>
);

export default Breadcrumbs;
