import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { MODALS } from '../../constants/common';
import appRepository from '../../stores/AppDataStore';
import KKButton from '../KKButton';
import KKIonIcon from '../KKIonIcon';

const modalRoot = document.getElementById('modal-root');

class ModalPortal extends React.Component {
    el: HTMLDivElement;

    constructor(props: any) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot!.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot!.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.el);
    }
}

interface ModalOptionsInterface {
    onClick?: (...args: any) => any | Promise<any>;
    type?: 'primary' | 'secondary';
    text?: string;
    form?: string;
    disabled?: boolean;
    loading?: boolean;
}

type ModalProps = {
    visible?: boolean;
    title?: string;
    subtitle?: string;
    options?: ModalOptionsInterface[];
    renderHeader?: (props: {
        title: string;
        subtitle: string;
    }) => React.ReactComponentElement<any, typeof props>;
    renderFooter?: (props: {
        options: ModalOptionsInterface[];
    }) => React.ReactComponentElement<any, typeof props>;
    modalName?: MODALS;
    onClose?: (...args: any) => any | Promise<any>;
};

const ModalDefaultHeader: React.FunctionComponent<{
    title: string;
    subtitle: string;
}> = ({ title, subtitle }) => (
    <>
        <div className="d-flex flex-direction-column p-4">
            <h3 className="text-black">{title}</h3>
            {!!subtitle && <h6 className="mt-2 leading-4">{subtitle}</h6>}
        </div>
        {!!title && (
            <div className="min-h-[1px] w-full bg-gradient-to-r from-transparent via-black/20 to-transparent" />
        )}
    </>
);

const ModalDefaultFooter: React.FunctionComponent<{
    options: ModalOptionsInterface[];
}> = ({ options }) => (
    <>
        {options.length > 0 && (
            <>
                <div className="min-h-[1px] w-full bg-gradient-to-r from-transparent via-black/20 to-transparent" />
                <div
                    className={`flex items-center gap-4 p-4 ${classNames({
                        'justify-center': options.length < 3,
                        'flex-col': options.length > 2,
                    })}`}
                >
                    {options.length > 0 &&
                        options.map(
                            (
                                {
                                    text,
                                    type,
                                    form,
                                    onClick,
                                    disabled,
                                    loading,
                                },
                                _idx,
                                array
                            ) => (
                                <div
                                    key={`${text}-modal-button`}
                                    className={`${classNames({
                                        'w-5/12 grow': array.length < 3,
                                        'w-full py-2 first:pt-0 last:pb-0':
                                            array.length > 2,
                                    })}`}
                                >
                                    <KKButton
                                        type={type || 'primary'}
                                        onClick={!form ? onClick : undefined}
                                        form={form}
                                        disabled={disabled}
                                        loading={loading}
                                        fullWidth
                                    >
                                        {text}
                                    </KKButton>
                                </div>
                            )
                        )}
                </div>
            </>
        )}
    </>
);

const Modal: React.FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
    children,
    modalName,
    visible = modalName === appRepository.activeModal,
    title = '',
    subtitle = '',
    options = [],
    onClose = () => appRepository.setActiveModal(null),
    renderHeader = (headerProps) => <ModalDefaultHeader {...headerProps} />,
    renderFooter = (footerProps) => <ModalDefaultFooter {...footerProps} />,
}) => {
    const history = useHistory();

    useEffect(() => {
        const unsubscribeFromHistory = history.listen(() => {
            appRepository.setActiveModal(null);
        });
        return () => unsubscribeFromHistory();
    }, []);

    return (
        <React.Fragment>
            {visible && (
                <ModalPortal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
                        <div className="relative flex max-h-[calc(100%_*_4/6)] w-4/6 flex-col rounded-md bg-white lg:w-1/2">
                            {!!onClose && (
                                <div
                                    className="absolute top-0 right-0 cursor-pointer p-3"
                                    onClick={onClose}
                                >
                                    <KKIonIcon
                                        name="close"
                                        className="text-4xl text-black"
                                    />
                                </div>
                            )}
                            <div>{renderHeader({ title, subtitle })}</div>
                            <div className="grow overflow-auto p-4">
                                {children}
                            </div>
                            <div>{renderFooter({ options })}</div>
                        </div>
                    </div>
                </ModalPortal>
            )}
        </React.Fragment>
    );
};

export default observer(Modal);
