import { useParams } from 'react-router-dom';
import EntityRewards from '../../../../components/EntityRewards';
import { ENUM_ENTITY_TYPE } from '../../../../constants/common';

const GroupRewards = () => {
    const { groupId } = useParams<{ groupId: string }>();

    return (
        <EntityRewards entityId={groupId} entityType={ENUM_ENTITY_TYPE.GROUP} />
    );
};

export default GroupRewards;
