import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack, TextInput } from '../../components/fields';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';

const alertMessageSchema = yup.object({
    alertMessage: yup.string().min(3).required().default(''),
    linkedWebpage: yup.string().url('Please enter a valid URL').default(''),
});

type AlertMessageSchema = yup.InferType<typeof alertMessageSchema>;

interface AlertMessageProps {
    alertMessage: string;
    linkedWebpage: string;
    onNext: (args: { alertMessage: string; linkedWebpage: string }) => void;
    onPrevious: (args: {
        alertMessage: string;
        linkedWebpage: string;
    }) => void;
    onClose: () => void;
}

const AlertMessage: React.FunctionComponent<AlertMessageProps> = ({
    alertMessage: initialAlertMessage,
    linkedWebpage: initialLinkedWebpage,
    onNext,
    onPrevious,
    onClose,
}) => {
    const alertMessageForm = useForm<AlertMessageSchema>({
        resolver: yupResolver(alertMessageSchema),
    });

    const onSubmit = (
        cb: AlertMessageProps['onNext'] | AlertMessageProps['onPrevious']
    ) =>
        alertMessageForm.handleSubmit(({ alertMessage, linkedWebpage }) => {
            cb({
                alertMessage: alertMessage as string,
                linkedWebpage: linkedWebpage as string,
            });
        });

    useEffect(() => {
        alertMessageForm.reset({
            alertMessage: initialAlertMessage,
            linkedWebpage: initialLinkedWebpage,
        });
    }, [initialAlertMessage, initialLinkedWebpage]);

    return (
        <Modal
            modalName={MODALS.CREATE_ALERT_FOR_FAMILIES}
            onClose={onClose}
            title="Alert Message"
            subtitle="This is the message that will be displayed to the families in the selected groups after you submit this form. There is also an opportunity for you to provide a link to a webpage."
            options={[
                {
                    text: 'Previous',
                    type: 'secondary',
                    onClick: () =>
                        onPrevious({
                            ...alertMessageForm.getValues(),
                        }),
                },
                {
                    text: 'Almost Done',
                    type: 'primary',
                    onClick: onSubmit(onNext),
                },
            ]}
        >
            <Stack>
                <TextInput
                    form={alertMessageForm}
                    name="alertMessage"
                    label="Alert Message"
                    multiline
                />
                <TextInput
                    form={alertMessageForm}
                    name="linkedWebpage"
                    label="Linked Webpage (optional)"
                />
            </Stack>
        </Modal>
    );
};

export default AlertMessage;
