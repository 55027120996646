import { DeepPartial } from 'react-hook-form';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { OrganizationInterface } from '../../models/Organization';
import {
    archiveOrganization,
    archiveSuborganization,
    createOrganization,
    createSuborganization,
    editOrganization,
} from '../../services/api/orgApi';
import {
    ORGANIZATIONS_QUERY_KEY,
    ORGANIZATION_BY_ID_QUERY_KEY,
} from '../queries/organizations';

export enum ARCHIVE_ORGANIZATION_MUTATION_TYPE {
    AS_ORGANIZATION_ADMIN = 'AS_ORGANIZATION_ADMIN',
    AS_PARENT_ORGANIZATION_ADMIN = 'AS_PARENT_ORGANIZATION_ADMIN',
}

export type ArchiveOrganizationMutation =
    | {
          type: ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_ORGANIZATION_ADMIN;
          parentOrganizationId?: null;
      }
    | {
          type: ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_PARENT_ORGANIZATION_ADMIN;
          parentOrganizationId: string;
      };

export const useArchiveOrganizationMutation = (
    mutation: ArchiveOrganizationMutation,
    options: UseMutationOptions<OrganizationInterface, Error, string> = {}
) => {
    const queryClient = useQueryClient();

    return useMutation<OrganizationInterface, Error, string>({
        ...options,
        mutationFn: async (organizationToUpdateId: string) => {
            switch (mutation.type) {
                case ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_ORGANIZATION_ADMIN: {
                    const { organization } = await archiveOrganization(
                        organizationToUpdateId
                    );
                    return organization;
                }
                case ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_PARENT_ORGANIZATION_ADMIN: {
                    const { organization } = await archiveSuborganization({
                        parentOrganizationId: mutation.parentOrganizationId,
                        organizationToUpdateId,
                    });
                    return organization;
                }
                default:
                    throw new Error('Invalid mutation type');
            }
        },
        onSuccess: async (
            updatedOrganization,
            organizationToUpdateId,
            context
        ) => {
            await queryClient.invalidateQueries([ORGANIZATIONS_QUERY_KEY]);
            await queryClient.invalidateQueries([
                ORGANIZATION_BY_ID_QUERY_KEY,
                { organizationId: organizationToUpdateId },
            ]);
            if (!!options.onSuccess)
                await options.onSuccess(
                    updatedOrganization,
                    organizationToUpdateId,
                    context
                );
        },
    });
};

export enum CREATE_ORGANIZATION_MUTATION_TYPE {
    ORGANIZATION = 'ORGANIZATION',
    SUBORGANIZATION = 'SUBORGANIZATION',
}

export type CreateOrganizationMutation =
    | {
          type: CREATE_ORGANIZATION_MUTATION_TYPE.ORGANIZATION;
          parentOrganizationId?: null;
      }
    | {
          type: CREATE_ORGANIZATION_MUTATION_TYPE.SUBORGANIZATION;
          parentOrganizationId: string;
      };

export const useCreateOrganizationMutation = (
    mutation: CreateOrganizationMutation,
    options: UseMutationOptions<
        OrganizationInterface,
        Error,
        DeepPartial<OrganizationInterface>
    > = {}
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async (variables: DeepPartial<OrganizationInterface>) => {
            switch (mutation.type) {
                case CREATE_ORGANIZATION_MUTATION_TYPE.ORGANIZATION: {
                    const { organization } = await createOrganization(
                        variables
                    );
                    return organization;
                }
                case CREATE_ORGANIZATION_MUTATION_TYPE.SUBORGANIZATION: {
                    const { organization } = await createSuborganization({
                        data: variables,
                        parentOrganizationId: mutation.parentOrganizationId,
                    });
                    return organization;
                }
                default:
                    throw new Error();
            }
        },
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries([ORGANIZATIONS_QUERY_KEY]);
            if (!!options.onSuccess) await options.onSuccess(...args);
        },
    });
};

export const useEditOrganizationMutation = (
    mutation: { organizationId?: string },
    options: Omit<
        UseMutationOptions<
            OrganizationInterface,
            Error,
            DeepPartial<OrganizationInterface>
        >,
        'mutationFn'
    > = {}
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async (variables: DeepPartial<OrganizationInterface>) => {
            if (!mutation.organizationId)
                throw new Error('Organization Id not provided');
            const { organization } = await editOrganization(
                mutation.organizationId,
                variables
            )();
            return organization;
        },
        onSuccess: async (...args) => {
            await queryClient.invalidateQueries([ORGANIZATIONS_QUERY_KEY]);
            await queryClient.invalidateQueries([
                ORGANIZATION_BY_ID_QUERY_KEY,
                { organizationId: mutation.organizationId },
            ]);
            if (!!options.onSuccess) await options.onSuccess(...args);
        },
    });
};
