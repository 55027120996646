import { DeepPartial } from 'react-hook-form';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { GroupInterface } from '../../models/Group';
import {
    archiveGroup,
    createGroup,
    editGroup,
    enrollChildrenToGroup,
} from '../../services/api/groupApi';
import {
    ENROLLABLE_CHILDREN_FOR_GROUP_QUERY_KEY,
    GROUPS_QUERY_KEY,
    GROUPS_QUERY_TYPE,
    GROUP_BY_ID_QUERY_KEY,
} from '../queries/groups';

export const useCreateGroupMutation = (
    mutation: {
        parentOrganizationId?: string;
    },
    options: Omit<
        UseMutationOptions<GroupInterface, Error, DeepPartial<GroupInterface>>,
        'mutationFn'
    > = {}
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async (variables) => {
            if (!mutation.parentOrganizationId)
                throw new Error('No parent organization Id provided');
            const { group } = await createGroup({
                parentOrganizationId: mutation.parentOrganizationId,
                data: variables,
            });
            return group;
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries([
                GROUPS_QUERY_KEY,
                { type: GROUPS_QUERY_TYPE.ALL_GROUPS },
            ]);
            await queryClient.invalidateQueries([
                GROUPS_QUERY_KEY,
                {
                    type: GROUPS_QUERY_TYPE.BY_PARENT_ID,
                    parentOrganizationId: mutation.parentOrganizationId,
                },
            ]);
            if (options.onSuccess)
                await options.onSuccess(data, variables, context);
        },
    });
};

export const useEditGroupMutation = (
    mutation: { groupId?: string },
    options: Omit<
        UseMutationOptions<GroupInterface, Error, DeepPartial<GroupInterface>>,
        'mutationFn'
    > = {}
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async (variables) => {
            if (!mutation.groupId) throw new Error('No group Id provided');
            const { group } = await editGroup({
                groupId: mutation.groupId,
                data: variables,
            });
            return group;
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries([
                GROUPS_QUERY_KEY,
                { type: GROUPS_QUERY_TYPE.ALL_GROUPS },
            ]);
            await queryClient.invalidateQueries([
                GROUPS_QUERY_KEY,
                {
                    type: GROUPS_QUERY_TYPE.BY_PARENT_ID,
                    parentOrganizationId: data.parentOrgId,
                },
            ]);
            await queryClient.invalidateQueries([
                GROUP_BY_ID_QUERY_KEY,
                { groupId: data._id },
            ]);
            if (options.onSuccess)
                await options.onSuccess(data, variables, context);
        },
    });
};

export const useEnrollChildrenToGroupMutation = (
    mutation: { groupId: string },
    options: Omit<
        UseMutationOptions<GroupInterface, Error, string[]>,
        'mutationFn'
    > = {}
) => {
    const queryClient = useQueryClient();

    return useMutation({
        ...options,
        mutationFn: async (childrenToEnroll) => {
            const { group } = await enrollChildrenToGroup({
                groupId: mutation.groupId,
                childrenToEnroll,
            });
            return group;
        },
        onSuccess: async (data, variables, context) => {
            await queryClient.invalidateQueries([
                GROUPS_QUERY_KEY,
                { type: GROUPS_QUERY_TYPE.ALL_GROUPS },
            ]);
            await queryClient.invalidateQueries([
                GROUPS_QUERY_KEY,
                {
                    type: GROUPS_QUERY_TYPE.BY_PARENT_ID,
                    parentOrganizationId: data.parentOrgId,
                },
            ]);
            await queryClient.invalidateQueries([
                GROUP_BY_ID_QUERY_KEY,
                { groupId: data._id },
            ]);
            await queryClient.invalidateQueries([
                ENROLLABLE_CHILDREN_FOR_GROUP_QUERY_KEY,
                { groupId: data._id },
            ]);
            if (options.onSuccess)
                await options.onSuccess(data, variables, context);
        },
    });
};

export enum ARCHIVE_GROUP_MUTATION_TYPE {
    AS_ORGANIZATION_ADMIN = 'AS_ORGANIZATION_ADMIN',
    AS_PARENT_ORGANIZATION_ADMIN = 'AS_PARENT_ORGANIZATION_ADMIN',
    AS_GROUP_ADMIN = 'AS_GROUP_ADMIN',
}

export type ArchiveGroupMutation =
    | {
          type: ARCHIVE_GROUP_MUTATION_TYPE.AS_ORGANIZATION_ADMIN;
          parentOrganizationId?: null;
      }
    | {
          type: ARCHIVE_GROUP_MUTATION_TYPE.AS_PARENT_ORGANIZATION_ADMIN;
          parentOrganizationId: string;
      }
    | {
          type: ARCHIVE_GROUP_MUTATION_TYPE.AS_GROUP_ADMIN;
          parentOrganizationId: string;
      };

export const useArchiveGroupMutation = (
    mutation: ArchiveGroupMutation,
    options: UseMutationOptions<GroupInterface, Error, string> = {}
) => {
    const queryClient = useQueryClient();

    return useMutation<GroupInterface, Error, string>({
        ...options,
        mutationFn: async (groupToUpdateId: string) => {
            const { group } = await archiveGroup(groupToUpdateId);
            return group;
        },
        onSuccess: async (updatedGroup, groupToUpdateId, context) => {
            await queryClient.invalidateQueries([GROUPS_QUERY_KEY]);
            await queryClient.invalidateQueries([
                GROUP_BY_ID_QUERY_KEY,
                { groupId: groupToUpdateId },
            ]);
            if (!!options.onSuccess)
                await options.onSuccess(updatedGroup, groupToUpdateId, context);
        },
    });
};
