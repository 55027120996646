import { IonIcons } from 'react-ion-icon';
import NavTile from './NavTile';

const DashboardTiles = ({
    tiles,
}: {
    tiles: {
        iconName: IonIcons;
        title: string;
        color: string;
        number: string | number;
    }[];
}) => (
    <div className="flex flex-wrap items-center justify-center gap-4">
        {tiles.map(({ iconName, title, color, number }) => (
            <div
                key={`${title}-${iconName}`}
                className="min-w-[220px] flex-[0.33]"
            >
                <NavTile
                    title={title}
                    color={color}
                    iconName={iconName}
                    number={number}
                />
            </div>
        ))}
    </div>
);

export default DashboardTiles;
