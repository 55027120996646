import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './NonAuthScreen.css';
import ForgotPassword from './ForgotPassword';
import Landing from './Landing';
import LogIn from './LogIn';
import Register from './Register';
import SetPassword from './SetPassword';
import DeleteAccountOrData from './delete_account_or_data_webpage';

const NonAuthScreen = () => (
    <div className="non-auth-view">
        <Switch>
            <Route path="/nonauth/forgot-password" component={ForgotPassword} />
            <Route path="/nonauth/set-password" component={SetPassword} />
            <Route path="/nonauth/log-in" component={LogIn} />
            <Route path="/nonauth/register" component={Register} />
            <Route path="/nonauth/landing" component={Landing} />
            <Route
                path="/nonauth/account_or_data_deletion"
                component={DeleteAccountOrData}
            />
        </Switch>
    </div>
);

export default NonAuthScreen;
