import React, { useCallback, useMemo, useState } from 'react';
import { Column, Row } from 'react-table';
import { ENUM_ENTITY_TYPE, MODALS } from '../constants/common';
import {
    SHOULD_BE_APPROVED_BY,
    SHOULD_BE_APPROVED_BY_LABEL,
} from '../constants/orgs';
import TaskFormModal from '../forms/TaskFormModal';
import { KKTableV2 } from '../forms/TaskFormModal/KKTable';
import { useTasksQuery } from '../hooks/queries/tasks';
import useEntityAndParents from '../hooks/useEntityAndParents';
import { OrganizationInterface } from '../models/Organization';
import { TaskInterface } from '../models/OrganizationTask';
import appRepository from '../stores/AppDataStore';
import Breadcrumbs from './Breadcrumbs';
import DashboardContentSection from './DashboardContentSection';
import KKButton from './KKButton';
import KKIonIcon from './KKIonIcon';
import Navbar from './Navbar';

const useTasksTable = ({
    entityId,
    entityType,
    onEditTaskClick,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    onEditTaskClick: (taskId: string) => void;
}) => {
    const { data: tasks } = useTasksQuery({
        entityId,
        entityType,
    });

    const columns: Column<TaskInterface>[] = useMemo(
        () => [
            {
                id: 'name',
                accessor: (task) => task.name || task.choreInformation?.name,
                Header: 'Name',
            },
            {
                id: 'shouldBeApprovedBy',
                accessor: (task) => task.shouldBeApprovedBy,
                Header: 'Who approves this task?',
                Cell: ({ value }: { value: SHOULD_BE_APPROVED_BY[] }) => {
                    return value
                        .map((option) => SHOULD_BE_APPROVED_BY_LABEL[option])
                        .join(', ');
                },
            },
            {
                id: 'updatedAt',
                accessor: (task) => task.updatedAt,
                Header: 'Updated',
                Cell: ({ value }: { value: string }) =>
                    new Date(value).toLocaleDateString(),
            },
            {
                id: 'createdAt',
                accessor: (task) => task.createdAt,
                Header: 'Created',
                Cell: ({ value }: { value: string }) =>
                    new Date(value).toLocaleDateString(),
            },
            {
                id: 'options',
                Header: () => (
                    <div className="flex w-full items-center justify-center">
                        Options
                    </div>
                ),
                Cell: ({
                    row: {
                        original: { _id: taskId },
                    },
                }: {
                    row: Row<TaskInterface>;
                }) => (
                    <div className="flex w-full items-center justify-center">
                        <KKButton
                            type="secondary"
                            onClick={() => onEditTaskClick(taskId)}
                        >
                            <KKIonIcon
                                name="pencil-outline"
                                className="text-xl text-kk-dark-blue"
                            />
                        </KKButton>
                    </div>
                ),
            },
        ],
        []
    );

    const data: TaskInterface[] = useMemo(
        () => (Array.isArray(tasks) ? tasks : []),
        [tasks]
    );

    return { columns, data };
};

const EntityTasks: React.FunctionComponent<{
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}> = ({ entityId, entityType }) => {
    const [selectedTask, setSelectedTask] = useState<string | null>(null);
    const { entityAndParents } = useEntityAndParents({
        entityId,
        entityType,
    });
    const { columns, data } = useTasksTable({
        entityId,
        entityType,
        onEditTaskClick: (taskId) => {
            setSelectedTask(taskId);
            appRepository.setActiveModal(MODALS.CREATE_TASK);
        },
    });

    const entity = useMemo(
        () =>
            !!entityAndParents
                ? ([...entityAndParents].pop()?.data as OrganizationInterface)
                : null,
        [entityAndParents]
    );

    const closeTaskModal = useCallback(() => {
        setSelectedTask(null);
        appRepository.setActiveModal(null);
    }, [appRepository]);

    return (
        <Navbar>
            <Breadcrumbs
                entities={entityAndParents}
                suffix={[{ link: '/tasks', name: 'Tasks' }]}
            />
            <DashboardContentSection>
                {!!entity && <h2>{entity.basicInformation.name}</h2>}
            </DashboardContentSection>
            <DashboardContentSection
                title="Tasks"
                headerButtons={[
                    {
                        label: '+ Add Task',
                        type: 'primary',
                        onClick: () =>
                            appRepository.setActiveModal(MODALS.CREATE_TASK),
                    },
                ]}
            >
                <KKTableV2 columns={columns} data={data} />
            </DashboardContentSection>
            <TaskFormModal
                entityId={entityId}
                entityType={entityType}
                modalName={MODALS.CREATE_TASK}
                onModalClose={closeTaskModal}
                onAfterSubmit={closeTaskModal}
                edit={!!selectedTask}
                taskId={selectedTask}
            />
        </Navbar>
    );
};

export default EntityTasks;
