module.exports = {
    colors: {
        'kk-dark-blue': '#002759',
        'kk-light-blue': '#abc3f7',
        'kk-fountain-blue': '#68c1bd',
        'kk-background-blue': '#f3f6fb',
        'kk-background-green': '#F0FAF9',
        'kk-green': '#45B7B2',
        'kk-dark-green': '#3AA09C',
        'kk-navy': '#222533',
        'kk-status-green': '#34C759',
        'kk-status-yellow': '#FFCC22',
        'kk-status-red': '#FF3B30',
        'kk-status-grey': '#c7c7cc',
        'kk-status-purple': '#5856D6',
        'kk-light-lime': '#B9E458',
        'kk-lime': '#A6CB53',
        'kk-light-grey': '#F9F9F9',
        'kk-grey': '#576272',
        'kk-dark-grey': '#4c5563',
        'kk-coral': '#EE7C71',
        'kk-orange': '#F79247',
        'kk-red': '#DD4C39',
        'kk-yellow': '#FCBF2B',
        'kk-purple': '#6C74F5',
    },
    fontFamily: {
        nunito: ['Nunito', 'Sans serif'],
    },
};
