/* eslint-disable lines-between-class-members */
import { makeAutoObservable, observable, action } from 'mobx';

class GroupDataStore {
    //userGroup = {};

    groupId;
    groupMemberships;
    groupBasicInfo;
    ownerEmail;
    parentOrgId;
    rewards;
    children;

    constructor() {
        makeAutoObservable(this, {
            setGroupData: action,
        });
    }

    setGroupData(groupData) {
        this.groupId = groupData._id;
        this.groupMemberships = groupData.groupMemberships;
        this.groupBasicInfo = groupData.basicInformation;
        this.ownerEmail = groupData.ownerEmail;
        this.parentOrgId = groupData.parentOrgId;
        this.rewards = groupData.rewards;
        this.children = groupData.children;
    }
}

const groupRepository = new GroupDataStore();

export default groupRepository;
