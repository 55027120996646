import { useCallback, useState } from 'react';
import { GroupInterface } from '../models/Group';
import {
    GroupsQuery,
    GroupsQueryOptions,
    useGroupsQuery,
} from './queries/groups';

const useGroupsList = <TData = GroupInterface[]>(
    query: GroupsQuery,
    options: GroupsQueryOptions<TData> = { keepPreviousData: true }
) => {
    const [showArchived, setShowArchivedGroups] = useState(false);
    const groupsQueryResult = useGroupsQuery(query, options);

    const toggleShowArchived = useCallback(
        () => setShowArchivedGroups(!showArchived),
        [showArchived]
    );

    return {
        ...groupsQueryResult,
        showArchived,
        toggleShowArchived,
    };
};
export default useGroupsList;
