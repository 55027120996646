import './NotificationModal.css';

import errorIcon from '../../images/error-circle.svg';
import pandaAvatar from '../../images/parent-avatar.png';
import successIcon from '../../images/success-circle.svg';
import userRepository from '../../stores/UserDataStore';
import Modal from './Modal';

const NotificationModal = ({
    modalName,
    icon,
    title,
    body,
    handleClose,
    options,
}) => (
    <Modal modalName={modalName} options={options} onClose={handleClose}>
        <div className="flex max-h-full flex-col items-center justify-center">
            {icon === 'success' && (
                <img
                    className="success-icon h-24 w-24"
                    src={successIcon}
                    alt="success"
                />
            )}
            {icon === 'error' && (
                <img
                    className="error-icon h-24 w-24"
                    src={errorIcon}
                    alt="error"
                />
            )}
            {icon === 'user' && (
                <img
                    className="h-24 w-24 rounded-full"
                    src={userRepository.avatar || pandaAvatar}
                />
            )}
            <div className="notification-modal-title">{title}</div>
            <div className="notification-modal-body">{body}</div>
        </div>
    </Modal>
);

export const NotificationModalContent = ({ icon, title, body }) => (
    <div className="flex flex-col items-center justify-center">
        {icon === 'success' && (
            <img
                className="success-icon h-24 w-24"
                src={successIcon}
                alt="success"
            />
        )}
        {icon === 'error' && (
            <img className="error-icon h-24 w-24" src={errorIcon} alt="error" />
        )}
        {icon === 'user' && (
            <img
                className="h-24 w-24 rounded-full"
                src={userRepository.avatar || pandaAvatar}
            />
        )}
        <div className="notification-modal-title">{title}</div>
        <div className="notification-modal-body">{body}</div>
    </div>
);

export default NotificationModal;
