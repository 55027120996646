import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
    Col,
    FormSection,
    Row,
    Stack,
    TextInput,
} from '../../components/fields';
import { LocalLoading } from '../../components/loading';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';
import {
    CREATE_ORGANIZATION_MUTATION_TYPE,
    useCreateOrganizationMutation,
    useEditOrganizationMutation,
} from '../../hooks/mutations/organizations';
import { useOrganizationByIdQuery } from '../../hooks/queries/organizations';
import appRepository from '../../stores/AppDataStore';
import { organizationFormSchema, OrganizationFormSchema } from './schema';

interface OrganizationFormModalProps {
    edit?: boolean;
    organizationToUpdateId?: string;
    rootOrganizationId?: string;
    onAfterSubmit?: () => void;
}

const OrganizationFormModal: React.FunctionComponent<OrganizationFormModalProps> =
    ({ edit, organizationToUpdateId, rootOrganizationId, onAfterSubmit }) => {
        const organizationForm = useForm<OrganizationFormSchema>({
            resolver: yupResolver(organizationFormSchema),
        });

        const organizationByIdQuery = useOrganizationByIdQuery(
            organizationToUpdateId,
            {
                refetchOnWindowFocus: false,
                onSuccess: (data) => {
                    if (!data) return;
                    organizationForm.reset({
                        name: data.basicInformation.orgName,
                        adminEmails: data.adminEmails.join(', '),
                        contact: {
                            firstName: data.basicInformation.contactFirstName,
                            lastName: data.basicInformation.contactLastName,
                            email: data.basicInformation.contactEmail,
                            phoneNumber: data.basicInformation.contactPhone,
                            state: data.basicInformation.contactState,
                            city: data.basicInformation.contactCity,
                            zip: data.basicInformation.contactZip,
                            address: data.basicInformation.contactAddress,
                        },
                        logo: data.basicInformation.logo,
                        notes: data.basicInformation.notes,
                        website: data.basicInformation.website,
                    });
                },
            }
        );

        const createOrganizationMutation = useCreateOrganizationMutation(
            !!rootOrganizationId
                ? {
                      parentOrganizationId: rootOrganizationId,
                      type: CREATE_ORGANIZATION_MUTATION_TYPE.SUBORGANIZATION,
                  }
                : {
                      type: CREATE_ORGANIZATION_MUTATION_TYPE.ORGANIZATION,
                  }
        );

        const editOrganizationMutation = useEditOrganizationMutation({
            organizationId: organizationToUpdateId,
        });

        const onClose = useCallback(() => {
            appRepository.setActiveModal(null);
            organizationForm.reset();
        }, [organizationForm]);

        const onSubmit = useCallback(
            organizationForm.handleSubmit(async (organizationFormValues) => {
                try {
                    const payload = {
                        adminEmails:
                            organizationFormValues.adminEmails.split(','),
                        basicInformation: {
                            orgName: organizationFormValues.name,
                            notes: organizationFormValues.notes || '',
                            contactEmail: organizationFormValues.contact.email,
                            contactPhone:
                                organizationFormValues.contact.phoneNumber,
                            contactFirstName:
                                organizationFormValues.contact.firstName,
                            contactLastName:
                                organizationFormValues.contact.lastName,
                            contactCountry: 'US',
                            contactState: organizationFormValues.contact.state,
                            contactCity: organizationFormValues.contact.city,
                            contactZip:
                                organizationFormValues.contact.zip.toString(),
                            contactAddress:
                                organizationFormValues.contact.address,
                            website: organizationFormValues.website || '',
                            logo: organizationFormValues.logo || '',
                        },
                    };

                    if (!edit)
                        await createOrganizationMutation.mutateAsync(payload);
                    if (!!edit && organizationToUpdateId)
                        await editOrganizationMutation.mutateAsync(payload);
                    if (!!onAfterSubmit) onAfterSubmit();
                } catch (error) {
                    console.error(error);
                }
            }),
            [
                organizationForm,
                createOrganizationMutation,
                editOrganizationMutation,
            ]
        );

        const loading = useMemo(
            () =>
                organizationByIdQuery.isFetching ||
                createOrganizationMutation.isLoading,
            [
                organizationByIdQuery.isFetching,
                createOrganizationMutation.isLoading,
            ]
        );

        return (
            <Modal
                title="Organization Form"
                modalName={MODALS.CREATE_ORGANIZATION}
                options={[
                    { text: 'Cancel', type: 'secondary', onClick: onClose },
                    {
                        text: 'Submit',
                        type: 'primary',
                        disabled: loading,
                        onClick: onSubmit,
                    },
                ]}
            >
                {loading && <LocalLoading />}
                <Stack>
                    <FormSection title="Organization">
                        <Stack>
                            {[
                                {
                                    label: 'Name',
                                    name: 'name',
                                    placeholder:
                                        'Please enter organization name',
                                },
                                {
                                    label: 'Admin Email',
                                    name: 'adminEmails',
                                    placeholder:
                                        'Please enter organization admin email',
                                    disabled: !!edit,
                                },
                                {
                                    label: 'Logo (Optional)',
                                    name: 'logo',
                                    placeholder:
                                        'Please enter organization logo URL',
                                },
                                {
                                    label: 'Website (Optional)',
                                    name: 'website',
                                    placeholder:
                                        'Please enter organization website',
                                },
                                {
                                    label: 'Notes (Optional)',
                                    name: 'notes',
                                    placeholder:
                                        'Please enter organization notes',
                                    multiline: true,
                                },
                            ].map((field) => (
                                <Row key={field.name}>
                                    <TextInput
                                        form={organizationForm}
                                        name={field.name}
                                        label={field.label}
                                        placeholder={field.placeholder}
                                        multiline={!!field.multiline}
                                        variant="secondary"
                                        fullWidth
                                    />
                                </Row>
                            ))}
                        </Stack>
                    </FormSection>
                    <FormSection title="Contact">
                        <Stack>
                            <Row>
                                {[
                                    {
                                        label: 'First Name',
                                        name: 'contact.firstName',
                                        placeholder:
                                            'Please enter contact first name',
                                    },
                                    {
                                        label: 'Last Name',
                                        name: 'contact.lastName',
                                        placeholder:
                                            'Please enter contact last name',
                                    },
                                ].map((field) => (
                                    <Col
                                        size={6}
                                        className="first:pr-1 last:pl-1"
                                        key={field.name}
                                    >
                                        <TextInput
                                            form={organizationForm}
                                            name={field.name}
                                            label={field.label}
                                            placeholder={field.placeholder}
                                            variant="secondary"
                                            fullWidth
                                        />
                                    </Col>
                                ))}
                            </Row>
                            {[
                                {
                                    label: 'Email',
                                    name: 'contact.email',
                                    placeholder: 'Please enter contact email',
                                },
                                {
                                    label: 'Contact Phone Number',
                                    name: 'contact.phoneNumber',
                                    placeholder:
                                        'Please enter contact phone number',
                                },
                                { label: 'Address', name: 'contact.address' },
                            ].map((field) => (
                                <Row key={field.name}>
                                    <TextInput
                                        form={organizationForm}
                                        name={field.name}
                                        label={field.label}
                                        placeholder={field.placeholder}
                                        variant="secondary"
                                        fullWidth
                                    />
                                </Row>
                            ))}
                            <Row>
                                {[
                                    {
                                        label: 'City',
                                        name: 'contact.city',
                                    },
                                    {
                                        label: 'State',
                                        name: 'contact.state',
                                    },
                                    {
                                        label: 'ZIP',
                                        name: 'contact.zip',
                                        type: 'number',
                                    },
                                ].map((field) => (
                                    <Col
                                        size={4}
                                        className="px-1 first:pl-0 last:pr-0"
                                        key={field.name}
                                    >
                                        <TextInput
                                            form={organizationForm}
                                            name={field.name}
                                            label={field.label}
                                            variant="secondary"
                                            type={field.type || 'text'}
                                            fullWidth
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Stack>
                    </FormSection>
                </Stack>
            </Modal>
        );
    };

export default OrganizationFormModal;
