import classNames from 'classnames';
import React from 'react';
import loadingImg from '../images/tail-spin.svg';
import './loading.css';

const Loading: React.FunctionComponent<{
    relative?: boolean;
    hiddenText?: boolean;
    spinnerSize?: 'sm' | 'md' | 'lg';
}> = ({ relative = false, hiddenText = false, spinnerSize = 'md' }) => (
    <div
        className={`flex h-full w-full flex-col items-center justify-center ${classNames(
            {
                absolute: !relative,
                relative,
            }
        )}`}
    >
        {!hiddenText && <h3 className="my-4">Loading...</h3>}
        <img
            src={loadingImg}
            className={classNames({
                'h-4 w-4': spinnerSize === 'sm',
                'h-6 w-6': spinnerSize === 'md',
                'h-8 w-8': spinnerSize === 'lg',
            })}
            alt="Loading..."
        />
    </div>
);

export const LocalLoading = () => (
    <div className="spinner-local z-20">
        <img src={loadingImg} alt="Loading..." />
    </div>
);

export default Loading;
