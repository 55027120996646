/* eslint-disable lines-between-class-members */
import { action, makeAutoObservable, observable } from 'mobx';
import { KK_API_URL } from '../config/index';
import { getIdToken } from '../utils/localStorage';

class UserDataStore {
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    avatar: string = '';
    role: string = '';
    accessToken: string = '';
    idToken: string = '';
    authenticated: boolean = false;
    managedEntityIds = [];
    defaultDashboardUrl = '';
    points = null;
    hasRoles = false;
    entityLogo: any = null;
    entityType: string = '';
    entityId: string = '';
    entityName: string = '';
    msg: string | null = '';

    constructor() {
        makeAutoObservable(this, {
            firstName: observable,
            lastName: observable,
            email: observable,
            avatar: observable,
            role: observable,
            accessToken: observable,
            idToken: observable,
            setUserData: action,
            managedEntityIds: observable,
            defaultDashboardUrl: observable,
            points: observable,
            hasRoles: observable,
            entityLogo: observable,
            setEntityLogo: action,
            setBasicInfo: action,
            entityName: observable,
            entityType: observable,
            entityId: observable,
            msg: observable,
            setMsg: action,
        });
    }

    setMsg(msg: string) {
        this.msg = msg;
    }

    setEntityLogo(logo: string) {
        this.entityLogo = logo;
        localStorage.setItem('logo', logo);
    }

    setBasicInfo = (basicInfo: any) => {
        this.entityName = basicInfo.entityName;
        this.entityType = basicInfo.entityType;
        this.entityId = basicInfo.entityId;
        this.entityLogo = basicInfo.entityLogo;
        this.email = basicInfo.email;
        localStorage.removeItem('entityName');
        localStorage.setItem(
            'entityName',
            `${basicInfo.firstName} ${basicInfo.lastName}`
        );
        localStorage.setItem('logo', this.entityLogo);
    };

    setUserData = (user: any) => {
        this.firstName =
            user.given_name ||
            user.user_metadata?.firstName ||
            user.user_metadata?.first_name ||
            localStorage.getItem('firstName') ||
            user.email;
        this.lastName =
            user.family_name ||
            user.user_metadata?.lastName ||
            user.user_metadata?.last_name ||
            localStorage.getItem('LastName') ||
            '';
        this.email = user.email;
        this.avatar = user.avatar || user.picture;
        this.role = user.role.split('_').join(' ');
        this.authenticated = true;
        this.defaultDashboardUrl = user.defaultDashboardUrl;
    };

    isAuthenticated = async () => {
        try {
            if (this.authenticated) {
                return true;
            }

            // const { user: userData = null } = await fetchLoggedUserData();
            //if (!userData) return false;
            let userData: any = {};
            let response = await fetch(`${KK_API_URL}/users/roles`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${getIdToken()}`,
                },

                method: 'GET',
            });

            const data = await response.json();

            const dashboardUrl = data.roles[0].defaultDashboardUrl;
            const role = data.roles[0].role;
            userData.role = role;
            userData.defaultDashboardUrl = dashboardUrl;
            this.setUserData(userData);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    reset = () => {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.avatar = '';
        this.role = '';
        this.accessToken = '';
        this.idToken = '';
        this.authenticated = false;
    };
}

const userRepository = new UserDataStore();

export default userRepository;
