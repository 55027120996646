import axios, { AxiosRequestConfig } from 'axios';
import { KK_API_URL, KK_CONSUMER_BACKEND_API_URL } from '../config';
import { getIdToken } from '../utils/localStorage';

export const baseResponseHandler = (response: any) => response.data;

export interface IBaseRequestConfig {
    url?: string;
    body?: any;
    data?: string;
    params?: any;
    method?: string;
    headers?: any;
}

const URL = {
    ORGS: KK_API_URL,
    CONSUMER: KK_CONSUMER_BACKEND_API_URL,
};

export const baseRequest = async (
    relativeUrl: string,
    config: IBaseRequestConfig = {},
    backend: 'ORGS' | 'CONSUMER' = 'ORGS'
) => {
    try {
        let newConfig = { ...config };
        const idToken = getIdToken();

        newConfig = {
            ...newConfig,
            headers: {
                'Content-Type': 'application/json',
                ...(!!idToken && { Authorization: `Bearer ${idToken}` }),
            },
            url: `${URL[backend]}${relativeUrl}`,
        };

        if (config.body) {
            if (config.method !== 'GET') {
                newConfig.data = JSON.stringify(config.body);
            } else {
                newConfig.params = config.body;
            }
        }
        return axios(newConfig as AxiosRequestConfig).then(baseResponseHandler);
    } catch (error) {
        console.log(error);
    }
};
