/* eslint-disable lines-between-class-members */
import { makeAutoObservable, observable, action } from 'mobx';
import userRepository from './UserDataStore';
import groupRepository from './GroupDataStore';
import { baseRequest } from '../services/baseRequest';

class OrgDataStore {
    userOrg = {};
    orgs = [];
    subOrgsForUserOrg = [];
    userGroupParentOrg = {};
    userGroupRootOrg = {};
    orgId;
    orgName;
    logo;

    constructor() {
        makeAutoObservable(this, {
            userOrg: observable,
            orgs: observable,
            subOrgsForUserOrg: observable,
            userGroupParentOrg: observable,
            userGroupRootOrg: observable,
            setOrgData: action,
        });
    }

    // async setOrgData() {
    //     // Setting org data for SUPER ADMINS
    //     if (userRepository?.points === 100) {
    //         const orgsApiResult = await baseRequest(`/organizations`, {
    //             method: 'GET',
    //         });

    //         this.orgs = orgsApiResult || [];
    //     }

    //     // Setting org data for ORG ADMINS
    //     if (userRepository?.points === 90) {
    //         const userOrgApiResult = await baseRequest(
    //             `/organizations/${userRepository?.managedEntityIds[0]}`,
    //             {
    //                 method: 'GET',
    //             }
    //         );

    //         const subOrgsForUserOrgApiResult = await baseRequest(
    //             `/organizations/${userRepository?.managedEntityIds[0]}/suborganization`,
    //             {
    //                 method: 'GET',
    //             }
    //         );
    //         this.userOrg = userOrgApiResult || {};
    //         this.subOrgsForUserOrg = subOrgsForUserOrgApiResult || [];
    //     }

    //     // Setting org data for GROUP ADMINS
    //     if (userRepository?.points === 80) {
    //         if (groupRepository?.userGroup?.parentOrgId) {
    //             const userGroupParentOrgApiResult = await baseRequest(
    //                 `/organizations/${groupRepository?.userGroup?.parentOrgId}`,
    //                 {
    //                     method: 'GET',
    //                 }
    //             );
    //             this.userGroupParentOrg = userGroupParentOrgApiResult || {};
    //         }
    //         const userGroupRootOrgApiResult = await baseRequest(
    //             `/organizations/${groupRepository?.userGroup?.rootOrgId}`,
    //             {
    //                 method: 'GET',
    //             }
    //         );
    //         this.userGroupRootOrg = userGroupRootOrgApiResult || {};
    //     }
    // }

    setOrgData(orgData) {
        this.orgId = orgData._id;
        this.orgName = orgData.basicInformation.orgName;
        this.logo = orgData.basicInformation.logo;
    }
}

const orgRepository = new OrgDataStore();

export default orgRepository;
