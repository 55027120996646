import { useMutation, useQueryClient } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { TaskInterface } from '../../models/OrganizationTask';
import {
    createTaskForEntity,
    editTaskForEntity,
} from '../../services/api/orgTasksApi';
import { TASKS_QUERY_KEY, TASK_BY_ID_QUERY_KEY } from '../queries/tasks';

export const useCreateTaskMutation = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (
            variables: Omit<
                TaskInterface,
                'createdBy' | 'createdAt' | 'updatedAt' | '_id'
            >
        ) => createTaskForEntity({ entityId, entityType }, variables)(),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    TASKS_QUERY_KEY,
                    { entityId, entityType },
                ]);
            },
        }
    );
};

export const useEditTaskMutation = ({
    entityId,
    entityType,
    taskId,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    taskId?: string | null;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (
            variables: Omit<
                TaskInterface,
                'createdBy' | 'createdAt' | 'updatedAt' | '_id'
            >
        ) => {
            if (!taskId) throw new Error('No taskId provided');
            return editTaskForEntity(
                { entityId, entityType, rewardId: taskId },
                variables
            )();
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    TASKS_QUERY_KEY,
                    { entityId, entityType },
                ]);
                await queryClient.invalidateQueries([
                    TASK_BY_ID_QUERY_KEY,
                    { entityId, entityType, taskId },
                ]);
            },
        }
    );
};
