import * as yup from 'yup';

export const organizationFormSchema = yup.object({
    name: yup.string().label('Organization name').min(3).required(),
    adminEmails: yup
        .string()
        .required('Please enter a valid email address')
        .transform((_value, originalValue: string = '') =>
            originalValue
                .split(',')
                .map((email) => email.trim())
                .join(',')
        )
        .test({
            name: 'emails',
            test: (value: string | undefined, ctx) => {
                if (typeof value !== 'string') return false;

                const firstInvalidEmail = value
                    .split(',')
                    .find((v) => !yup.string().email().isValidSync(v));

                return !firstInvalidEmail
                    ? true
                    : ctx.createError({
                          message: `The email address '${firstInvalidEmail}' is invalid`,
                      });
            },
        })
        .test({
            name: 'unique-emails',
            test: (value: string | undefined, ctx) => {
                if (typeof value !== 'string') return false;

                const emailAddresses = value.split(',');

                const areAllUnique =
                    emailAddresses.length === new Set(emailAddresses).size;

                return areAllUnique
                    ? true
                    : ctx.createError({
                          message: `Emails must be unique`,
                      });
            },
        }),
    contact: yup.object({
        firstName: yup.string().label('Contact first name').required(),
        lastName: yup.string().label('Contact last name').required(),
        email: yup.string().label('Contact email').required(),
        phoneNumber: yup.string().label('Contact phone number').required(),
        state: yup.string().label('State').required(),
        city: yup.string().label('City').required(),
        zip: yup.lazy((value) =>
            value === ''
                ? yup.string().label('ZIP').required()
                : yup.number().label('ZIP').required()
        ),
        address: yup.string().label('Address').required(),
    }),
    logo: yup.string().label('Organization logo').url().optional(),
    website: yup.string().label('Organization website').url().optional(),
    notes: yup.string().optional(),
});

export type OrganizationFormSchema = yup.InferType<
    typeof organizationFormSchema
>;
