import { useMutation, useQueryClient } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { AssignmentInterface } from '../../models/Assignment';
import {
    createAssignment,
    editAssignment,
} from '../../services/api/assignmentsApi';
import {
    ASSIGNMENTS_ASSIGNED_TO_QUERY_KEY,
    ASSIGNMENTS_QUERY_KEY,
    ASSIGNMENT_QUERY_KEY,
} from '../queries/assignments';

export const useCreateAssignmentMutation = ({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: Omit<AssignmentInterface, 'createdBy'>) =>
            createAssignment({ entityType, entityId, assignment: variables }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    ASSIGNMENTS_QUERY_KEY,
                    { entityId, entityType },
                ]);
                await queryClient.invalidateQueries([
                    ASSIGNMENTS_ASSIGNED_TO_QUERY_KEY,
                    { entityId, entityType },
                ]);
            },
        }
    );
};

export const useEditAssignmentMutation = ({
    entityType,
    entityId,
    assignmentId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
    assignmentId?: string | null;
}) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: Omit<AssignmentInterface, 'createdBy'>) => {
            if (!assignmentId) throw new Error('Assignment id not provided');
            return editAssignment({
                entityType,
                entityId,
                assignmentId,
                assignment: variables,
            });
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    ASSIGNMENT_QUERY_KEY,
                    { assignmentId },
                ]);
                await queryClient.invalidateQueries([
                    ASSIGNMENTS_QUERY_KEY,
                    { entityId, entityType },
                ]);
                await queryClient.invalidateQueries([
                    ASSIGNMENTS_ASSIGNED_TO_QUERY_KEY,
                    { entityId, entityType },
                ]);
            },
        }
    );
};
