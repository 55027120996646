import classNames from 'classnames';
import React from 'react';
import { IonIcons } from 'react-ion-icon';
import { colors } from '../config/tailwind';
import KKIonIcon from './KKIonIcon';

type ThemeColors = keyof typeof colors;
interface CommonQuickActionTileProps {
    onClick: () => void;
    title: string;
    titleColor?: ThemeColors | Omit<string, ThemeColors>;
    ionIcon: string;
    iconColor?: ThemeColors | Omit<string, ThemeColors>;
    backgroundColor?: ThemeColors | Omit<string, ThemeColors>;
    borderColor?: ThemeColors | Omit<string, ThemeColors>;
    style?: Record<string, any>;
}

export interface QuickActionMdTile extends CommonQuickActionTileProps {
    size: 'md';
    secondaryTitle?: undefined;
    secondaryTitleColor?: undefined;
    secondaryIonIcon?: undefined;
    secondaryIonIconSrc?: undefined;
    secondaryIconColor?: undefined;
    secondaryOnClick?: () => undefined;
}

export interface QuickActionLgTile extends CommonQuickActionTileProps {
    size: 'lg';
    secondaryTitle: string;
    secondaryTitleColor?: ThemeColors | Omit<string, ThemeColors>;
    secondaryIonIcon?: IonIcons;
    secondaryIonIconSrc?: string;
    secondaryIconColor?: ThemeColors | Omit<string, ThemeColors>;
    secondaryOnClick: () => void;
}

export type QuickActionsTileProps = QuickActionMdTile | QuickActionLgTile;

const QuickActionsTile: React.FunctionComponent<QuickActionsTileProps> = ({
    onClick,
    size = 'md',
    title,
    titleColor = 'black',
    ionIcon,
    iconColor = 'kk-dark-blue',
    secondaryTitle,
    secondaryTitleColor,
    secondaryIonIcon,
    secondaryIonIconSrc,
    secondaryIconColor = 'black',
    secondaryOnClick = () => null,
    borderColor = 'transparent',
    backgroundColor = 'white',
    style,
}) => (
    <button
        type="button"
        className={`text-decoration-none m-2 flex shrink-0 flex-col items-center rounded-3xl py-10 shadow-md hover:shadow-lg ${classNames(
            {
                'h-56 w-56': size === 'lg',
                'h-48 w-48': size === 'md',
            }
        )}`}
        onClick={onClick}
        style={{
            backgroundColor:
                (colors[backgroundColor as ThemeColors] as string) ||
                (backgroundColor as string),
            border: `1px solid`,
            borderColor:
                (colors[borderColor as ThemeColors] as string) ||
                (borderColor as string),
            cursor: 'pointer',
            ...style,
        }}
    >
        <KKIonIcon
            name={ionIcon as IonIcons}
            className="shrink-0 text-6xl text-kk-dark-blue"
            style={{
                color:
                    (colors[iconColor as ThemeColors] as string) ||
                    (iconColor as string),
            }}
        />
        <div className="flex grow items-center">
            <p
                className="mx-6 text-base font-bold"
                style={{
                    color:
                        colors[titleColor as ThemeColors] ||
                        (titleColor as string),
                }}
            >
                {title}
            </p>
        </div>
        {size === 'lg' && (
            <div
                className="flex items-center justify-center gap-1 hover:underline"
                onClick={(e) => {
                    e.stopPropagation();
                    secondaryOnClick();
                }}
            >
                <KKIonIcon
                    name={secondaryIonIcon}
                    src={secondaryIonIconSrc}
                    className="px-1 text-xl"
                    style={{
                        color:
                            (colors[
                                secondaryIconColor as ThemeColors
                            ] as string) || (secondaryIconColor as string),
                    }}
                />
                <div
                    className="px-1"
                    style={{
                        color:
                            colors[secondaryTitleColor as ThemeColors] ||
                            (secondaryTitleColor as string),
                    }}
                >
                    {secondaryTitle}
                </div>
            </div>
        )}
    </button>
);

export default QuickActionsTile;
