import { useMemo } from 'react';
import {
    OrganizationEntityInterface,
    GroupEntityInterface,
} from '../models/Entity';
import { OrganizationInterface } from '../models/Organization';
import { GroupInterface } from '../models/Group';
import { ENUM_ENTITY_TYPE } from '../constants/common';

export const useEntityDataAndType = ({
    entityType,
    entityData,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityData: OrganizationInterface | GroupInterface | null;
}) =>
    useMemo(() => {
        switch (entityType) {
            case ENUM_ENTITY_TYPE.ORGANIZATION:
                return {
                    data: entityData as OrganizationInterface,
                    type: ENUM_ENTITY_TYPE.ORGANIZATION,
                } as OrganizationEntityInterface;

            case ENUM_ENTITY_TYPE.GROUP:
                return {
                    data: entityData as GroupInterface,
                    type: ENUM_ENTITY_TYPE.GROUP,
                } as GroupEntityInterface;

            default:
                return null;
        }
    }, [entityType, entityData]);
