import React, { AllHTMLAttributes } from 'react';
import { IonIcons } from 'react-ion-icon';

const KKIonIcon: React.FunctionComponent<
    {
        name?: IonIcons;
        src?: string;
        customStrokeWidth?: boolean;
    } & AllHTMLAttributes<HTMLDivElement>
> = ({ name, src, className, customStrokeWidth, ...htmlProps }) => {
    const onNodeMount = (node: HTMLElement) => {
        if (!customStrokeWidth) return;
        if (!node?.shadowRoot) return;

        const style = document.createElement('style');
        style.innerHTML = 'svg path{ stroke-width: inherit !important };';
        node.shadowRoot.appendChild(style);
    };

    return (
        <ion-icon
            ref={onNodeMount}
            class={`hydrated ${className}`}
            name={name}
            src={src}
            {...htmlProps}
        />
    );
};

export default KKIonIcon;
