import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import Alerts from '../../../components/Alerts';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DashboardContentSection from '../../../components/DashboardContentSection';
import KKButton from '../../../components/KKButton';
import Loading from '../../../components/loading';
import Navbar from '../../../components/Navbar';
import QuickActions, {
    quickActionLgTileFactory,
    quickActionMdTileFactory,
} from '../../../components/QuickActions';
import DashboardCard from '../../../components/tiles/DashboardCard';
import DashboardTiles from '../../../components/tiles/DashboardTiles';
import { navTiles } from '../../../components/tiles/navTiles';
import { ENUM_ENTITY_TYPE, MODALS } from '../../../constants/common';
import AddChildrenToGroupModal from '../../../forms/AddChildrenToGroupModal';
import AlertModal from '../../../forms/AlertFormModal';
import AssignmentModal from '../../../forms/AssignmentFormModal';
import { KKTableV2 } from '../../../forms/TaskFormModal/KKTable';
import { useAssignmentsAssignedToQuery } from '../../../hooks/queries/assignments';
import { useEntityAndParentsQuery } from '../../../hooks/queries/entities';
import {
    useGroupByIdQuery,
    useGroupEnrolledChildrenQuery,
} from '../../../hooks/queries/groups';
import { FamilyUnitChildInfoInterface } from '../../../models/FamilyUnit';
import appRepository from '../../../stores/AppDataStore';
import { getChildAge } from '../../../utils/familyUnitHelpers';

const useChildrenTable = ({ groupId }: { groupId: string }) => {
    const enrolledChildren = useGroupEnrolledChildrenQuery(groupId);

    const columns: Column<FamilyUnitChildInfoInterface>[] = useMemo(
        () => [
            { id: 'name', accessor: (child) => child.name, Header: 'Name' },
            {
                id: 'birthdate',
                accessor: (child) =>
                    new Date(child.dob.split('/').join('-')).toLocaleString(
                        'en-US',
                        {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                        }
                    ),
                Header: 'Birthdate',
            },
            {
                id: 'age',
                Header: 'Age',
                Cell: ({
                    row: { original },
                }: CellProps<FamilyUnitChildInfoInterface>) =>
                    getChildAge(original.dob),
            },
        ],
        []
    );

    const data = useMemo(
        () =>
            Array.isArray(enrolledChildren.data) ? enrolledChildren.data : [],
        [enrolledChildren.data]
    );

    return { columns, data };
};

const Group = () => {
    const history = useHistory();
    const { pathname: currentPathname } = useLocation();
    const { groupId } = useParams<{ groupId: string }>();

    const entityAndParentsQuery = useEntityAndParentsQuery({
        entityId: groupId,
        entityType: ENUM_ENTITY_TYPE.GROUP,
    });

    const groupQuery = useGroupByIdQuery(groupId);

    const assignmentsQuery = useAssignmentsAssignedToQuery({
        entityId: groupId,
        entityType: ENUM_ENTITY_TYPE.GROUP,
    });

    const childrenTable = useChildrenTable({ groupId });

    if (entityAndParentsQuery.isLoading) {
        return (
            <Navbar>
                <Loading />
            </Navbar>
        );
    }

    return (
        <Navbar>
            <Breadcrumbs entities={entityAndParentsQuery.data} />
            <DashboardContentSection>
                <h2>
                    {!!groupQuery.data &&
                        groupQuery.data.basicInformation.groupName}
                </h2>
                <KKButton
                    type="text"
                    onClick={() => history.push(`${currentPathname}/details`)}
                    className="self-start"
                >
                    View details
                </KKButton>
            </DashboardContentSection>
            <DashboardContentSection>
                <DashboardTiles
                    tiles={[
                        navTiles.children(
                            groupQuery.data?.groupMemberships.length
                        ),
                        navTiles.assignments(assignmentsQuery.data?.length),
                    ]}
                />
            </DashboardContentSection>
            <DashboardContentSection>
                <div className="flex w-full gap-4">
                    <Alerts
                        entityType={ENUM_ENTITY_TYPE.GROUP}
                        entityId={groupId}
                    />
                </div>
            </DashboardContentSection>
            <DashboardContentSection>
                <DashboardCard title="Quick Actions">
                    <QuickActions
                        tiles={[
                            quickActionMdTileFactory.addChildren(() =>
                                appRepository?.setActiveModal(
                                    MODALS.ADD_CHILDREN_TO_GROUP
                                )
                            ),
                            quickActionMdTileFactory.alertFamilies(() =>
                                appRepository.setActiveModal(
                                    MODALS.CREATE_ALERT_FOR_FAMILIES
                                )
                            ),
                            quickActionMdTileFactory.myTasks(() =>
                                history.push(`${currentPathname}/tasks`)
                            ),
                            quickActionMdTileFactory.myRewards(() =>
                                history.push(`${currentPathname}/rewards`)
                            ),
                        ]}
                        middleTile={quickActionLgTileFactory.createAssignment(
                            () =>
                                appRepository.setActiveModal(
                                    MODALS.CREATE_ASSIGNMENT
                                ),
                            () => history.push(`${currentPathname}/assignments`)
                        )}
                    />
                </DashboardCard>
                {!!groupQuery.data && (
                    <AssignmentModal
                        entity={{
                            data: groupQuery.data,
                            type: ENUM_ENTITY_TYPE.GROUP,
                        }}
                    />
                )}
                {!!groupQuery.data && (
                    <AlertModal
                        entity={{
                            data: groupQuery.data,
                            type: ENUM_ENTITY_TYPE.GROUP,
                        }}
                    />
                )}
            </DashboardContentSection>
            <DashboardContentSection title="Children">
                <KKTableV2
                    columns={childrenTable.columns}
                    data={childrenTable.data}
                />
            </DashboardContentSection>
            <AddChildrenToGroupModal groupId={groupId} />
        </Navbar>
    );
};

export default Group;
