import './RegistrationPage.css';

import KKButton from '../components/KKButton';
import kkLogo from '../images/logos/kk-logo-word-gray.png';

const RegistrationPage = (props) => (
    <div className="registration-modal">
        <div className="registration-modal-content">
            <div className="registration-modal-header">
                <img src={kkLogo} alt="Kiddie Kredit Logo" />
            </div>
            <div className="registration-modal-title">{props.title}</div>
            <div className="registration-modal-body">{props.form}</div>
            <div className="registration-modal-button">
                <KKButton onClick={props.handleSubmit} type="primary">
                    {props.btn}
                </KKButton>
            </div>
            <div className="registration-modal-spacer"></div>
            <div className="registration-modal-footer">
                <p>
                    By signing up, you agree to our{' '}
                    <span>
                        <a href="#">Terms, Data Policy</a>
                    </span>{' '}
                    and{' '}
                    <span>
                        <a href="#">Cookies Policy</a>
                    </span>
                    .
                </p>
            </div>
        </div>
    </div>
);

export default RegistrationPage;
