import React from 'react';

import RegistrationPage from '../../pages/RegistrationPage';

const SetPassword = () => {
    
    const setPasswordForm = (
        <form action="" className="form-modal-form">
            <label className="form-label" htmlFor="password">
                Enter Password
            </label>
            <input
                className="form-input"
                type="text"
                id="password"
                name="password"
                placeholder="Password"
            />
            <label className="form-label" htmlFor="repeat-password">
                Repeat Password
            </label>
            <input
                className="form-input"
                type="text"
                id="repeat-password"
                name="repeat-password"
                placeholder="Password"
            />
        </form>
    );

    return (
        <RegistrationPage
            form={setPasswordForm}
            title="Welcome!"
            handleSubmit={null}
            btn="Sign Up"
        />
    );
};

export default SetPassword;
