import { RewardInterface } from './OrganizationReward';
import { OrganizationTaskInterface, TaskInterface } from './OrganizationTask';

export enum ASSIGNEE_SELECTION {
    ALL_CHILDREN = 'ALL_CHILDREN',
    SPECIFIC_CHILDREN = 'SPECIFIC_CHILDREN',
}

export interface AssigneeInterface {
    group: string;
    selection: ASSIGNEE_SELECTION;
    children: string[];
}

export type CreatedBy =
    | {
          organization: string;
          group?: null;
      }
    | { organization?: null; group: string };

export interface AssignmentInterface {
    _id?: string;
    createdBy: CreatedBy;
    name: string;
    notes: string;
    rewardMessage: string;
    linkedWebpage: string;
    rewards: { reward: string | RewardInterface }[];
    tasks: { task: string | TaskInterface; reps: number }[];
    assignedTo: AssigneeInterface[];
    startDate: number;
    endDate: number;
}

export interface PopulatedAssignmentInterface
    extends Omit<AssignmentInterface, 'rewards' | 'tasks'> {
    rewards: { reward: RewardInterface }[];
    tasks: { task: OrganizationTaskInterface; reps: number }[];
}
