import React, { useCallback, useMemo, useState } from 'react';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import {
    useCreateAssignmentMutation,
    useEditAssignmentMutation,
} from '../../hooks/mutations/assignments';
import { useAssignmentByIdQuery } from '../../hooks/queries/assignments';
import { ASSIGNEE_SELECTION } from '../../models/Assignment';
import {
    Entity,
    FamilyUnitChildEntityInterface,
    GroupEntityInterface,
} from '../../models/Entity';
import appRepository from '../../stores/AppDataStore';
import AssignmentData from './AssignmentData';
import AssignmentReview from './AssignmentReview';
import RewardMessage from './RewardMessage';
import SelectEntities from './SelectEntities';
import SelectTasksAndRewards from './SelectTasksAndRewards';
import { AssignmentFormData } from './shared';
import groupRepository from '../../stores/GroupDataStore';
import userRepository from '../../stores/UserDataStore';

const AssignmentModal: React.FunctionComponent<{
    entity: Entity;
    edit?: boolean;
    assignmentId?: string | null;
    onModalClose?: () => Promise<any> | any;
}> = ({ entity, edit, assignmentId, onModalClose: onAfterModalClose }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState<AssignmentFormData>({
        name: '',
        notes: '',
        selectedEntities: [],
        selectedTasks: [],
        selectedRewards: [],
        rewardMessage: '',
        linkedWebpage: '',
    });

    const assignmentByIdQuery = useAssignmentByIdQuery(
        {
            assignmentId,
            entityId: entity.data._id,
            entityType: entity.type,
        },
        {
            refetchOnWindowFocus: false,
            enabled: edit && !!assignmentId,
            onSuccess: (data) => {
                setFormData({
                    name: data.name,
                    notes: data.notes,
                    linkedWebpage: data.linkedWebpage,
                    rewardMessage: data.rewardMessage,
                    selectedEntities: data.assignedTo.map(
                        ({ group: groupId }) =>
                            ({
                                data: { _id: groupId },
                                type: ENUM_ENTITY_TYPE.GROUP,
                            } as GroupEntityInterface)
                    ),
                    selectedRewards: data.rewards.map(({ reward }) => ({
                        id: reward._id,
                        name: reward.name,
                    })),
                    selectedTasks: data.tasks.map(({ task, reps }) => ({
                        id: task._id,
                        name: task.name,
                        reps,
                    })),
                });
            },
        }
    );

    const createAssignmentMutation = useCreateAssignmentMutation({
        entityId: entity.data._id,
        entityType: entity.type,
    });

    const editAssignmentMutation = useEditAssignmentMutation({
        entityId: entity.data._id,
        entityType: entity.type,
        assignmentId,
    });

    const defaultAssignmentName = useMemo(
        () =>
            [
                formData.selectedTasks.map(({ name }) => name)[0]?.trim(),
                formData.selectedTasks.length > 1
                    ? `& ${formData.selectedTasks.length - 1} more`
                    : '',
                'for',
                formData.selectedRewards.map(({ name }) => name)[0]?.trim(),
                formData.selectedRewards.length > 1
                    ? `& ${formData.selectedRewards.length - 1} more`
                    : '',
            ]
                .filter((stringValue) => !!stringValue)
                .join(' '),
        [formData]
    );

    const onModalClose = useCallback(() => {
        appRepository.setActiveModal(null);
        setFormData({
            name: '',
            notes: '',
            selectedEntities: [],
            selectedTasks: [],
            selectedRewards: [],
            rewardMessage: '',
            linkedWebpage: '',
        });
        setStep(1);
        if (!!onAfterModalClose) onAfterModalClose();
    }, [step]);

    const onNext = useCallback(
        (values: Partial<AssignmentFormData>) => {
            setFormData({ ...formData, ...values });
            setStep(step + 1);
        },
        [formData, step]
    );

    const onPrevious = useCallback(
        (values: Partial<AssignmentFormData>) => {
            setFormData({ ...formData, ...values });
            setStep(step - 1);
        },
        [formData, step]
    );

    const onFinish = useCallback(async () => {
        try {
            const selectedChildrenByGroupId = formData.selectedEntities
                .filter(
                    (
                        selectedEntity
                    ): selectedEntity is FamilyUnitChildEntityInterface =>
                        !!selectedEntity &&
                        selectedEntity.type ===
                            ENUM_ENTITY_TYPE.FAMILY_UNIT_CHILD
                )
                .reduce((result, selectedFamilyChild) => {
                    const groupId = selectedFamilyChild.data.parentGroupId;
                    if (!result[groupId]) result[groupId] = [];
                    result[groupId].push(selectedFamilyChild.data.compoundKey);
                    return result;
                }, {} as { [groupId: string]: string[] });

            let assignedTo: any = formData.selectedEntities
                .filter(
                    (selectedEntity) =>
                        (!!selectedEntity &&
                            selectedEntity.type === ENUM_ENTITY_TYPE.GROUP) ||
                        selectedEntity.type ===
                            ENUM_ENTITY_TYPE.FAMILY_UNIT_CHILD
                )
                .map((selectedGroup) => {
                    if (
                        selectedGroup.type ===
                        ENUM_ENTITY_TYPE.FAMILY_UNIT_CHILD
                    ) {
                        selectedGroup.data._id =
                            selectedGroup.data.parentGroupId;
                    }

                    const childrenSelectionType =
                        Array.isArray(
                            selectedChildrenByGroupId[selectedGroup.data._id]
                        ) &&
                        selectedChildrenByGroupId[selectedGroup.data._id]
                            .length > 0 &&
                        selectedChildrenByGroupId[selectedGroup.data._id]
                            .length < groupRepository.groupMemberships.length
                            ? ASSIGNEE_SELECTION.SPECIFIC_CHILDREN
                            : ASSIGNEE_SELECTION.ALL_CHILDREN;
                    return {
                        group: selectedGroup.data._id,
                        children:
                            childrenSelectionType ===
                            ASSIGNEE_SELECTION.SPECIFIC_CHILDREN
                                ? selectedChildrenByGroupId[
                                      selectedGroup.data._id
                                  ]
                                : [],
                        selection: childrenSelectionType,
                    };
                });

            if (userRepository.entityType === ENUM_ENTITY_TYPE.GROUP) {
                assignedTo = [assignedTo.pop()];
            }

            const requestPayload = {
                name: formData.name || defaultAssignmentName,
                notes: formData.notes,
                rewardMessage: formData.rewardMessage,
                linkedWebpage: formData.linkedWebpage,
                tasks: formData.selectedTasks.map(({ id, reps }) => ({
                    task: id,
                    reps,
                })),
                rewards: formData.selectedRewards.map(({ id }) => ({
                    reward: id,
                })),
                assignedTo,
                startDate: Date.now(),
                endDate: Date.parse('01 Jan 2100 00:00:00 +00:00'),
            };

            if (!edit)
                await createAssignmentMutation.mutateAsync(requestPayload);

            if (edit) await editAssignmentMutation.mutateAsync(requestPayload);

            onModalClose();
        } catch (error) {
            console.error(error);
        }
    }, [formData]);

    return (
        <>
            {step === 1 && (
                <SelectEntities
                    entity={entity}
                    selectedEntities={formData.selectedEntities}
                    onNext={onNext}
                    onClose={onModalClose}
                    isLoading={
                        assignmentByIdQuery.isLoading ||
                        assignmentByIdQuery.isFetching
                    }
                />
            )}
            {step === 2 && (
                <SelectTasksAndRewards
                    entity={entity}
                    selectedTasks={formData.selectedTasks}
                    selectedRewards={formData.selectedRewards}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onClose={onModalClose}
                />
            )}
            {step === 3 && (
                <AssignmentData
                    name={formData.name}
                    defaultName={defaultAssignmentName}
                    notes={formData.notes}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    onClose={onModalClose}
                />
            )}
            {step === 4 && (
                <RewardMessage
                    rewardMessage={formData.rewardMessage}
                    linkedWebpage={formData.linkedWebpage}
                    onClose={onModalClose}
                    onNext={onNext}
                    onPrevious={onPrevious}
                />
            )}
            {step === 5 && (
                <AssignmentReview
                    formData={formData}
                    onPrevious={() => setStep(step - 1)}
                    onFinish={onFinish}
                    isLoading={
                        createAssignmentMutation.isLoading ||
                        editAssignmentMutation.isLoading
                    }
                />
            )}
        </>
    );
};

export default AssignmentModal;
