import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardRouter from './dashboard/DashboardRouter';
import userRepository from '../stores/UserDataStore';

const AuthRouter = () => (
    <Switch>
        <Route path={`/dashboard`} component={DashboardRouter} />
        <Route path={`/`} render={() => <Redirect to={`${userRepository.defaultDashboardUrl}`} />} />
    </Switch>
);

export default AuthRouter;
