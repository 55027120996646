import { IonIcons } from 'react-ion-icon';
import tailwind from '../../config/tailwind';

export const navTiles = {
    organizations: (number?: string | number) => ({
        iconName: 'business-outline' as IonIcons,
        title: 'Organizations',
        number: number || '-',
        color: tailwind.colors['kk-orange'],
    }),
    subOrganizations: (number?: string | number) => ({
        iconName: 'storefront-outline' as IonIcons,
        title: 'Sub-Organizations',
        number: number || '-',
        color: tailwind.colors['kk-purple'],
    }),
    groups: (number?: string | number) => ({
        iconName: 'people-outline' as IonIcons,
        title: 'Groups',
        number: number || '-',
        color: tailwind.colors['kk-dark-green'],
    }),
    familyUnits: (number?: string | number) => ({
        iconName: 'home-outline' as IonIcons,
        title: 'Families',
        number: number || '-',
        color: tailwind.colors['kk-coral'],
    }),
    users: (number?: string | number) => ({
        iconName: 'person-circle-outline' as IonIcons,
        title: 'Users',
        number: number || '-',
        color: tailwind.colors['kk-green'],
    }),
    assignments: (number?: string | number) => ({
        iconName: 'list-outline' as IonIcons,
        title: 'Assignments',
        number: number || '-',
        color: tailwind.colors['kk-yellow'],
    }),
    children: (number?: string | number) => ({
        iconName: 'accessibility-outline' as IonIcons,
        title: 'Children',
        number: number || '-',
        link: '/home/dashboard/organizations/sub-organizations/groups/:groupId/children' as IonIcons,
        color: tailwind.colors['kk-green'],
    }),
};
