import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { DeepPartial, useForm } from 'react-hook-form';
import {
    ControlledCheckboxSelection,
    Stack,
    TextInput,
} from '../../components/fields';
import { LocalLoading } from '../../components/loading';
import Modal from '../../components/modals/Modal';
import { ENUM_ENTITY_TYPE, MODALS } from '../../constants/common';
import { SHOULD_BE_APPROVED_BY_LABEL } from '../../constants/orgs';
import { SHOULD_BE_APPROVED_BY } from '../../constants/orgTasks';
import {
    useCreateTaskMutation,
    useEditTaskMutation,
} from '../../hooks/mutations/tasks';
import { useTaskByIdQuery } from '../../hooks/queries/tasks';
import { TaskInterface } from '../../models/OrganizationTask';
import appRepository from '../../stores/AppDataStore';
import {
    taskFormDefaultValues,
    TaskFormValues,
    taskValidationSchema,
} from './schema';

interface TaskFormProps {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    onModalClose?: () => any;
    onAfterSubmit?: () => any;
    formId?: string;
    taskId?: string | null;
    edit?: boolean;
    modalName?: MODALS;
}

const TaskFormModal: React.FunctionComponent<TaskFormProps> = ({
    entityId,
    entityType,
    onModalClose = () => appRepository.setActiveModal(null),
    onAfterSubmit = () => null,
    formId = 'task-form',
    edit = false,
    taskId = null,
    modalName,
}) => {
    const taskByIdQuery = useTaskByIdQuery(
        {
            entityId,
            entityType,
            taskId,
        },
        {
            onSuccess: (taskToUpdate) => {
                // const taskRRule = rrulestr(
                //     taskToUpdate.choreInformation.repetitionRule
                // );

                const formDefaultValues: TaskFormValues = {
                    name:
                        taskToUpdate.name ||
                        taskToUpdate.choreInformation?.name ||
                        '',
                    description:
                        taskToUpdate.notes ||
                        taskToUpdate.choreInformation?.notes ||
                        '',
                    shouldBeApprovedBy: taskToUpdate.shouldBeApprovedBy || [
                        SHOULD_BE_APPROVED_BY.GROUP_ADMIN,
                    ],
                    // frequency: !!taskToUpdate.oneTime
                    //     ? ONE_TIME_FREQUENCY_OPTION
                    //     : taskRRule.options.freq.toString(),
                    // daysOfWeek: taskRRule.options.byweekday.map(
                    //     (dayIdx) => ALL_WEEKDAYS[dayIdx]
                    // ),
                    // nthInstanceOfDayOfWeekInMonth: Array.isArray(
                    //     taskRRule.options.bysetpos
                    // )
                    //     ? taskRRule.options.bysetpos[0].toString()
                    //     : null,
                    // oneTimeDueDate: !!taskToUpdate.oneTime
                    //     ? getOneTimeDueDate(taskRRule)
                    //     : null,
                    // start: new Date(taskToUpdate.choreInformation.startDate)
                    //     .toISOString()
                    //     .split('T')[0],
                    // end: new Date(taskToUpdate.choreInformation.endDate)
                    //     .toISOString()
                    //     .split('T')[0],
                };

                taskForm.reset(formDefaultValues);
            },
        }
    );
    const { mutateAsync: createTask, isLoading: isCreateTaskLoading } = useCreateTaskMutation({
        entityId,
        entityType,
    });
    const { mutateAsync: editTask, isLoading: isEditTaskLoading } = useEditTaskMutation({
        entityId,
        entityType,
        taskId,
    });
    const taskForm = useForm<TaskFormValues>({
        defaultValues: taskValidationSchema.getDefault(),
        resolver: yupResolver(taskValidationSchema),
    });
    // const frequency = taskForm.watch('frequency');
    // const oneTimeDueDate = taskForm.watch('oneTimeDueDate');

    const loading = useMemo(
        () => taskByIdQuery.isFetching,
        [taskByIdQuery.isFetching]
    );

    // const onFrequencyChange = useCallback(() => {
    //     if (loading) return;
    //     taskForm.setValue('daysOfWeek', []);
    //     taskForm.setValue(
    //         'oneTimeDueDate',
    //         frequency === ONE_TIME_FREQUENCY_OPTION
    //             ? ONE_TIME_DUE_DATE.NO_DUE_DATE
    //             : null
    //     );
    //     taskForm.setValue('nthInstanceOfDayOfWeekInMonth', null);
    // }, [loading, taskForm, frequency]);

    // const onOneTimeDueDateChange = useCallback(() => {
    //     if (loading) return;
    //     const { start } = taskForm.getValues();
    //     taskForm.setValue('end', start);
    // }, []);

    const onSubmit = useCallback(
        async (data: TaskFormValues) => {
            try {
                // const toUTCDate = (str: string) => {
                //     const [year, month, day] = str.split('-').map(Number);
                //     return new Date(Date.UTC(year, month - 1, day));
                // };

                // const { start, end } = resolveDateValuesForTask({
                //     start: toUTCDate(data.start),
                //     end: toUTCDate(data.end),
                //     oneTimeDueDate: data.oneTimeDueDate as ONE_TIME_DUE_DATE,
                // });

                // const getDayIndex = (str: WeekdayStr) =>
                //     Weekday.fromStr(str).weekday;

                const taskResult: DeepPartial<TaskInterface> = {
                    // oneTime: data.frequency === ONE_TIME_FREQUENCY_OPTION,
                    shouldBeApprovedBy:
                        data.shouldBeApprovedBy as SHOULD_BE_APPROVED_BY[],
                    name: data.name,
                    notes: data.description || '',
                };

                if (!edit) await createTask(taskResult as TaskInterface);
                if (!!edit) await editTask(taskResult as TaskInterface);

                onAfterSubmit();
            } catch (error) {
                console.error(error);
                onModalClose();
            }
        },
        [edit, taskId]
    );

    const closeModalAndResetForm = () => {
        taskForm.reset(taskFormDefaultValues);
        onModalClose();
    };

    // useEffect(() => {
    //     const subscription = taskForm.watch((_formValues, { name }) => {
    //         switch (name) {
    //             case 'frequency':
    //                 onFrequencyChange();
    //                 break;
    //             case 'oneTimeDueDate':
    //                 onOneTimeDueDateChange();
    //                 break;
    //             default:
    //                 break;
    //         }
    //     });
    //     return () => subscription.unsubscribe();
    // }, [taskForm.watch]);

    return (
        <Modal
            title={edit ? 'Edit Task' : 'Add Task'}
            modalName={modalName}
            options={[
                {
                    text: 'Cancel',
                    type: 'secondary',
                    onClick: closeModalAndResetForm,
                },
                { form: formId, text: 'Submit', disabled: isCreateTaskLoading || isEditTaskLoading },
            ]}
            onClose={closeModalAndResetForm}
        >
            <form
                id={formId}
                className="relative flex flex-col"
                onSubmit={taskForm.handleSubmit(onSubmit, (error) =>
                    console.log(error)
                )}
            >
                {loading && <LocalLoading />}
                <Stack>
                    {[
                        { label: 'Task Name', name: 'name' },
                        {
                            label: 'Task Description',
                            name: 'description',
                            multiline: true,
                        },
                    ].map(({ label, name, multiline }) => (
                        <TextInput
                            form={taskForm}
                            name={name}
                            label={label}
                            key={name}
                            multiline={multiline}
                        />
                    ))}
                    <ControlledCheckboxSelection
                        form={taskForm}
                        label="Who can approve the task? (Select all that apply)"
                        name="shouldBeApprovedBy"
                        options={[
                            SHOULD_BE_APPROVED_BY.FAMILYUNIT_ADMIN,
                            SHOULD_BE_APPROVED_BY.GROUP_ADMIN,
                        ].map((option) => ({
                            label: SHOULD_BE_APPROVED_BY_LABEL[option],
                            value: option,
                            labelPosition: 'right',
                            size: 'lg',
                        }))}
                        multiple={true}
                    />
                </Stack>
            </form>
        </Modal>
    );
};

export default observer(TaskFormModal);
