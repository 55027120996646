import React from 'react';

const ListItem: React.FunctionComponent<{
    children?: React.ReactNode;
    className?: string;
}> = ({ children, className }) => (
    <div
        className={`my-2 w-full overflow-hidden rounded-lg shadow-md hover:shadow-lg ${className}`}
    >
        <div>{children}</div>
    </div>
);

export default ListItem;
