import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { Col, FieldError, Row, TextInput } from '../../components/fields';
import { KKCheckboxV2 } from '../../components/fields/KKCheckbox';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';
import { useRewardsQuery } from '../../hooks/queries/rewards';
import { useTasksQuery } from '../../hooks/queries/tasks';
import { Entity } from '../../models/Entity';

const selectTasksAndRewardsSchema = yup.object({
    tasks: yup
        .array(
            yup.object({
                label: yup.string(),
                value: yup.string(),
                selected: yup.boolean().transform((value) => !!value),
                reps: yup.lazy((value) =>
                    value === ''
                        ? yup.string().transform(() => 0)
                        : yup.number().when('selected', {
                              is: true,
                              then: (schema) =>
                                  schema
                                      .integer('Please enter an integer')
                                      .min(
                                          1,
                                          'Please enter a value greater than 0'
                                      ),
                              otherwise: (schema) => schema,
                          })
                ),
            })
        )
        .test({
            name: 'at-least-one-selected',
            message: 'Please select at least one value',
            test: (value) => {
                if (!value || !Array.isArray(value)) return false;
                return value.some(({ selected }) => !!selected);
            },
        }),
    rewards: yup
        .array(
            yup.object({
                label: yup.string(),
                value: yup.string(),
                selected: yup.boolean(),
            })
        )
        .test({
            name: 'at-least-one-selected',
            message: 'Please select at least one value',
            test: (value) => {
                if (!value || !Array.isArray(value)) return false;
                return value.some(({ selected }) => !!selected);
            },
        }),
});

type SelectRewardsAndTasksSchema = yup.InferType<
    typeof selectTasksAndRewardsSchema
>;

interface SelectTasksAndRewardsProps {
    entity: Entity;
    selectedTasks: { id: string; name: string; reps: number }[];
    selectedRewards: { id: string; name: string }[];
    onNext: (arg: {
        selectedTasks: { id: string; name: string; reps: number }[];
        selectedRewards: { id: string; name: string }[];
    }) => void;
    onPrevious: (arg: {
        selectedTasks: { id: string; name: string; reps: number }[];
        selectedRewards: { id: string; name: string }[];
    }) => void;
    onClose: () => void;
}

const SelectTasksAndRewards: React.FunctionComponent<SelectTasksAndRewardsProps> =
    ({
        entity,
        selectedTasks: initialSelectedTasks,
        selectedRewards: initialSelectedRewards,
        onNext,
        onPrevious,
        onClose,
    }) => {
        const selectTasksAndRewardsForm = useForm<SelectRewardsAndTasksSchema>({
            resolver: yupResolver(selectTasksAndRewardsSchema),
            mode: 'onSubmit',
        });

        const taskFieldArray = useFieldArray({
            control: selectTasksAndRewardsForm.control,
            name: 'tasks',
        });

        const rewardFieldArray = useFieldArray({
            control: selectTasksAndRewardsForm.control,
            name: 'rewards',
        });

        const taskFieldArrayWatch = useWatch({
            control: selectTasksAndRewardsForm.control,
            name: 'tasks',
        });

        useTasksQuery(
            {
                entityId: entity.data._id,
                entityType: entity.type,
            },
            {
                onSuccess: (data) => {
                    if (taskFieldArray.fields.length > 0) return;

                    const initialTaskFieldArrayValuesById =
                        initialSelectedTasks.reduce(
                            (result, { id, name, reps }) => {
                                result[id] = {
                                    selected: true,
                                    label: name,
                                    value: id,
                                    reps,
                                };
                                return result;
                            },
                            {} as {
                                [taskId: string]: {
                                    selected: boolean;
                                    label: string;
                                    value: string;
                                    reps: number;
                                };
                            }
                        );

                    data.forEach((task) => {
                        taskFieldArray.append({
                            label: task.name || task.choreInformation?.name,
                            value: task._id,
                            selected:
                                initialTaskFieldArrayValuesById[task._id]
                                    ?.selected || false,
                            reps:
                                initialTaskFieldArrayValuesById[task._id]
                                    ?.reps || 0,
                        });
                    });

                    selectTasksAndRewardsForm.clearErrors();
                },
            }
        );

        useRewardsQuery(
            {
                entityId: entity.data._id,
                entityType: entity.type,
            },
            {
                onSuccess: (data) => {
                    if (rewardFieldArray.fields.length > 0) return;

                    const initialRewardFieldArrayValuesById =
                        initialSelectedRewards.reduce(
                            (result, { id, name }) => {
                                result[id] = {
                                    selected: true,
                                    label: name,
                                    value: id,
                                };
                                return result;
                            },
                            {} as {
                                [taskId: string]: {
                                    selected: boolean;
                                    label: string;
                                    value: string;
                                };
                            }
                        );

                    data.forEach((reward) => {
                        rewardFieldArray.append({
                            label: reward.name,
                            value: reward._id,
                            selected:
                                initialRewardFieldArrayValuesById[reward._id]
                                    ?.selected || false,
                        });
                    });

                    selectTasksAndRewardsForm.clearErrors();
                },
            }
        );

        const onSubmit = (
            cb:
                | SelectTasksAndRewardsProps['onNext']
                | SelectTasksAndRewardsProps['onPrevious']
        ) =>
            selectTasksAndRewardsForm.handleSubmit(
                ({ tasks, rewards }: Partial<SelectRewardsAndTasksSchema>) => {
                    const selectedTasks = tasks!
                        .filter(({ selected }) => !!selected)
                        .map(({ value, label, reps }) => ({
                            id: value as string,
                            reps: reps as number,
                            name: label as string,
                        }));

                    const selectedRewards = rewards!
                        .filter(({ selected }) => !!selected)
                        .map(({ value, label }) => ({
                            id: value as string,
                            name: label as string,
                        }));

                    cb({
                        selectedTasks,
                        selectedRewards,
                    });
                }
            );

        useEffect(() => {
            const formSubscription = selectTasksAndRewardsForm.watch(
                (formValues, { name }) => {
                    if (!name) return;
                    if (name.match(/tasks\.\d+\.selected/)) {
                        const digit = Number(name.split('.')[1]);
                        const updatedValue =
                            formValues?.tasks?.[digit]?.selected;
                        selectTasksAndRewardsForm.setValue(
                            `tasks.${Number(digit)}.reps`,
                            !updatedValue ? 0 : 1
                        );
                    }
                }
            );
            return formSubscription.unsubscribe;
        }, [selectTasksAndRewardsForm]);

        return (
            <Modal
                modalName={MODALS.CREATE_ASSIGNMENT}
                title="Select Tasks and Rewards"
                options={[
                    {
                        text: 'Previous',
                        type: 'secondary',
                        onClick: () =>
                            onPrevious({
                                selectedTasks: selectTasksAndRewardsForm
                                    .getValues()
                                    .tasks!.filter(({ selected }) => selected)
                                    .map(({ value, label, reps }) => ({
                                        id: value as string,
                                        name: label as string,
                                        reps: reps as number,
                                    })),
                                selectedRewards: selectTasksAndRewardsForm
                                    .getValues()
                                    .rewards!.filter(({ selected }) => selected)
                                    .map(({ value, label }) => ({
                                        id: value as string,
                                        name: label as string,
                                    })),
                            }),
                    },
                    {
                        text: 'Next',
                        type: 'primary',
                        onClick: onSubmit(onNext),
                    },
                ]}
                onClose={onClose}
            >
                <Row>
                    <Col size={6} className="pr-4">
                        <Row>
                            <Col size={10}>
                                <h4>Your Tasks</h4>
                                {selectTasksAndRewardsForm.formState
                                    .isSubmitted && (
                                    <>
                                        <FieldError
                                            errors={
                                                selectTasksAndRewardsForm
                                                    .formState.errors
                                            }
                                            field="tasks"
                                        />
                                        <FieldError
                                            // the function below will return the first error in the field array
                                            errors={
                                                Array.isArray(
                                                    selectTasksAndRewardsForm
                                                        ?.formState?.errors
                                                        ?.tasks
                                                )
                                                    ? selectTasksAndRewardsForm.formState.errors.tasks.reduce(
                                                          (result, error) => {
                                                              if (
                                                                  !result.reps
                                                                      ?.message &&
                                                                  !!error.reps
                                                                      ?.message
                                                              )
                                                                  result =
                                                                      error;
                                                              return result;
                                                          },
                                                          {}
                                                      )
                                                    : {}
                                            }
                                            field="reps"
                                        />
                                    </>
                                )}
                            </Col>
                            <Col size={2}>
                                <div className="flex justify-center">
                                    <h4>Reps</h4>
                                </div>
                            </Col>
                        </Row>
                        <div className="my-2">
                            {taskFieldArray.fields.map((taskField, idx) => (
                                <Row
                                    className="items-center py-1"
                                    key={taskField.id}
                                >
                                    <Col size={10}>
                                        <Controller
                                            name={`tasks.${idx}.selected`}
                                            control={
                                                selectTasksAndRewardsForm.control
                                            }
                                            render={({ field }) => (
                                                <KKCheckboxV2
                                                    name={field.name}
                                                    label={taskField.label}
                                                    value={taskField.value}
                                                    state={
                                                        !!field.value
                                                            ? 'checked'
                                                            : 'unchecked'
                                                    }
                                                    onChange={field.onChange}
                                                    size="lg"
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col size={2}>
                                        <TextInput
                                            form={selectTasksAndRewardsForm}
                                            name={`tasks.${idx}.reps`}
                                            type="number"
                                            size="md"
                                            disabled={
                                                !taskFieldArrayWatch?.[idx]
                                                    .selected
                                            }
                                            hiddenErrorMessage
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </Col>
                    <Col size={6} className="pl-4">
                        <h4>Your Rewards</h4>
                        {selectTasksAndRewardsForm.formState.isSubmitted && (
                            <FieldError
                                errors={
                                    selectTasksAndRewardsForm.formState.errors
                                }
                                field="rewards"
                            />
                        )}
                        <div className="my-2">
                            {rewardFieldArray.fields.map((rewardField, idx) => (
                                <Row className="py-2" key={rewardField.id}>
                                    <Controller
                                        name={`rewards.${idx}.selected`}
                                        control={
                                            selectTasksAndRewardsForm.control
                                        }
                                        render={({ field }) => (
                                            <KKCheckboxV2
                                                name={field.name}
                                                label={rewardField.label}
                                                value={rewardField.value}
                                                state={
                                                    !!field.value
                                                        ? 'checked'
                                                        : 'unchecked'
                                                }
                                                onChange={field.onChange}
                                                size="lg"
                                            />
                                        )}
                                    />
                                </Row>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    };

export default SelectTasksAndRewards;
