import {
    PopulatedTaskCompletionInterface,
    TaskCompletionInterface,
} from '../../models/OrganizationTaskCompletion';
import {
    ENUM_ENTITY_TYPE,
    ORGANIZATION_TASK_COMPLETION_STATUS,
} from '../../constants/common';
import { baseRequest } from '../baseRequest';

export function getOpenTaskCompletionsByAssignmentId({
    assignmentId,
    entityType,
    entityId,
}: {
    assignmentId: string;
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(
            `/taskcompletions/created-by/${entityType}/${entityId}/${assignmentId}`,
            {
                method: 'GET',
            }
        ) as Promise<{ taskCompletions: TaskCompletionInterface[] }>;
}

export function getOpenTaskCompletionsByGroupId({
    entityType,
    entityId,
}: {
    entityType: string;
    entityId: string;
}) {
    return () =>
        baseRequest(
            `/taskcompletions/assigned-to/${entityType}/${entityId}/?status=${ORGANIZATION_TASK_COMPLETION_STATUS.OPEN}`,
            {
                method: 'GET',
            }
        ) as Promise<{
            taskCompletions: PopulatedTaskCompletionInterface[];
        }>;
}

export function processTaskCompletion(
    {
        entityType,
        entityId,
    }: {
        entityType: ENUM_ENTITY_TYPE;
        entityId: string;
    },
    taskCompletionData: {
        taskCompletionId: string;
        approvalStatus: ORGANIZATION_TASK_COMPLETION_STATUS;
        childId: any;
    }
) {
    return () =>
        baseRequest(
            `/taskcompletions/${entityType}/${entityId}/${taskCompletionData.taskCompletionId}/${taskCompletionData.childId}/process/${taskCompletionData.approvalStatus}`,
            {
                method: 'POST',
            }
        ) as Promise<TaskCompletionInterface>;
}
