import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { ENUM_ENTITY_TYPE, MODALS } from '../constants/common';
// import CreateAssignment from '../forms/CreateAssignmentContainer';
import { KKTableV2 } from '../forms/TaskFormModal/KKTable';
import { useAssignmentsCreatedQuery } from '../hooks/queries/assignments';

import useEntityAndParents from '../hooks/useEntityAndParents';
import { GroupInterface } from '../models/Group';
import { OrganizationInterface } from '../models/Organization';
import { TaskInterface } from '../models/OrganizationTask';

import AssignmentModal from '../forms/AssignmentFormModal';
import { useEntityDataAndType } from '../hooks/useEntityDataAndType';
import { AssignmentInterface } from '../models/Assignment';
import appRepository from '../stores/AppDataStore';
import Breadcrumbs from './Breadcrumbs';
import DashboardContentSection from './DashboardContentSection';
import KKButton from './KKButton';
import KKIonIcon from './KKIonIcon';
import Navbar from './Navbar';

const useAssignmentsTable = ({
    entityId,
    entityType,
    onEditAssignmentClick,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    onEditAssignmentClick: (taskId: string) => void;
}) => {
    const assignmentsCreatedQuery = useAssignmentsCreatedQuery({
        entityId,
        entityType,
    });

    const { pathname: currentPathname } = useLocation();

    const columns: Column<AssignmentInterface & { createdAt?: string }>[] =
        useMemo(
            () => [
                {
                    id: 'name',
                    Header: 'Name',
                    Cell: ({
                        row: {
                            original: { name, _id },
                        },
                    }: {
                        row: Row<AssignmentInterface>;
                    }) => (
                        <Link
                            to={`${currentPathname}/${_id}`}
                            className="text-[#007296] underline"
                        >
                            {name}
                        </Link>
                    ),
                },

                {
                    id: 'createdAt',
                    accessor: (assignment) => assignment.createdAt,
                    Header: 'Created',
                    Cell: ({ value }: { value: string }) =>
                        new Date(value).toLocaleDateString(),
                },
                {
                    id: 'options',
                    Header: () => (
                        <div className="flex w-full items-center justify-center">
                            Options
                        </div>
                    ),
                    Cell: ({
                        row: {
                            original: { _id: assignmentId },
                        },
                    }: {
                        row: Row<TaskInterface>;
                    }) => (
                        <div className="flex w-full items-center justify-center">
                            <KKButton
                                type="secondary"
                                onClick={() =>
                                    onEditAssignmentClick(assignmentId)
                                }
                            >
                                <KKIonIcon
                                    name="pencil-outline"
                                    className="text-xl text-kk-dark-blue"
                                />
                            </KKButton>
                        </div>
                    ),
                },
            ],
            []
        );

    const data: AssignmentInterface[] = useMemo(
        () =>
            Array.isArray(assignmentsCreatedQuery.data)
                ? assignmentsCreatedQuery.data
                : [],
        [assignmentsCreatedQuery.data]
    );

    return { columns, data };
};

const EntityAssignments: React.FunctionComponent<{
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}> = ({ entityId, entityType }) => {
    const [selectedAssignment, setSelectedAssignment] = useState<string | null>(
        null
    );

    const { entityAndParents } = useEntityAndParents({
        entityId,
        entityType,
    });

    const { columns, data } = useAssignmentsTable({
        entityId,
        entityType,
        onEditAssignmentClick: (assignmentId) => {
            setSelectedAssignment(assignmentId);
            appRepository.setActiveModal(MODALS.CREATE_ASSIGNMENT);
        },
    });

    const entity = useMemo(() => {
        if (!!entityAndParents) {
            switch (entityType) {
                case ENUM_ENTITY_TYPE.ORGANIZATION:
                    return [...entityAndParents].pop()
                        ?.data as OrganizationInterface;

                case ENUM_ENTITY_TYPE.GROUP:
                    return [...entityAndParents].pop()?.data as GroupInterface;

                default:
                    return null;
            }
        }
        return null;
    }, [entityAndParents]);

    const entityDataAndType = useEntityDataAndType({
        entityType,
        entityData: entity,
    });

    return (
        <Navbar>
            <Breadcrumbs
                entities={entityAndParents}
                suffix={[{ link: '/assignments', name: 'My Assignments' }]}
            />
            <DashboardContentSection>
                {!!entity && <h2>{entity.basicInformation.name}</h2>}
            </DashboardContentSection>
            <DashboardContentSection
                title="Assignments"
                headerButtons={[
                    {
                        label: '+ Add Assignment',
                        type: 'primary',
                        onClick: () =>
                            appRepository.setActiveModal(
                                MODALS.CREATE_ASSIGNMENT
                            ),
                    },
                ]}
            >
                <KKTableV2 columns={columns} data={data} />
            </DashboardContentSection>
            {!!entity && !!entityDataAndType && (
                <AssignmentModal
                    entity={entityDataAndType}
                    assignmentId={selectedAssignment}
                    edit={!!selectedAssignment}
                    onModalClose={() => {
                        setSelectedAssignment(null);
                    }}
                />
            )}
        </Navbar>
    );
};

export default EntityAssignments;
