import React, { FC } from 'react';
import './input.css';

interface InputProps {
    value: string;
    name: string;
    title: string;
    type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
    placeholder?: string;
    handleChange: any;
    handleBlur: any;
    className?: string;
    labelClassName?: string;
    showLabel?: boolean;
    error?: string;
    style?: any;
    autoComplete?: string;
}

export const Input: FC<InputProps> = ({
    value,
    name,
    type = 'text',
    className = '',
    labelClassName = '',
    title,
    showLabel = true,
    placeholder = '',
    handleBlur,
    handleChange,
    error,
    style,
    autoComplete,
}) => (
    <>
        {showLabel ? (
            <label htmlFor={name} className={`form-label ${labelClassName}`}>
                {title}
            </label>
        ) : null}
        <input
            className={`form-input w-full ${className}`}
            id={name}
            name={name}
            type={type}
            onBlur={handleBlur}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            style={style}
            autoComplete={autoComplete}
        />
        {error && <span className="text-error"> {error} </span>}
    </>
);
