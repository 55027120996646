import { DeepPartial } from 'react-hook-form';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { OrganizationInterface } from '../../models/Organization';
import { baseRequest } from '../baseRequest';

export const createOrganization = (data: any) =>
    baseRequest('/organizations/create', {
        method: 'POST',
        body: data,
    }) as Promise<{ organization: OrganizationInterface }>;

export const createSuborganization = ({
    parentOrganizationId,
    data,
}: {
    parentOrganizationId: string;
    data: DeepPartial<OrganizationInterface>;
}) =>
    baseRequest(
        `/organizations/${parentOrganizationId}/suborganizations/create`,
        {
            method: 'POST',
            body: data,
        }
    );

export function getOrganizations(queryParams: { showArchived?: boolean } = {}) {
    return baseRequest('/organizations', {
        method: 'GET',
        params: queryParams,
    }) as Promise<{ organizations: OrganizationInterface[] }>;
}

export function getSubOrgsApi(queryParams: { showArchived?: boolean } = {}) {
    return baseRequest('/organizations/suborganizations', {
        method: 'GET',
        params: queryParams,
    }) as Promise<{ organizations: OrganizationInterface[] }>;
}

export function getOrganizationById(organizationId: string) {
    return () =>
        baseRequest(`/organizations/${organizationId}`, {
            method: 'GET',
        }) as Promise<{ organization: OrganizationInterface }>;
}

export function getSuborganizationsByParentId(
    organizationId: string,
    queryParams: { showArchived?: boolean } = {}
) {
    return () =>
        baseRequest(`/organizations/${organizationId}/suborganizations`, {
            method: 'GET',
            params: queryParams,
        }) as Promise<{ organizations: OrganizationInterface[] }>;
}

export const archiveOrganization = (organizationId: string) =>
    baseRequest(`/organizations/${organizationId}/archive`, {
        method: 'POST',
    }) as Promise<{ organization: OrganizationInterface }>;

export const archiveSuborganization = ({
    parentOrganizationId,
    organizationToUpdateId,
}: {
    parentOrganizationId: string;
    organizationToUpdateId: string;
}) =>
    baseRequest(
        `/organizations/${parentOrganizationId}/suborganizations/${organizationToUpdateId}/archive`,
        { method: 'POST' }
    ) as Promise<{ organization: OrganizationInterface }>;

export function editOrganization(
    organizationId: string,
    data: DeepPartial<OrganizationInterface>
) {
    return () =>
        baseRequest(`/organizations/${organizationId}/edit`, {
            method: 'POST',
            body: data,
        }) as Promise<{ organization: OrganizationInterface }>;
}

export function getEntityAlerts(
    entityType: ENUM_ENTITY_TYPE,
    entityId: string
) {
    return () =>
        baseRequest(`/alerts/${entityType}/${entityId}`, {
            method: 'GET',
        });
}

export function getTotalEnrolledFamilyUnitsCount(orgId?: string) {
    return () =>
        baseRequest(`/organizations/${orgId}/enrolled-family-units`, {
            method: 'GET',
        });
}
