export const env = process.env.REACT_APP_ENV;

function getApiUrlBasedOnEnvOrBrowserLocation() {
    const envApiUrls: { [key: string]: string } = {
        development: 'http://localhost:3070',
        // development: 'https://kk-orgs-backend.loca.lt',
        test: 'https://testorgsapi.go.kiddiekredit.com',
        production: 'https://prodorgsapi.go.kiddiekredit.com',
    };
    if (env && env in envApiUrls) {
        return envApiUrls[env];
    }
    if (window.location.host.includes('localhost')) {
        return envApiUrls.development;
    }
    if (window.location.host.includes('testorgs')) {
        return envApiUrls.test;
    }
    return envApiUrls.production;
}

function getKKConsumerBackendUrl() {
    const urls: { [key: string]: string } = {
        development: 'http://localhost:3080',
        // development: 'https://kkbackend.loca.lt',
        test: 'https://testapi.go.kiddiekredit.com',
        production: 'https://productionapi.go.kiddiekredit.com',
    };
    if (env && env in urls) {
        return urls[env];
    }
    if (window.location.host.includes('localhost')) {
        return urls.development;
    }
    if (window.location.host.includes('testorgs')) {
        return urls.test;
    }
    return urls.production;
}

export const REACT_APP_AUTH0_DOMAIN = 'kiddiekredit.auth0.com';
export const REACT_APP_AUTH0_CLIENT_ID = 'LVfVnJg9zngBlm5fIPkAPsZO5wyveh1z';
export const REACT_APP_AUTH0_AUDIENCE = 'https://api.kiddiekredit.com';
export const KK_API_URL = getApiUrlBasedOnEnvOrBrowserLocation();
export const KK_CONSUMER_BACKEND_API_URL = getKKConsumerBackendUrl();
