import { useQuery, UseQueryOptions } from 'react-query';
import { FamilyUnitChildInfoInterface } from '../../models/FamilyUnit';
import { GroupInterface } from '../../models/Group';
import {
    EnrollableChildrenForGroupQuery,
    getAllGroups,
    getGroupById,
    getGroupEnrollableChildren,
    getGroupEnrolledChildren,
    getGroupsByParentOrganizationId,
} from '../../services/api/groupApi';

import groupRepository from '../../stores/GroupDataStore';
import userRepository from '../../stores/UserDataStore';

export enum GROUPS_QUERY_TYPE {
    ALL_GROUPS = 'ALL_GROUPS',
    BY_PARENT_ID = 'BY_PARENT_ID',
}

export type GroupsQueryParams = { params?: { showArchived?: boolean } };

export type GroupsQuery = (
    | { type: GROUPS_QUERY_TYPE.ALL_GROUPS }
    | {
          type: GROUPS_QUERY_TYPE.BY_PARENT_ID;
          parentOrganizationId: string;
      }
) &
    GroupsQueryParams;

export type GroupsQueryOptions<TData = GroupInterface[]> = Omit<
    UseQueryOptions<GroupInterface[], Error, TData>,
    'queryKey' | 'queryFn'
>;

export const GROUPS_QUERY_KEY = 'groups';

export const getGroupsQueryOptions = <TData = GroupInterface[]>(
    query: GroupsQuery,
    options: GroupsQueryOptions<TData> = {}
): UseQueryOptions<GroupInterface[], Error, TData> => ({
    queryKey: [GROUPS_QUERY_KEY, query],
    queryFn: async () => {
        switch (query.type) {
            case GROUPS_QUERY_TYPE.ALL_GROUPS: {
                const { groups } = await getAllGroups(query.params);
                return groups;
            }
            case GROUPS_QUERY_TYPE.BY_PARENT_ID: {
                const { groups } = await getGroupsByParentOrganizationId(
                    query.parentOrganizationId,
                    query.params
                );

                return groups;
            }
            default:
                throw new Error('Invalid query type');
        }
    },
    ...options,
});

export const useGroupsQuery = <TData = GroupInterface[]>(
    query: GroupsQuery,
    options: GroupsQueryOptions<TData> = {}
) => useQuery({ ...getGroupsQueryOptions(query, options) });

export const GROUP_ENROLLED_CHILDREN_QUERY_KEY = 'groups_enrolled_children';

export const getGroupEnrolledChildrenQueryOptions = <
    TData = FamilyUnitChildInfoInterface[]
>(
    groupId: string,
    options: Omit<
        UseQueryOptions<FamilyUnitChildInfoInterface[], Error, TData>,
        'queryFn' | 'queryKey'
    > = {}
): UseQueryOptions<FamilyUnitChildInfoInterface[], Error, TData> => ({
    queryKey: [GROUP_ENROLLED_CHILDREN_QUERY_KEY, { groupId }],
    queryFn: async () => {
        const { children } = await getGroupEnrolledChildren(groupId);
        return children;
    },
    ...options,
});

export const useGroupEnrolledChildrenQuery = <
    TData = FamilyUnitChildInfoInterface[]
>(
    groupId: string,
    options: Omit<
        UseQueryOptions<FamilyUnitChildInfoInterface[], Error, TData>,
        'queryFn' | 'queryKey'
    > = {}
) => useQuery({ ...getGroupEnrolledChildrenQueryOptions(groupId, options) });

export const GROUP_BY_ID_QUERY_KEY = 'group_by_id';

export const getGroupByIdQueryOptions = <TData>(
    groupId?: string,
    options: Omit<
        UseQueryOptions<GroupInterface, Error, TData>,
        'queryKey' | 'queryFn'
    > = {}
): UseQueryOptions<GroupInterface, Error, TData> => ({
    ...options,
    queryKey: [GROUP_BY_ID_QUERY_KEY, { groupId }],
    queryFn: async () => {
        if (!groupId) throw new Error('Group Id not provided');
        const { group } = await getGroupById(groupId)();

        groupRepository.setGroupData(group);
        const basicInfo = {
            entityId: group._id,
            entityType: 'GROUP',
            entityName: group.basicInformation.groupName,
            entityLogo: group.basicInformation?.logo,
            firstName: group.basicInformation.adminFirstName,
            lastName: group.basicInformation.adminLastName,
            email: group.ownerEmail,
            parentEntity: group.parentOrgId,
        };

        if (userRepository.role == 'GROUP ADMIN')
            userRepository.setBasicInfo(basicInfo);

        return group;
    },
});

export const useGroupByIdQuery = <TData = GroupInterface>(
    groupId?: string,
    options: Omit<
        UseQueryOptions<GroupInterface, Error, TData>,
        'queryKey' | 'queryFn'
    > = {}
) => useQuery({ ...getGroupByIdQueryOptions(groupId, options) });

export const ENROLLABLE_CHILDREN_FOR_GROUP_QUERY_KEY =
    'enrollable_children_for_group';

export const getEnrollableChildrenForGroupQueryOptions = <
    TData = FamilyUnitChildInfoInterface
>(
    query: EnrollableChildrenForGroupQuery,
    options: Omit<
        UseQueryOptions<FamilyUnitChildInfoInterface, Error, TData>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<FamilyUnitChildInfoInterface, Error, TData> => ({
    queryKey: [
        ENROLLABLE_CHILDREN_FOR_GROUP_QUERY_KEY,
        { groupId: query.groupId },
    ],
    queryFn: async () => {
        if (!query.groupId) throw new Error('Group Id not provided');
        const { children } = await getGroupEnrollableChildren(query);
        return children;
    },
    ...options,
});

export const useEnrollableChildrenForGroupQuery = <
    TData = FamilyUnitChildInfoInterface
>(
    query: EnrollableChildrenForGroupQuery,
    options: Omit<
        UseQueryOptions<FamilyUnitChildInfoInterface, Error, TData>,
        'queryKey' | 'queryFn'
    > = {}
) =>
    useQuery({
        ...getEnrollableChildrenForGroupQueryOptions(query, options),
    });
