import imageCompression from 'browser-image-compression';

async function compressImage(file: any) {
    const imageFile = file;

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    try {
        const compressedFile = await imageCompression(imageFile, options);

        return compressedFile;
    } catch (error) {
        console.log(error);
    }
}

export default compressImage;
