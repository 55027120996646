import React, { useMemo } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { Column, Row } from 'react-table';
import {
    ENUM_ENTITY_TYPE,
    ORGANIZATION_TASK_COMPLETION_STATUS,
} from '../constants/common';
import { SHOULD_BE_APPROVED_BY } from '../constants/orgTasks';
import { useProcessTaskCompletionMutation } from '../hooks/mutations/taskCompletions';
import { useTasksCompletionsByGroupIdQuery } from '../hooks/queries/taskCompletions';
import {
    PopulatedTaskCompletionInterface,
    TaskCompletionInterface,
} from '../models/OrganizationTaskCompletion';
import KKButton from './KKButton';
import KKTable from './KKTable';
import DashboardCard from './tiles/DashboardCard';

const useApprovalRequestsTable = ({
    groupId,
    processTaskCompletion,
}: {
    groupId: string;
    processTaskCompletion: UseMutateAsyncFunction<
        TaskCompletionInterface,
        unknown,
        {
            taskCompletionId: string;
            approvalStatus: ORGANIZATION_TASK_COMPLETION_STATUS;
            childId: any;
        },
        unknown
    >;
}) => {
    const taskCompletionsTable = useTasksCompletionsByGroupIdQuery({ groupId });

    const columns: Column<PopulatedTaskCompletionInterface>[] = useMemo(
        () => [
            {
                id: 'name',
                accessor: (taskCompletion) => taskCompletion.child.name,
                Header: 'Child',
            },
            {
                id: 'taskName',
                accessor: (taskCompletion) => taskCompletion.task.name,
                Header: 'Task',
            },

            {
                id: 'approveOrDeny',
                Header: 'Approval',

                Cell: ({
                    row: {
                        original: { _id: taskCompletionId, task },
                        cells,
                    },
                }: {
                    row: Row<TaskCompletionInterface>;
                }) => {
                    const canTaskBeApprovedByGroupAdmin =
                        task.shouldBeApprovedBy.includes(
                            SHOULD_BE_APPROVED_BY.GROUP_ADMIN
                        );

                    if (!!canTaskBeApprovedByGroupAdmin) {
                        return (
                            <div className="flex w-full items-center  gap-2">
                                <KKButton
                                    type="primary"
                                    onClick={(row) =>
                                        processTaskCompletion({
                                            taskCompletionId,
                                            approvalStatus:
                                                ORGANIZATION_TASK_COMPLETION_STATUS.APPROVED,
                                            childId: cells[2].value,
                                        })
                                    }
                                >
                                    Approve
                                </KKButton>
                                <KKButton
                                    type="danger"
                                    onClick={() =>
                                        processTaskCompletion({
                                            taskCompletionId,
                                            approvalStatus:
                                                ORGANIZATION_TASK_COMPLETION_STATUS.DENIED,
                                            childId: cells[2].value,
                                        })
                                    }
                                >
                                    Deny
                                </KKButton>
                            </div>
                        );
                    }
                    return (
                        <div className="flex w-full items-center gap-2  text-kk-dark-green">
                            Parental-Approval
                        </div>
                    );
                },
            },
        ],
        []
    );

    const data = useMemo(
        () =>
            Array.isArray(taskCompletionsTable.data)
                ? taskCompletionsTable.data
                : [],
        [taskCompletionsTable.data]
    );

    return { columns, data };
};

const ApprovalRequests: React.FunctionComponent<{ groupId: string }> = ({
    groupId,
}) => {
    const { mutateAsync: processTaskCompletion } =
        useProcessTaskCompletionMutation({
            entityId: groupId,
            entityType: ENUM_ENTITY_TYPE.GROUP,
        });

    const { columns, data } = useApprovalRequestsTable({
        groupId,
        processTaskCompletion,
    });

    const isApprovalRequestsListEmpty = data.length < 1;

    return (
        <DashboardCard
            title="Awaiting Approval"
            headerButtons={[
                {
                    label: 'Approve All',
                    type: 'primary',
                    disabled: isApprovalRequestsListEmpty,
                    onClick: () => {
                        data.forEach((approvalRequest) => {
                            const canTaskBeApprovedByGroupAdmin =
                                approvalRequest.task.shouldBeApprovedBy.includes(
                                    SHOULD_BE_APPROVED_BY.GROUP_ADMIN
                                );
                            if (canTaskBeApprovedByGroupAdmin) {
                                processTaskCompletion({
                                    taskCompletionId: approvalRequest._id,
                                    approvalStatus:
                                        ORGANIZATION_TASK_COMPLETION_STATUS.APPROVED,
                                    childId: approvalRequest.child._id,
                                });
                            }
                        });
                    },
                },
            ]}
            contentContainerClassName="py-3 overflow-auto h-60"
        >
            {!isApprovalRequestsListEmpty ? (
                <KKTable columns={columns} data={data} />
            ) : (
                <p className=" text-md text-kk-dark-green">
                    You don't have any pending approval request
                </p>
            )}
        </DashboardCard>
    );
};

export default ApprovalRequests;
