import { useEffect, useMemo, useState } from 'react';
import { useQueries, UseQueryOptions } from 'react-query';
import { CheckboxNode } from '../components/CheckboxTree';
import KKIonIcon from '../components/KKIonIcon';
import { ENUM_ENTITY_TYPE } from '../constants/common';
import {
    AnyEntity,
    Entity,
    FamilyUnitChildEntityInterface,
    GroupEntityInterface,
    OrganizationEntityInterface,
} from '../models/Entity';
import { FamilyUnitChildInfoInterface } from '../models/FamilyUnit';
import { GroupInterface } from '../models/Group';
import { OrganizationInterface } from '../models/Organization';
import {
    getGroupEnrolledChildrenQueryOptions,
    getGroupsQueryOptions,
    GROUPS_QUERY_TYPE,
} from './queries/groups';
import {
    getOrganizationsQueryOptions,
    ORGANIZATIONS_QUERY_TYPE,
} from './queries/organizations';

const getCheckboxNodeInfo = (entity: AnyEntity, idx: number) => {
    switch (entity.type) {
        case ENUM_ENTITY_TYPE.ORGANIZATION:
            return {
                nodeId: entity.data._id,
                parentNodeId:
                    idx === 0 ? null : entity.data.parentOrgId?.toString(),
                value: entity.data._id,
                label:
                    idx === 0
                        ? 'Select All'
                        : entity.data.basicInformation.orgName,
                renderIcon: () => (
                    <KKIonIcon
                        name="storefront-outline"
                        className="hydrated mr-2 text-xl text-black"
                    />
                ),
                type: entity.type,
            };
        case ENUM_ENTITY_TYPE.GROUP:
            return {
                nodeId: entity.data._id,
                parentNodeId:
                    idx === 0 ? null : entity.data.parentOrgId?.toString(),
                value: entity.data._id,
                label:
                    idx === 0
                        ? 'Select All'
                        : entity.data.basicInformation.groupName,
                renderIcon: () => (
                    <KKIonIcon
                        name="people-outline"
                        className="hydrated mr-2 text-xl text-black"
                    />
                ),
                type: entity.type,
            };
        case ENUM_ENTITY_TYPE.FAMILY_UNIT_CHILD:
            return {
                nodeId: entity.data._id,
                parentNodeId: idx === 0 ? null : entity.data.parentGroupId,
                value: entity.data._id,
                label: idx === 0 ? 'Select All' : entity.data.name,
                type: entity.type,
            };
        default:
            throw new Error('Invalid entity type');
    }
};

const useNestedEntityTree = ({ rootEntity }: { rootEntity: Entity }) => {
    const [entitiesArray, setEntitiesArray] = useState<AnyEntity[]>([]);
    const {
        organizationsQueriesArray,
        groupsQueriesArray,
        childrenQueriesArray,
    } = useMemo(() => {
        const organizationsQueriesArrayResult: UseQueryOptions<
            OrganizationInterface[],
            Error,
            OrganizationEntityInterface[]
        >[] = [];

        const groupsQueriesArrayResult: UseQueryOptions<
            GroupInterface[],
            Error,
            GroupEntityInterface[]
        >[] = [];

        const childrenQueriesArrayResult: UseQueryOptions<
            FamilyUnitChildInfoInterface[],
            Error,
            FamilyUnitChildEntityInterface[]
        >[] = [];

        const includedEntities = entitiesArray.reduce((result, { data }) => {
            result[data?._id] = true;
            return result;
        }, {} as { [entityId: string]: boolean });

        const addChildEntities = (data: AnyEntity[]) => {
            if (data?.length === 0) return;

            const entitiesToAdd = data.filter(
                ({ data: { _id } }) => !includedEntities[_id]
            );

            if (entitiesToAdd.length === 0) return;

            setEntitiesArray([...entitiesArray, ...entitiesToAdd]);
        };

        entitiesArray.forEach((entity) => {
            switch (entity.type) {
                case ENUM_ENTITY_TYPE.ORGANIZATION:
                    organizationsQueriesArrayResult.push({
                        ...getOrganizationsQueryOptions({
                            parentOrganizationId: entity.data._id,
                            type: ORGANIZATIONS_QUERY_TYPE.BY_PARENT_ID,
                        }),
                        select: (data: OrganizationInterface[]) =>
                            data.map((organizationData) => ({
                                type: ENUM_ENTITY_TYPE.ORGANIZATION,
                                data: organizationData,
                            })),
                        onSuccess: addChildEntities,
                    });
                    groupsQueriesArrayResult.push({
                        ...getGroupsQueryOptions({
                            parentOrganizationId: entity.data._id,
                            type: GROUPS_QUERY_TYPE.BY_PARENT_ID,
                        }),
                        select: (data: GroupInterface[]) =>
                            data.map((groupData) => ({
                                type: ENUM_ENTITY_TYPE.GROUP,
                                data: groupData,
                            })),
                        onSuccess: addChildEntities,
                    });
                    break;
                case ENUM_ENTITY_TYPE.GROUP:
                    if (rootEntity.type !== ENUM_ENTITY_TYPE.GROUP) break;
                    childrenQueriesArrayResult.push({
                        ...getGroupEnrolledChildrenQueryOptions(
                            entity.data._id
                        ),
                        select: (data: FamilyUnitChildInfoInterface[]) =>
                            data.map((childData) => ({
                                type: ENUM_ENTITY_TYPE.FAMILY_UNIT_CHILD,
                                data: {
                                    ...childData,
                                    parentGroupId: entity.data._id,
                                },
                            })),
                        onSuccess: addChildEntities,
                    });
                    break;
                default:
                    break;
            }
        });

        return {
            organizationsQueriesArray: organizationsQueriesArrayResult,
            groupsQueriesArray: groupsQueriesArrayResult,
            childrenQueriesArray: childrenQueriesArrayResult,
        };
    }, [entitiesArray]);

    const organizationsQueriesResults = useQueries(organizationsQueriesArray);

    const groupsQueriesResults = useQueries(groupsQueriesArray);
    const childrenQueriesResults = useQueries(childrenQueriesArray);

    const entitiesById = useMemo(
        () =>
            entitiesArray.reduce((result, entity) => {
                result[entity.data._id] = entity;
                return result;
            }, {} as { [entityId: string]: AnyEntity }),
        [entitiesArray]
    );

    const checkboxNodes = useMemo(
        () =>
            [
                rootEntity,
                ...childrenQueriesResults.map(({ data }) => data),
                ...groupsQueriesResults.map(({ data }) => data),
                ...organizationsQueriesResults.map(({ data }) => data),
            ]
                .flat()
                .filter((entity): entity is Entity => !!entity)
                .map((entity) => ({ ...entity }))
                .map<Omit<CheckboxNode, 'state'>>(getCheckboxNodeInfo),
        [organizationsQueriesArray, groupsQueriesArray]
    );

    useEffect(() => {
        const storedRootEntity = entitiesArray[0];
        const shouldUpdate =
            !storedRootEntity ||
            storedRootEntity.data._id !== rootEntity.data._id;
        if (!shouldUpdate) return;
        setEntitiesArray([rootEntity]);
    }, [rootEntity]);

    return { checkboxNodes, entitiesById };
};

export default useNestedEntityTree;
