import React, { useEffect, useState } from 'react';
import CheckboxTree from '../../components/CheckboxTree';
import { FieldError, Stack } from '../../components/fields';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';
import useNestedEntityTree from '../../hooks/useNestedEntityTree';
import { AnyEntity, Entity } from '../../models/Entity';

const SelectEntities: React.FunctionComponent<{
    entity: Entity;
    selectedEntities: AnyEntity[];
    onNext: (arg: { selectedEntities: AnyEntity[] }) => void;
    onClose: () => void;
}> = ({
    entity,
    selectedEntities: initialSelectedEntities,
    onNext,
    onClose,
}) => {
    const [errors, setErrors] = useState<any>({});
    const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
    const { checkboxNodes, entitiesById } = useNestedEntityTree({
        rootEntity: entity,
    });

    const onSubmit = () => {
        if (selectedEntities.length === 0) {
            setErrors({
                selectedEntities: {
                    message: 'Please select at least one entity',
                },
            });
        } else {
            setErrors({});
            onNext({
                selectedEntities: selectedEntities.map(
                    (entityId) => entitiesById[entityId]
                ),
            });
        }
    };

    useEffect(() => {
        setSelectedEntities(
            initialSelectedEntities.length > 0
                ? initialSelectedEntities.map(({ data: { _id } }) => _id)
                : checkboxNodes.map(({ nodeId }) => nodeId.toString())
        );
    }, [checkboxNodes, initialSelectedEntities]);

    return (
        <Modal
            modalName={MODALS.CREATE_ALERT_FOR_FAMILIES}
            title="Select Entities to Alert"
            options={[
                { text: 'Cancel', type: 'secondary', onClick: onClose },
                { text: 'Next', type: 'primary', onClick: onSubmit },
            ]}
            onClose={onClose}
        >
            <Stack spacing="sm">
                <div>
                    <h4>Please select all relevant groups:</h4>
                    <FieldError errors={errors} field="selectedEntities" />
                </div>
                <CheckboxTree
                    nodes={checkboxNodes}
                    selectedNodes={selectedEntities}
                    onChange={setSelectedEntities}
                />
            </Stack>
        </Modal>
    );
};

export default SelectEntities;
