/* eslint-disable lines-between-class-members */
import { makeAutoObservable, observable, action } from 'mobx';
import { MODALS } from '../constants/common';

class AppDataStore {
    activeModal: MODALS | null = null;
    constructor() {
        makeAutoObservable(this, {
            activeModal: observable,
            setActiveModal: action,
        });
    }

    setActiveModal = (modalName: MODALS | null) => {
        this.activeModal = modalName;
    };
}

const appRepository = new AppDataStore();

export default appRepository;
