import { useCallback, useState } from 'react';
import { OrganizationInterface } from '../models/Organization';
import {
    OrganizationsQuery,
    OrganizationsQueryOptions,
    useOrganizationsQuery,
} from './queries/organizations';

const useOrganizationsList = <TData = OrganizationInterface[]>(
    query: OrganizationsQuery,
    options: OrganizationsQueryOptions<TData> = { keepPreviousData: true }
) => {
    const [showArchived, setShowArchived] = useState(false);

    const organizationsQueryResult = useOrganizationsQuery(
        {
            ...query,
            params: { showArchived },
        },
        options
    );

    const toggleShowArchived = useCallback(
        () => setShowArchived(!showArchived),
        [showArchived]
    );

    return {
        ...organizationsQueryResult,
        showArchived,
        toggleShowArchived,
    };
};

export default useOrganizationsList;
