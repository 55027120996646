import { observer } from 'mobx-react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Input } from '../../components/fields/Input';
import KKButton from '../../components/KKButton';
import Loading from '../../components/loading';
import { useForm } from '../../hooks/useForm';
import kkLogo from '../../images/logos/kk-logo-word-gray.png';
import '../../pages/RegistrationPage.css';
import { loginWithAuth0 ,getUserRole} from '../../services/authorization';
import userRepository from '../../stores/UserDataStore';
import * as LocalStorage from '../../utils/localStorage';


const LogIn = observer(() => {
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const initialValues = {
        email: '',
        password: '',
    };
    const validations = {
        email: {
            required: {
                value: true,
                message: 'Please enter your email',
            },
        },
        password: {
            required: {
                value: true,
                message: 'Please enter your password',
            },
        },
    };
    const handleFormSubmit = async ({ values }) => {
        try {
            setSubmitting(true);

            const { signInToken, user } = await loginWithAuth0(
                values.email,
                values.password
            );
         
            
            LocalStorage.setIdToken(signInToken.id_token);
            LocalStorage.setAccessToken(signInToken.access_token);
           
            await getUserRole(signInToken,user);
         
            userRepository.setUserData(user);

            
       
            setSubmitting(false);
            history.push(user.defaultDashboardUrl);
            return signInToken;
        } catch (err) {
            console.log("Err",err)
            const errMsg = err?.statusText?.includes('Unauthorized')
                ? 'Wrong email or password'
                : 'Something went wrong';
            setErrorMessage(errMsg);
            setSubmitting(false);
            return false;
        }
    };
    const { values, errors, handleChange, handleBlur, handleSubmit } = useForm({
        initialValues,
        onSubmit: handleFormSubmit,
        validations,
    });

   {submitting && <Loading/>}
    return (
        <div className="registration-modal">
            <div className="registration-modal-content">
                <div className="registration-modal-header">
                    <img src={kkLogo} alt="Kiddie Kredit Logo" />
                </div>
                <div className="registration-modal-title">Welcome Back!</div>
                <form
                    id={'signInForm'}
                    className="form-modal-form"
                    onSubmit={handleSubmit}
                >
                    <Input
                        name="email"
                        type="text"
                        handleBlur={handleBlur}
                        title="Email"
                        handleChange={handleChange}
                        error={errors.email}
                        value={values.email}
                        placeholder="Email"
                    />
                    <div className="form-password-row">
                        <label className="form-label" htmlFor="password">
                            Password
                        </label>
                        <Link
                            to="/nonauth/forgot-password"
                            className="form-label forgot-password"
                        >
                            Forgot password?
                        </Link>
                    </div>
                    <Input
                        showLabel={false}
                        name="password"
                        type="password"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        error={errors.password}
                        value={values.password}
                        placeholder="Password"
                    />
                </form>
                {Object.keys(errors).length === 0 && !!errorMessage && (
                    <span className="text-error">{errorMessage}</span>
                )}
                <div className="registration-modal-button">
                    <KKButton
                        disabled={submitting}
                        onClick={handleSubmit}
                        type="primary"
                    >
                        Sign In
                    </KKButton>
                </div>
                <div>
                    <span style={{ fontSize: 'var(--sm-font)' }}>
                        Don't have an account yet?{' '}
                    </span>
                    <Link
                        to="/nonauth/register"
                        className="login-register-link"
                    >
                        Register
                    </Link>
                </div>
                <div className="registration-modal-spacer"></div>
                <div className="registration-modal-spacer"></div>
                <div className="registration-modal-footer">
                    <p>
                        By signing up, you agree to our{' '}
                        <span>
                            <a href="#">Terms, Data Policy</a>
                        </span>{' '}
                        and{' '}
                        <span>
                            <a href="#">Cookies Policy</a>
                        </span>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
});

export default LogIn;
