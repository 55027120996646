import { useQuery } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { getAlertsCreatedByEntity } from '../../services/api/alertsApi';

export const ALERTS_QUERY_KEY = 'alerts';

export const useAlertsCreatedQuery = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) =>
    useQuery([ALERTS_QUERY_KEY, { entityId, entityType }], async () => {
        const { alerts } = await getAlertsCreatedByEntity({
            entityId,
            entityType,
        })();

        return alerts;
    });
