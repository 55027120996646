import { ENUM_ENTITY_TYPE } from '../../constants/common';
import {
    AssignmentInterface,
    PopulatedAssignmentInterface,
} from '../../models/Assignment';
import { baseRequest } from '../baseRequest';

export function getAssignmentsCreatedByEntity({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(`/assignments/created-by/${entityType}/${entityId}`, {
            method: 'GET',
        }) as Promise<{
            assignments: AssignmentInterface[];
        }>;
}

export function createAssignment({
    entityType,
    entityId,
    assignment,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
    assignment: Omit<AssignmentInterface, 'createdBy'>;
}) {
    return baseRequest(
        `/assignments/created-by/${entityType}/${entityId}/create`,
        {
            method: 'POST',
            body: { ...assignment },
        }
    ) as Promise<{
        assignment: AssignmentInterface;
    }>;
}

export function editAssignment({
    entityType,
    entityId,
    assignment,
    assignmentId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
    assignment: Omit<AssignmentInterface, 'createdBy'>;
    assignmentId: string;
}) {
    return baseRequest(
        `/assignments/created-by/${entityType}/${entityId}/edit/${assignmentId}`,
        {
            method: 'POST',
            body: { ...assignment },
        }
    ) as Promise<{
        assignment: AssignmentInterface;
    }>;
}

export function getAssignmentsAssignedTo({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(`/assignments/assigned-to/${entityType}/${entityId}`, {
            method: 'GET',
        }) as Promise<{
            assignments: AssignmentInterface[];
        }>;
}

export function getAssignmentById({
    entityType,
    entityId,
    assignmentId,
}: {
    assignmentId: string;
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(
            `/assignments/created-by/${entityType}/${entityId}/${assignmentId}`,
            {
                method: 'GET',
            }
        ) as Promise<{
            assignment: PopulatedAssignmentInterface;
        }>;
}
