import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import './KKButton.css';

export interface KKButtonProps {
    onClick?: (...args: any) => any | Promise<any>;
    form?: string;
    clickType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    className?: string;
    type?: 'primary' | 'secondary' | 'text' | 'danger';
    style?: any;
    to?: string;
    loading?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
}

const KKButton: React.FunctionComponent<
    React.PropsWithChildren<KKButtonProps>
> = ({
    onClick = () => {},
    form = undefined,
    clickType = 'button',
    type = '',
    style = {},
    to = '',
    children,
    loading = false,
    disabled = false,
    fullWidth = false,
    className,
}) => (
    <>
        {!to && (
            <button
                onClick={onClick}
                form={form}
                type={!!form ? 'submit' : 'button'}
                className={`flex items-center justify-center rounded-md font-nunito ${classNames(
                    {
                        'border-2 border-solid px-2 py-1 text-sm font-extrabold hover:shadow-md':
                            ['primary', 'secondary', 'danger'].includes(type),
                        'border-kk-dark-blue': [
                            'primary',
                            'secondary',
                        ].includes(type),
                        'bg-kk-dark-blue text-white': type === 'primary',
                        'bg-transparent text-kk-dark-blue':
                            type === 'secondary',
                        'border-kk-status-red bg-kk-status-red text-white':
                            type === 'danger',
                        'text-xs text-kk-green hover:text-kk-dark-green':
                            type === 'text',
                        'cursor-wait': loading,
                        'cursor-not-allowed': disabled,
                        'w-full': fullWidth,
                    }
                )} ${className}`}
                style={style}
                disabled={disabled || loading}
            >
                {children}
            </button>
        )}
        {to && (
            <div className="button-container">
                <Link to={to}>
                    <button
                        className={type}
                        type={clickType}
                        style={style}
                        disabled={disabled}
                    >
                        {children}
                    </button>
                </Link>
            </div>
        )}
    </>
);

export default KKButton;
