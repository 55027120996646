import React from 'react';
import './DashboardContent.css';
import KKButton, { KKButtonProps } from './KKButton';
import Loading from './loading';

interface DashboardContentSectionProps {
    title?: string;
    headerButtons?: {
        label: string;
        type: KKButtonProps['type'];
        onClick: KKButtonProps['onClick'];
    }[];
    children?: React.ReactNode;
    loading?: boolean;
}

const DashboardContentSection: React.FunctionComponent<DashboardContentSectionProps> =
    ({ title = '', headerButtons = [], loading = false, children }) => (
        <div className="flex w-full flex-col px-6 py-3">
            {!!title && (
                <div className="flex justify-between">
                    <h3>{title}</h3>
                    <div className="flex gap-2">
                        {!!headerButtons &&
                            headerButtons.map((button) => (
                                <KKButton
                                    key={button.label}
                                    onClick={button.onClick}
                                    type={button.type}
                                >
                                    {button.label}
                                </KKButton>
                            ))}
                    </div>
                </div>
            )}
            {!!title && (
                <div className="my-4 flex w-full flex-grow items-center border-t-2 border-solid border-t-kk-light-grey" />
            )}
            <div className="relative">
                {loading && (
                    <div className="absolute inset-0 flex h-full w-full bg-white/75">
                        <div className="relative h-full max-h-80 w-full">
                            <Loading hiddenText spinnerSize="lg" />
                        </div>
                    </div>
                )}
                {children}
            </div>
        </div>
    );

export default DashboardContentSection;
