import React from 'react';
import historyIcon from '../images/history.svg';
import QuickActionsTile, {
    QuickActionLgTile,
    QuickActionMdTile,
} from './QuickActionsTile';

type QuickActionMdTileFactory = (onClick: () => void) => QuickActionMdTile;

type QuickActionLgTileFactory = (
    onClick: () => void,
    secondaryOnClick: () => void
) => QuickActionLgTile;

export const quickActionMdTileFactory = {
    inviteNewFamilies: ((onClick: (...args: any) => any) => ({
        size: 'md',
        title: 'Invite New Families',
        iconColor: 'kk-purple',
        ionIcon: 'home',
        backgroundColor: 'kk-background-blue',
        onClick,
    })) as QuickActionMdTileFactory,
    addChildren: ((onClick: (...args: any) => any) => ({
        size: 'md',
        title: 'Add Children',
        iconColor: 'kk-green',
        ionIcon: 'accessibility',
        backgroundColor: 'kk-background-blue',
        onClick,
    })) as QuickActionMdTileFactory,
    alertFamilies: ((onClick: (...args: any) => any) => ({
        size: 'md',
        title: 'Alert Families',
        iconColor: 'kk-coral',
        ionIcon: 'alert-circle',
        backgroundColor: 'kk-background-blue',
        onClick,
    })) as QuickActionMdTileFactory,
    myTasks: ((onClick: (...args: any) => any) => ({
        size: 'md',
        title: 'My Tasks',
        iconColor: 'kk-lime',
        ionIcon: 'clipboard',
        backgroundColor: 'kk-background-blue',
        onClick,
    })) as QuickActionMdTileFactory,
    myRewards: ((onClick: (...args: any) => any) => ({
        size: 'md',
        title: 'My Rewards',
        iconColor: 'kk-yellow',
        ionIcon: 'trophy',
        backgroundColor: 'kk-background-blue',
        onClick,
    })) as QuickActionMdTileFactory,
};

export const quickActionLgTileFactory = {
    createAssignment: ((onClick, secondaryOnClick) => ({
        size: 'lg',
        title: 'Create Assignment',
        titleColor: 'white',
        ionIcon: 'add-circle',
        iconColor: 'white',
        onClick,
        secondaryTitle: 'My Assignments',
        secondaryIonIconSrc: historyIcon,
        secondaryOnClick,
        backgroundColor: 'kk-green',
        borderColor: 'transparent',
    })) as QuickActionLgTileFactory,
};

interface QuickActionsProps {
    tiles?: Array<QuickActionMdTile>;
    middleTile?: QuickActionLgTile;
}

const QuickActions: React.FunctionComponent<QuickActionsProps> = ({
    tiles = [],
    middleTile,
}) => (
    <div className="flex w-full items-center justify-center">
        <div className="h-100 grid grid-cols-[1fr_1fr_auto_1fr_1fr]">
            <div className="col-span-5 row-start-2 flex max-w-md flex-wrap items-center justify-center xl:col-span-2 xl:col-start-1 xl:row-start-1 xl:justify-end">
                {tiles.slice(0, Math.floor(tiles.length / 2)).map((tile) => (
                    <QuickActionsTile key={tile.title} {...tile} />
                ))}
            </div>
            <div className="col-span-5 flex items-center justify-center xl:col-span-1 xl:col-start-3">
                {middleTile && <QuickActionsTile {...middleTile} />}
            </div>
            <div className="col-span-5 row-start-3 flex max-w-md flex-wrap items-center justify-center xl:col-span-2 xl:col-start-4 xl:row-start-1 xl:justify-start">
                {tiles
                    .slice(Math.floor(tiles.length / 2), tiles.length)
                    .map((tile) => (
                        <QuickActionsTile key={tile.title} {...tile} />
                    ))}
            </div>
        </div>
    </div>
);

export default QuickActions;
