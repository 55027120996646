import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack, TextInput } from '../../components/fields';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';

const rewardMessageSchema = yup.object({
    rewardMessage: yup.string().min(3).required().default(''),
    linkedWebpage: yup.string().url('Please enter a valid URL').default(''),
});

type RewardMessageSchema = yup.InferType<typeof rewardMessageSchema>;

interface RewardMessageProps {
    rewardMessage: string;
    linkedWebpage: string;
    onNext: (args: { rewardMessage: string; linkedWebpage: string }) => void;
    onPrevious: (args: {
        rewardMessage: string;
        linkedWebpage: string;
    }) => void;
    onClose: () => void;
}

const RewardMessage: React.FunctionComponent<RewardMessageProps> = ({
    rewardMessage: initialRewardMessage,
    linkedWebpage: initialLinkedWebpage,
    onNext,
    onPrevious,
    onClose,
}) => {
    const rewardMessageForm = useForm<RewardMessageSchema>({
        resolver: yupResolver(rewardMessageSchema),
    });

    const onSubmit = (
        cb: RewardMessageProps['onNext'] | RewardMessageProps['onPrevious']
    ) =>
        rewardMessageForm.handleSubmit(({ rewardMessage, linkedWebpage }) => {
            cb({
                rewardMessage: rewardMessage as string,
                linkedWebpage: linkedWebpage as string,
            });
        });

    useEffect(() => {
        rewardMessageForm.reset({
            rewardMessage: initialRewardMessage,
            linkedWebpage: initialLinkedWebpage,
        });
    }, [initialRewardMessage, initialLinkedWebpage]);

    return (
        <Modal
            modalName={MODALS.CREATE_ASSIGNMENT}
            onClose={onClose}
            title="Reward Message"
            subtitle="This is the message that will be displayed to your user once their task(s) have been approved and they are eligible to receive their reward(s). Please provide instructions on how they can redeem their reward. There is also an opportunity for you to provide a link to a webpage."
            options={[
                {
                    text: 'Previous',
                    type: 'secondary',
                    onClick: () =>
                        onPrevious({
                            ...rewardMessageForm.getValues(),
                        }),
                },
                {
                    text: 'Almost Done',
                    type: 'primary',
                    onClick: onSubmit(onNext),
                },
            ]}
        >
            <Stack>
                <TextInput
                    form={rewardMessageForm}
                    name="rewardMessage"
                    label="Reward Message"
                    multiline
                />
                <TextInput
                    form={rewardMessageForm}
                    name="linkedWebpage"
                    label="Linked Webpage (optional)"
                />
            </Stack>
        </Modal>
    );
};

export default RewardMessage;
