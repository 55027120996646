import { useQuery, UseQueryOptions } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { TaskInterface } from '../../models/OrganizationTask';
import { getTaskByIdForEntity } from '../../services/api/orgTasksApi';
import { getTasks } from '../../services/api/tasksApi';

export const TASKS_QUERY_KEY = 'tasks';

const getTasksQueryOptions = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}): UseQueryOptions<TaskInterface[], Error> => ({
    queryKey: [TASKS_QUERY_KEY, { entityId, entityType }],
    queryFn: async () => {
        const { tasks } = await getTasks({ entityId, entityType })();
        return tasks;
    },
});

export const useTasksQuery = (
    {
        entityId,
        entityType,
    }: {
        entityId: string;
        entityType: ENUM_ENTITY_TYPE;
    },
    options: UseQueryOptions<TaskInterface[], Error> = {}
) =>
    useQuery({ ...getTasksQueryOptions({ entityId, entityType }), ...options });

export const TASK_BY_ID_QUERY_KEY = 'task_by_id';

export const useTaskByIdQuery = (
    {
        entityId,
        entityType,
        taskId,
    }: {
        entityId: string;
        entityType: string;
        taskId?: string | null;
    },
    options: UseQueryOptions<TaskInterface, Error> = {}
) =>
    useQuery<TaskInterface, Error>({
        queryKey: [TASK_BY_ID_QUERY_KEY, { entityId, entityType, taskId }],
        queryFn: async () => {
            if (!taskId) throw new Error('No task id provided');
            const { task } = await getTaskByIdForEntity({
                entityId,
                entityType,
                taskId,
            })();
            return task;
        },
        enabled: !!taskId,
        ...options,
    });
