import { useCallback, useEffect, useRef, useState } from 'react';

export const useForm = ({
    initialValues = {},
    onSubmit = () => {},
    validations = {},
}) => {
    const [values, setValues] = useState(initialValues || {});
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [onSubmitting, setOnSubmitting] = useState(false);
    const [onBlur, setOnBlur] = useState(false);

    const formRendered = useRef(true);

    useEffect(() => {
        if (formRendered.current) {
            setValues({ ...initialValues });
            setErrors({});
            setTouched({});
            setOnSubmitting(false);
            setOnBlur(false);
        }
        formRendered.current = false;
    }, [initialValues]);

    const validateField = useCallback(
        ({ required, pattern, custom } = {}, fieldValue) => {
            if (required?.value && !fieldValue)
                return {
                    valid: false,
                    error: required?.message
                        ? String(required?.message)
                        : 'Required',
                };

            if (pattern?.value && !RegExp(pattern?.value).test(fieldValue))
                return {
                    valid: false,
                    error: pattern?.message
                        ? String(pattern?.message)
                        : 'Not valid input',
                };

            if (custom?.isValid && !custom.isValid(fieldValue))
                return {
                    valid: false,
                    error: custom?.message
                        ? String(custom?.message)
                        : 'Not valid input',
                };

            return { valid: true, error: null };
        },
        []
    );

    const validateFields = useCallback(
        (fieldsValues = {}, fieldsValidations = {}) =>
            Object.entries(fieldsValidations).reduce(
                (validationResult, [field, fieldValidations]) => {
                    validationResult[field] = validateField(
                        fieldValidations,
                        fieldsValues[field]
                    );
                    validationResult.valid = validationResult.valid
                        ? validationResult[field].valid
                        : false;
                    return validationResult;
                },
                { valid: true }
            ),
        []
    );

    const getErrorMessages = useCallback(
        (validatedFields = {}) =>
            Object.entries(validatedFields).reduce(
                (updatedErrors, [field, validatedField]) => {
                    if (field !== 'valid' && !validatedField.valid)
                        updatedErrors[field] = validatedField?.error;
                    return updatedErrors;
                },
                {}
            ),
        []
    );

    const handleChange = (event, index, node, cName) => {
        const { target } = event;
        const { name, value } = target;
        event.persist();
        if (node) {
            if (cName) values[node][index][cName] = value;
            else values[node][index] = value;
            setValues({ ...values });
        } else if (event.target.type === 'checkbox') {
            const regEx = /Key: (.*)/;
            const label = event.target.labels[0].innerText.match(regEx)[1];
            const checkboxId = event.target.name;
            const checkedValues = values;
            if (event.target.checked) {
                checkedValues[checkboxId] = label;
            } else {
                checkedValues[checkboxId] = '';
            }
            setValues({ ...checkedValues });
        } else setValues({ ...values, [name]: value });
    };

    const handleBlur = (event) => {
        const { target } = event;
        const { name } = target;
        setTouched({ ...touched, [name]: true });
        const { valid, error } = validateField(validations[name], values[name]);

        if (!valid) {
            setErrors((prevValue) => ({ ...prevValue, [name]: error }));
            return;
        }

        const { [name]: _name, ...rest } = errors;

        setErrors({ ...rest });
    };

    const handleSubmit = (event) => {
        if (!!event) event.preventDefault();

        const { valid: isFormValid, ...validatedFields } = validateFields(
            values,
            validations
        );

        const errorMessages = getErrorMessages(validatedFields);
        setErrors(errorMessages);

        if (isFormValid && !!onSubmit) onSubmit({ values });
    };

    // New method
    const handleFormSubmit = (onSubmitFunc, onErrorFunc) => async (event) => {
        try {
            if (!!event) event.preventDefault();

            const { valid: isFormValid, ...validatedFields } = validateFields(
                values,
                validations
            );

            const errorMessages = getErrorMessages(validatedFields);
            setErrors(errorMessages);

            if (isFormValid && onSubmitFunc)
                await onSubmitFunc({ values, touched });
            if (isFormValid && !onSubmitFunc)
                throw new Error('No submit function provided');

            if (!isFormValid && onErrorFunc)
                await onErrorFunc({ values, errors: errorMessages, touched });
            if (!isFormValid && !onErrorFunc) throw new Error(errorMessages);
        } catch {
            console.error('Invalid form', errors);
        }
    };

    const setExistingValues = (valuesToSet) => {
        const sanitizedValues = Object.entries(valuesToSet).reduce(
            (result, [key, value]) => {
                if (key in initialValues)
                    result[key] =
                        typeof value === 'function'
                            ? value(values[key])
                            : value;
                return result;
            },
            {}
        );
        setValues((prev) => ({ ...prev, ...sanitizedValues }));
    };

    return {
        values,
        errors,
        touched,
        onSubmitting,
        onBlur,
        handleChange,
        handleBlur,
        handleSubmit,
        handleFormSubmit,
        setValues: setExistingValues,
    };
};
