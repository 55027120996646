import {RRule} from 'rrule';
import * as yup from 'yup';
import { SHOULD_BE_APPROVED_BY } from '../../constants/orgs';

export const ONE_TIME_FREQUENCY_OPTION = 'ONE_TIME';

export const taskValidationSchema = yup.object({
    name: yup
        .string()
        .required('Task name is required')
        .min(3, 'Task name must have at least 3 characters'),
    description: yup.string(),
    // frequency: yup
    //     .string()
    //     .oneOf([
    //         RRule.DAILY.toString(),
    //         RRule.WEEKLY.toString(),
    //         RRule.MONTHLY.toString(),
    //         ONE_TIME_FREQUENCY_OPTION,
    //     ]),
    // daysOfWeek: yup
    //     .array()
    //     .when('frequency', {
    //         is: ONE_TIME_FREQUENCY_OPTION,
    //         then: (schema) => schema,
    //         otherwise: (schema) =>
    //             schema
    //                 .min(1, 'At least one day must be selected')
    //                 .of(yup.string().oneOf(ALL_WEEKDAYS)),
    //     })
    //     .default([]),
    // nthInstanceOfDayOfWeekInMonth: yup
    //     .string()
    //     .nullable(true)
    //     .when('frequency', {
    //         is: RRule.MONTHLY.toString(),
    //         then: (schema) => schema.required('Select one option'),
    //         otherwise: (schema) => schema,
    //     }),
    // oneTimeDueDate: yup
    //     .string()
    //     .nullable(true)
    //     .when('frequency', {
    //         is: ONE_TIME_FREQUENCY_OPTION,
    //         then: (schema) =>
    //             schema.oneOf(Object.values(ONE_TIME_DUE_DATE)).nullable(false),
    //         otherwise: (schema) => schema,
    //     }),
    // start: yup
    //     .string()
    //     .matches(/^\d{4}-\d{2}-\d{2}$/)
    //     .required(),
    // end: yup
    //     .string()
    //     .matches(/^\d{4}-\d{2}-\d{2}$/)
    //     .required(),
    shouldBeApprovedBy: yup
        .array()
        .min(1, 'Select at least one option')
        .of(yup.mixed().oneOf(Object.values(SHOULD_BE_APPROVED_BY)))
        .default([]),
});

export type TaskFormValues = yup.InferType<typeof taskValidationSchema>;

export const taskFormDefaultValues = {
    name: '',
    description: '',
    frequency: RRule.DAILY.toString(),
    daysOfWeek: [],
    nthInstanceOfDayOfWeekInMonth: null,
    oneTimeDueDate: null,
    start: new Date().toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0],
    shouldBeApprovedBy: [],
};
