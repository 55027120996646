import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Row, TextInput } from '../components/fields';
import { baseRequest } from '../services/baseRequest';
import Modal from '../components/modals/Modal';
import { MODALS } from '../constants/common';
import appRepository from '../stores/AppDataStore';
import csvtojson from 'csvtojson';

const inviteFamiliesFormSchema = yup.object().shape({
    familyUnitEmails: yup
        .string()
        .required('Please enter a valid email address')
        .test({
            name: 'emails',
            test(value) {
                if (typeof value !== 'string') return false;
                const firstInvalidEmail = value
                    .split(',')
                    .map((email) => email.trim())
                    .find((v) => !yup.string().email().isValidSync(v));

                return !firstInvalidEmail
                    ? true
                    : this.createError({
                        message: `The email address '${firstInvalidEmail}' is invalid`,
                    });
            },
        }),
});

type InviteFamiliesFormSchema = yup.InferType<typeof inviteFamiliesFormSchema>;

const InviteNewFamilies: React.FunctionComponent<{
    parentOrgId: string;
    onAfterSubmit: () => void;
}> = ({ parentOrgId, onAfterSubmit }) => {
    const [jsonArray, setJsonArray] = useState<string[]>([]);
    const inviteFamiliesForm = useForm<InviteFamiliesFormSchema>({
        resolver: yupResolver(inviteFamiliesFormSchema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const onSubmit = inviteFamiliesForm.handleSubmit(async (values) => {
        const emailList = values.familyUnitEmails.split(',').map((e) => e.trim());


        const createFamilyOrgEnrollmentsRequest = {
            familyUnitEmails: emailList,
        };
        const apiResult = await baseRequest(
            `/orgenrollments/${parentOrgId}/addfamilyunits`,
            {
                body: createFamilyOrgEnrollmentsRequest,
                method: 'POST',
            }
        );

        onAfterSubmit();
    });

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            const fileContents = await selectedFile.text();
            const jsonArray = await csvtojson().fromString(fileContents);
            const emails = jsonArray.map((item) => item.email);
            setJsonArray(emails);
        } else {
            // Handle the case when no file is selected
            setJsonArray([]);
        }
    };
    const handleSubmit_Button = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (jsonArray.length > 0) {
            const s = jsonArray.join(',');
            inviteFamiliesForm.setValue('familyUnitEmails' as never, s as never);
        }
    };

    const onClose = () => {
        appRepository.setActiveModal(null);
        inviteFamiliesForm.reset();
    };

    return (
        <Modal
            modalName={MODALS.INVITE_FAMILIES}
            title="Invite New Families"
            options={[
                {
                    text: 'Cancel',
                    type: 'secondary',
                    onClick: onClose,
                },
                { text: 'Invite', type: 'primary', onClick: onSubmit },
            ]}
            onClose={onClose}
        >
            <Row>
                <TextInput
                    label="Parent Email Address (enter one or more, comma separated)"
                    placeholder="parent@domain.com, parent2@domain.com..."
                    form={inviteFamiliesForm}
                    name="familyUnitEmails"
                    fullWidth
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 20,
                        marginBottom: -10,
                    }}
                >
                    <h4 style={{marginRight:'5px'}}>CSV File Upload</h4>
                    <input type="file" accept=".csv" onChange={handleFileChange} />
                    <button
                        type="button"
                        style={
                            {
                                width: 180, 
                                borderRadius: 8 ,
                                borderWidth:1,
                                padding:'7px 0px 7px 0px',
                                color:'white',
                                backgroundColor:'#002759',
                                fontSize:'14px',
                                
                            }
                        }
                        onClick={handleSubmit_Button}
                    >
                        Upload
                    </button>
                </div>
            </Row>
        </Modal>
    );
};

export default InviteNewFamilies;