import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column, Row } from 'react-table';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import DashboardContentSection from '../../../../../components/DashboardContentSection';
import KKButton from '../../../../../components/KKButton';
import Navbar from '../../../../../components/Navbar';
import { ENUM_ENTITY_TYPE } from '../../../../../constants/common';
import { KKTableV2 } from '../../../../../forms/TaskFormModal/KKTable';
import { useAssignmentByIdQuery } from '../../../../../hooks/queries/assignments';
import useEntityAndParents from '../../../../../hooks/useEntityAndParents';
import { AssignmentInterface } from '../../../../../models/Assignment';
import { useTasksCompletionsByAssignmentIdQuery } from '../../../../../hooks/queries/taskCompletions';

const useApprovalRequestsTable = ({
    assignmentId,
    entityType,
    entityId,
}: {
    assignmentId: string;
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) => {
    const { data: ar } = useTasksCompletionsByAssignmentIdQuery({
        assignmentId,
        entityType,
        entityId,
    });
    const approvalRequests = [
        {
            childName: 'Bethany',
            taskName: 'task',
            approveOrDeny: 'd',
        },
    ];

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'childName',
                Header: 'Child name',
                Cell: ({
                    row: {
                        original: { childName },
                    },
                }: {
                    row: Row<any>;
                }) => <div>{childName}</div>,
            },
            {
                id: 'taskName',
                accessor: (approvalRequest) => approvalRequest.taskName,
                Header: 'Task Name',
            },
            {
                id: 'approveOrDeny',
                Header: () => (
                    <div className="flex w-full items-center justify-center gap-2">
                        Approve/Deny
                    </div>
                ),
                Cell: () => (
                    <div className="flex w-full items-center justify-center gap-2">
                        <KKButton type="primary">Approve</KKButton>
                        <KKButton type="danger">Deny</KKButton>
                    </div>
                ),
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () => (Array.isArray(approvalRequests) ? approvalRequests : []),
        [approvalRequests]
    );

    return { columns, data };
};
const useRewardsRedemptionsTable = () => {
    const rewardsRedemptions = [
        {
            childName: 'Martha',
            rewards: [
                {
                    name: 'Lemon Badge',
                    received: false,
                },
                {
                    name: 'Business Badge',
                    received: true,
                },
            ],
        },
        {
            childName: 'Martha',
            rewards: [
                {
                    name: 'Lemon Badge',
                    received: false,
                },
                {
                    name: 'Business Badge',
                    received: true,
                },
            ],
        },
    ];

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'childName',
                Header: () => <div>Child name</div>,
                accessor: (rewardRedemption) => rewardRedemption.childName,
            },
            {
                id: 'reward',

                Header: 'Reward',
                Cell: ({
                    row: {
                        original: { rewards },
                    },
                }: {
                    row: Row<any>;
                }) =>
                    rewards.map(
                        (reward: { name: string; received: boolean }) => (
                            <div className="mb-2">{reward.name}</div>
                        )
                    ),
            },
            {
                id: 'received',
                Header: 'Received?',
                Cell: ({
                    row: {
                        original: { rewards },
                    },
                }: {
                    row: Row<any>;
                }) =>
                    rewards.map(
                        (reward: { name: string; received: boolean }) => {
                            if (reward.received) {
                                return (
                                    <div className="mb-2 rounded-full px-2 text-xl text-green-600">
                                        ✓
                                    </div>
                                );
                            }
                            return (
                                <div className="mb-2 flex gap-1 pt-2">
                                    <button className="rounded-full bg-green-600  px-2 text-white">
                                        ✓
                                    </button>
                                    <button className="rounded-full bg-red-600  px-2 text-white">
                                        X
                                    </button>
                                </div>
                            );
                        }
                    ),
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () => (Array.isArray(rewardsRedemptions) ? rewardsRedemptions : []),
        [rewardsRedemptions]
    );

    return { columns, data };
};

const useAssigneesTable = () => {
    const assignees = [
        {
            childName: 'Bethany',
            status: 'In progress',
            redeemed: 'Not eligible!',
        },
        {
            childName: 'Martha',
            status: 'Complete',
            redeemed: 'Eligible!',
        },
    ];

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'childName',
                Header: () => <div>Child name</div>,
                Cell: ({
                    row: {
                        original: { childName },
                    },
                }: {
                    row: Row<any>;
                }) => <div>{childName}</div>,
            },
            {
                id: 'status',
                accessor: (assignee) => assignee.status,
                Header: () => <div className="">Status</div>,
            },
            {
                id: 'approveOrDeny',
                Header: () => <div className="">Reward(s) Redeemed</div>,
                Cell: ({
                    row: {
                        original: { redeemed },
                    },
                }: {
                    row: Row<any>;
                }) => <div className="">{redeemed}</div>,
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () => (Array.isArray(assignees) ? assignees : []),
        [assignees]
    );

    return { columns, data };
};

const getAssignmentInfoTableData = (
    assignmentProperty: string,
    assignment: AssignmentInterface | undefined
) => {
    if (!assignment) return [];
    switch (assignmentProperty) {
        case 'tasks':
            return assignment.tasks.map((assignedTask) => {
                if (typeof assignedTask.task !== 'string') {
                    return (
                        assignedTask.task.name ||
                        assignedTask.task.choreInformation?.name ||
                        ''
                    );
                }
                return '';
            });
        case 'qty':
            return assignment.tasks.map((task) => {
                if (typeof task.task !== 'string') {
                    return task.reps;
                }
                return '';
            });
        case 'rewards':
            return assignment.rewards.map((reward) => {
                if (typeof reward.reward !== 'string') {
                    return reward.reward.name;
                }
                return '';
            });

        default:
            return [];
    }
};

const Assignment = () => {
    const { organizationId, assignmentId } = useParams<{
        organizationId: string;
        assignmentId: string;
    }>();
    const { entityAndParents } = useEntityAndParents({
        entityId: organizationId,
        entityType: ENUM_ENTITY_TYPE.ORGANIZATION,
    });
    const { data: assignment } = useAssignmentByIdQuery({
        assignmentId,
        entityId: organizationId,
        entityType: ENUM_ENTITY_TYPE.ORGANIZATION,
    });

    const { columns: approvalRequestsColumns, data: approvalRequestsData } =
        useApprovalRequestsTable({
            assignmentId,
            entityType: ENUM_ENTITY_TYPE.ORGANIZATION,
            entityId: organizationId,
        });

    const { columns: assigneesColumns, data: assigneesData } =
        useAssigneesTable();

    const { columns: rewardsRedemptionsColumns, data: rewardsRedemptionsData } =
        useRewardsRedemptionsTable();

    return (
        <Navbar>
            <Breadcrumbs
                entities={entityAndParents}
                suffix={[
                    {
                        link: `/dashboard/organizations/${organizationId}/assignments`,

                        name: 'My Assignments',
                    },
                    { name: assignment?.name || '' },
                ]}
            />
            <DashboardContentSection>
                <div className="flex flex-col gap-2">
                    <h2>{assignment?.name}</h2>
                    <h3 className="text-lg">Overview</h3>
                </div>
            </DashboardContentSection>
            <DashboardContentSection>
                <div className="mx-auto mb-10 flex w-3/5 flex-col justify-between rounded-3xl border-2 border-solid border-black p-8">
                    <div className="flex w-full justify-between font-thin">
                        {['tasks', 'qty', 'rewards'].map((header, idx) => (
                            <div>
                                <h4
                                    key={`${header}-${idx}`}
                                    className="mb-6 text-2xl font-light uppercase tracking-widest"
                                >
                                    {header}
                                </h4>
                                {getAssignmentInfoTableData(
                                    header,
                                    assignment
                                ).map((data) => (
                                    <p className="text-center">{data}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </DashboardContentSection>
            <DashboardContentSection>
                <div className="mb-6 flex justify-center gap-20">
                    <div className="flex h-60 w-2/5 flex-col gap-4 rounded-2xl border-2 border-solid px-6 py-6">
                        <div className="flex justify-between">
                            <h2 className="px-4 text-xl">{`Awaiting Approval(${approvalRequestsData.length})`}</h2>
                            <KKButton type="primary">Approve All</KKButton>
                        </div>
                        <KKTableV2
                            columns={approvalRequestsColumns}
                            data={approvalRequestsData}
                        />
                    </div>
                    <div className="flex h-60 w-2/5 flex-col gap-4 rounded-2xl border-2 border-solid px-6 py-4">
                        <h2 className="px-4 text-xl">
                            Rewards for Redemptions
                        </h2>

                        <KKTableV2
                            columns={rewardsRedemptionsColumns}
                            data={rewardsRedemptionsData}
                        />
                    </div>
                </div>
            </DashboardContentSection>

            <div className="mx-6 my-4  flex h-60  gap-20 rounded-2xl border-4 bg-[#ebebeb] p-6">
                <h2 className="text-xl font-bold">Assignees</h2>
                <div className="mt-20 flex  w-full flex-col">
                    <KKTableV2
                        columns={assigneesColumns}
                        data={assigneesData}
                    />
                </div>
            </div>
        </Navbar>
    );
};

export default Assignment;
