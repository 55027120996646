import classNames from 'classnames';
import React from 'react';
import KKButton from '../KKButton';

interface HeaderButton {
    label: string;
    type: 'primary' | 'secondary';
    onClick: (...args: any) => any;
    disabled?: boolean;
}

interface DashboardCardDefaultHeaderProps {
    title?: string;
    headerButtons?: HeaderButton[];
}

type DashboardCardProps = DashboardCardDefaultHeaderProps & {
    containerClassName?: string;
    contentContainerClassName?: string;
    fullHeight?: boolean;
    children?: React.ReactNode;
    renderHeader?: (
        headerProps?: DashboardCardDefaultHeaderProps
    ) => React.ReactComponentElement<any, DashboardCardDefaultHeaderProps>;
};

const DashboardCardDefaultHeader: React.FunctionComponent<
    DashboardCardDefaultHeaderProps
> = ({ title, headerButtons = [] }) => (
    <div className="flex justify-between py-3">
        {!!title && <h3 className="mb-2 text-black">{title}</h3>}
        {headerButtons.length > 0 && (
            <div className="flex items-center">
                {headerButtons.map(
                    ({ label, type, onClick, disabled = false }) => (
                        <KKButton
                            key={`${label}-${type}`}
                            type={type || 'primary'}
                            onClick={onClick}
                            disabled={disabled}
                        >
                            {label}
                        </KKButton>
                    )
                )}
            </div>
        )}
    </div>
);

const DashboardCard: React.FunctionComponent<DashboardCardProps> = ({
    title,
    headerButtons = [],
    renderHeader = (headerProps) => (
        <DashboardCardDefaultHeader {...headerProps} />
    ),
    containerClassName = 'py-3 px-6',
    contentContainerClassName = 'py-3',
    fullHeight,
    children,
}) => (
    <div
        className={`grow rounded-md bg-white shadow-md ${containerClassName} ${classNames(
            {
                'h-full': fullHeight,
            }
        )}`}
    >
        <div>{renderHeader({ title, headerButtons })}</div>
        <div className={contentContainerClassName}>{children}</div>
    </div>
);

export default DashboardCard;
