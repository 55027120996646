import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Group from './groups/[:groupId]';
import GroupTasks from './groups/[:groupId]/Tasks';
import GroupDetails from './groups/[:groupId]/Details';
import Organization from './organizations/[:organizationId]';
import OrganizationDetails from './organizations/[:organizationId]/Details';
import GroupRewards from './groups/[:groupId]/Rewards';
import OrganizationRewards from './organizations/[:organizationId]/Rewards';
import OrganizationTasks from './organizations/[:organizationId]/Tasks';
import GroupAssignments from './groups/[:groupId]/Assignments';
import GroupAssignment from './groups/[:groupId]/assignments/[:assignmentId]';
import OrganizationAssignments from './organizations/[:organizationId]/Assignments';
import OrganizationAssignment from './organizations/[:organizationId]/assignments/[:assignmentId]';

const DashboardRouter = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${path}/organizations/:organizationId/assignments/:assignmentId`}
                render={() => <OrganizationAssignment />}
            />
            <Route
                path={`${path}/organizations/:organizationId/assignments`}
                render={() => <OrganizationAssignments />}
            />
            <Route
                path={`${path}/organizations/:organizationId/details`}
                render={() => <OrganizationDetails />}
            />
            <Route
                path={`${path}/organizations/:organizationId/tasks`}
                render={() => <OrganizationTasks />}
            />
            <Route
                path={`${path}/organizations/:organizationId/rewards`}
                render={() => <OrganizationRewards />}
            />
            <Route
                path={`${path}/organizations/:organizationId`}
                render={() => <Organization />}
            />
            <Route
                path={`${path}/groups/:groupId/assignments/:assignmentId`}
                render={() => <GroupAssignment />}
            />
            <Route
                path={`${path}/groups/:groupId/assignments`}
                render={() => <GroupAssignments />}
            />
            <Route
                path={`${path}/groups/:groupId/details`}
                render={() => <GroupDetails />}
            />
            <Route
                path={`${path}/groups/:groupId/tasks`}
                render={() => <GroupTasks />}
            />
            <Route
                path={`${path}/groups/:groupId/rewards`}
                render={() => <GroupRewards />}
            />
            <Route path={`${path}/groups/:groupId`} render={() => <Group />} />
            <Route path={`${path}`} render={() => <Dashboard />} />
        </Switch>
    );
};

export default DashboardRouter;
