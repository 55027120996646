import React from 'react';
import { Col, Row, Stack, TextInput } from '../../components/fields';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';
import { AlertFormData } from './shared';

const AlertReview: React.FunctionComponent<{
    formData: AlertFormData;
    onFinish: () => void;
    onPrevious: () => void;
    isCreateAlertLoading: boolean;
}> = ({ formData, onPrevious, onFinish, isCreateAlertLoading }) => (
    <Modal
        modalName={MODALS.CREATE_ALERT_FOR_FAMILIES}
        title="Alert Review"
        options={[
            { text: 'Previous', type: 'secondary', onClick: onPrevious },
            {
                text: 'Finish',
                type: 'primary',
                onClick: onFinish,
                disabled: isCreateAlertLoading,
            },
        ]}
    >
        <Stack>
            <Row>
                {`Once you submit this form all the families you selected will receive the following message`}
            </Row>
            <Row className="items-center justify-center">
                <Col size={8} className="px-2">
                    <TextInput
                        name="alertMessage"
                        value={formData.alertMessage}
                        multiline
                        disabled
                    />
                </Col>
            </Row>
        </Stack>
    </Modal>
);

export default AlertReview;
