import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { KK_API_URL } from '../../config';
import kkLogo from '../../../src/images/logos/kk-square-512.png';

const EntityTile: React.FunctionComponent<{
    logo?: string;
    title: string;
    body: string;
    to: string;
    rightContent?: () => React.ReactNode;
    expanded?: boolean;
    expandedContent?: () => React.ReactNode;
}> = ({
    logo,
    title,
    body,
    to,
    expanded,
    expandedContent = () => <></>,
    rightContent = () => <></>,
}) => {
    let [attempt, setAttempt] = useState(0);
    return (
        <div className="flex flex-col">
            <div className="flex justify-between">
                <Link className="flex w-full p-2" to={to}>
                    <div className="m-1.5 flex items-center justify-center">
                        {logo && (
                            <img
                                src={logo}
                                className="h-14 w-14 rounded-lg object-cover"
                                onError={(error) => {
                                    console.log(
                                        'Error fetching image ==> ',
                                        error
                                    );
                                    const imageElement =
                                        document.getElementById(
                                            'profile_pic'
                                        ) as HTMLImageElement;
                                    if (
                                        imageElement &&
                                        logo !== kkLogo &&
                                        attempt < 3
                                    ) {
                                        attempt++;
                                        setAttempt(attempt);
                                        imageElement.src = `${KK_API_URL}/uploads/${logo}`;
                                    }
                                }}
                            />
                        )}
                    </div>
                    <div className="m-1.5 flex flex-grow flex-col justify-center">
                        <h5 className="mb-1">{title}</h5>
                        <h6 className="mb-1">{body}</h6>
                    </div>
                </Link>
                <div className="flex items-center justify-center">
                    {rightContent()}
                </div>
            </div>
            {expanded && expandedContent()}
        </div>
    );
};

export default EntityTile;
