import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { Entity } from '../../models/Entity';
import { baseRequest } from '../baseRequest';

export function getEntityAndParents({
    entityType,
    entityId,
}: {
    entityType: ENUM_ENTITY_TYPE;
    entityId: string;
}) {
    return () =>
        baseRequest(`/entities/entity-and-parents/${entityType}/${entityId}`, {
            method: 'GET',
        }) as Promise<{
            entityAndParents: Entity[];
        }>;
}
