import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    Col,
    FormSection,
    Row,
    Stack,
    TextInput,
} from '../../components/fields';
import { LocalLoading } from '../../components/loading';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';
import {
    useCreateGroupMutation,
    useEditGroupMutation,
} from '../../hooks/mutations/groups';
import { useGroupByIdQuery } from '../../hooks/queries/groups';
import appRepository from '../../stores/AppDataStore';

const groupFormSchema = yup.object({
    name: yup.string().label('Group name').min(3).required().trim(),
    admin: yup.object({
        email: yup.string().label('Admin email').email().required(),
        firstName: yup.string().label('Admin first name').required(),
        lastName: yup.string().label('Admin last name').required(),
    }),
});

type GroupFormSchema = yup.InferType<typeof groupFormSchema>;

const GroupFormModal: React.FunctionComponent<{
    parentOrganizationId?: string;
    edit?: boolean;
    groupId?: string;
    onAfterSubmit?: () => void;
}> = ({
    parentOrganizationId,
    groupId,
    edit,
    onAfterSubmit = () => appRepository.setActiveModal(null),
}) => {
    const groupForm = useForm<GroupFormSchema>({
        resolver: yupResolver(groupFormSchema),
    });

    const groupQuery = useGroupByIdQuery(groupId, {
        enabled: !!edit && !!groupId,
        onSuccess: (data) => {
            groupForm.reset({
                name: data.basicInformation.name,
                admin: {
                    email: data.ownerEmail,
                    firstName: data.basicInformation.adminFirstName,
                    lastName: data.basicInformation.adminLastName,
                },
            });
        },
    });

    const createGroupMutation = useCreateGroupMutation({
        parentOrganizationId,
    });

    const editGroupMutation = useEditGroupMutation({ groupId });

    const onSubmit = useCallback(
        groupForm.handleSubmit(async (groupFormValues) => {
            try {
                const payload = {
                    basicInformation: {
                        groupName: groupFormValues.name,
                        adminFirstName: groupFormValues.admin.firstName,
                        adminLastName: groupFormValues.admin.lastName,
                    },
                    ownerEmail: groupFormValues.admin.email,
                };

                if (!edit) await createGroupMutation.mutateAsync(payload);
                if (edit) await editGroupMutation.mutateAsync(payload);

                if (!!onAfterSubmit) onAfterSubmit();
            } catch (error) {
                console.error(error);
            }
        }),
        [groupForm]
    );

    const onClose = useCallback(() => {
        appRepository.setActiveModal(null);
        groupForm.reset();
    }, [groupForm]);

    const loading = useMemo(
        () =>
            groupQuery.isFetching ||
            createGroupMutation.isLoading ||
            editGroupMutation.isLoading,
        [
            groupQuery.isFetching,
            createGroupMutation.isLoading,
            editGroupMutation.isLoading,
        ]
    );

    return (
        <Modal
            title="Group Form"
            modalName={MODALS.CREATE_GROUP}
            onClose={onClose}
            options={[
                { text: 'Cancel', type: 'secondary', onClick: onClose },
                { text: 'Submit', type: 'primary', onClick: onSubmit, disabled: loading },
            ]}
        >
            {loading && <LocalLoading />}
            <Stack>
                <FormSection title="Group">
                    <Row>
                        <TextInput
                            form={groupForm}
                            name="name"
                            label="Name"
                            variant="secondary"
                            fullWidth
                        />
                    </Row>
                </FormSection>
                <FormSection title="Admin">
                    <Stack>
                        <Row>
                            {[
                                {
                                    name: 'admin.firstName',
                                    label: 'First Name',
                                },
                                { name: 'admin.lastName', label: 'Last Name' },
                            ].map(({ name, label }) => (
                                <Col
                                    size={6}
                                    className="px-1 first:pl-0 last:pr-0"
                                    key={name}
                                >
                                    <TextInput
                                        form={groupForm}
                                        name={name}
                                        label={label}
                                        variant="secondary"
                                        fullWidth
                                    />
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <TextInput
                                form={groupForm}
                                name="admin.email"
                                label="Email"
                                variant="secondary"
                                fullWidth
                            />
                        </Row>
                    </Stack>
                </FormSection>
            </Stack>
        </Modal>
    );
};

export default GroupFormModal;
