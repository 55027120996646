// eslint-disable-next-line camelcase
import jwtDecode from 'jwt-decode';

export function getAccessToken() {
    return localStorage.getItem('accessToken');
}

export function setAccessToken(token) {
    localStorage.setItem('accessToken', token);
}

export function getIdToken() {
    return localStorage.getItem('idToken');
}

export function setIdToken(token) {
    localStorage.setItem('idToken', token);
}

export function clearStorage() {
    localStorage.clear();
}

export function isAuthenticated() {
    const idToken = getIdToken();
    if (!idToken || typeof idToken === 'undefined') return false;
    const decodedToken = jwtDecode(idToken);

    return decodedToken.exp < new Date().getTime();
}

// export function setUserBasicInfo(user) {
//     localStorage.setItem('firstName', user.given_name);
//     localStorage.setItem('LastName', user.family_name);
// }
