import { useEffect } from 'react';
import './NonAuth.css';
import kkLogo from '../../images/logos/kk-logo-combo.png';
import { KK_CONSUMER_BACKEND_API_URL } from '../../config';

export default function DeleteAccountOrData() {
    return (
        <div className="deleteContainer">
            <div className="centered-div">
                <div style={{ margin: 10, maxHeight: 300, maxWidth: 500 }}>
                    <img src={kkLogo} />
                </div>
                <form
                    id="myForm"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontFamily: 'Times New Roman, Times, serif',
                    }}
                    method="post"
                    onSubmit={deleteMyAccountOrTheData}
                >
                    <label
                        style={{ fontSize: 'large', margin: 10 }}
                        htmlFor="email"
                    >
                        Email:
                    </label>
                    <input
                        style={{
                            padding: '10px',
                            borderRadius: '10px',
                            borderWidth: '1px',
                        }}
                        type="email"
                        id="email"
                        name="email"
                        title="Enter your email id here."
                    />

                    <br />

                    <label
                        style={{ fontSize: 'large', margin: 10 }}
                        htmlFor="password"
                        h
                    >
                        Password:
                    </label>
                    <input
                        style={{
                            padding: '10px',
                            borderRadius: '10px',
                            borderWidth: '1px',
                        }}
                        type="password"
                        id="password"
                        name="password"
                        title="Enter your password here."
                    />

                    <br />
                    <label
                        style={{ fontSize: 'large', padding: '10px' }}
                        title="Choose any one"
                        for="options"
                    >
                        Select an option:
                    </label>
                    <select
                        style={{
                            padding: '10px',
                            borderRadius: '10px',
                            border: '1px solid grey',
                            fontSize: '18px',
                            backgroundColor: 'white',
                        }}
                        id="options"
                        name="options"
                    >
                        <option
                            value="option1"
                            title="Selecting this option will delete your account permanently."
                        >
                            Delete Account
                        </option>
                        <option
                            value="option2"
                            title="Selecting this option will delete any data associated with your account permanently,but not account itself."
                        >
                            Delete Data Only
                        </option>
                    </select>
                    <br />
                    <input
                        className="press-button"
                        style={{
                            padding: '10px',
                            borderRadius: '50px',
                            border: '1px solid grey',
                            fontSize: 'medium',
                        }}
                        type="submit"
                        value="Submit"
                    />
                </form>
            </div>
        </div>
    );
}

function deleteMyAccountOrTheData(event) {
    event.preventDefault();
    const formData = new FormData(document.getElementById('myForm'));
    const urlEncodedData = new URLSearchParams(formData).toString();

    console.log('calling deleteMyAccountOrTheData.......');

    fetch(`${KK_CONSUMER_BACKEND_API_URL}/request_account_deletion_web_page`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedData,
    })
        .then((response) => response.json())
        .then((data) => {
            // You can handle the API response here
            console.log('data ==> ', data);
            window.alert(data.error ? data.error : data.message);
            document.getElementById('myForm').reset();
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle errors here
        });
}
