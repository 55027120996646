import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { FieldError, Row, Stack } from '../components/fields';
import { KKCheckboxV2 } from '../components/fields/KKCheckbox';
import { LocalLoading } from '../components/loading';
import Modal from '../components/modals/Modal';
import { MODALS } from '../constants/common';
import { useEnrollChildrenToGroupMutation } from '../hooks/mutations/groups';
import { useEnrollableChildrenForGroupQuery } from '../hooks/queries/groups';
import { FamilyUnitChildInfoInterface } from '../models/FamilyUnit';
import appRepository from '../stores/AppDataStore';
import { getChildAge } from '../utils/familyUnitHelpers';

const enrollChildrenSchema = yup.object({
    selectedChildren: yup.array(yup.string()).min(1).required(),
});

type EnrollChildrenSchema = yup.InferType<typeof enrollChildrenSchema>;

const AddChildrenToGroupModal: React.FunctionComponent<{
    groupId: string;
    onAfterSubmit?: () => void;
}> = ({
    groupId,
    onAfterSubmit = () => appRepository.setActiveModal(null),
}) => {
    const enrollChildrenForm = useForm<EnrollChildrenSchema>({
        resolver: yupResolver(enrollChildrenSchema),
    });

    const selectedChildren = useWatch({
        name: 'selectedChildren',
        control: enrollChildrenForm.control,
        defaultValue: [],
    });

    const enrollableChildrenQuery = useEnrollableChildrenForGroupQuery(
        {
            groupId,
        },
        { refetchOnWindowFocus: false }
    );

    const enrollChildrenToGroupMutation = useEnrollChildrenToGroupMutation({
        groupId,
    });

    const loading = useMemo(
        () =>
            enrollableChildrenQuery.isFetching ||
            enrollChildrenToGroupMutation.isLoading,
        [
            enrollableChildrenQuery.isFetching,
            enrollChildrenToGroupMutation.isLoading,
        ]
    );

    const toggleChild = useCallback(
        (childCompoundKey) => () => {
            if (!Array.isArray(selectedChildren)) return;
            const shouldAdd = !selectedChildren.includes(childCompoundKey);
            enrollChildrenForm.setValue(
                'selectedChildren',
                shouldAdd
                    ? [...selectedChildren, childCompoundKey]
                    : selectedChildren.filter(
                          (selectedChild) => selectedChild !== childCompoundKey
                      )
            );
        },
        [selectedChildren]
    );

    const onClose = useCallback(() => {
        appRepository.setActiveModal(null);
        enrollChildrenForm.reset();
    }, []);

    const onSubmit = useCallback(
        enrollChildrenForm.handleSubmit(async (enrollChildrenFormValues) => {
            try {
                if (
                    !Array.isArray(enrollChildrenFormValues.selectedChildren) ||
                    enrollChildrenFormValues.selectedChildren.length === 0
                )
                    return;

                await enrollChildrenToGroupMutation.mutateAsync(
                    enrollChildrenFormValues.selectedChildren as string[]
                );

                enrollChildrenForm.reset();
                if (!!onAfterSubmit) onAfterSubmit();
            } catch (error) {
                console.error(error);
            }
        }),
        [enrollChildrenForm, enrollChildrenToGroupMutation]
    );

    return (
        <Modal
            title="Children Enrollment"
            modalName={MODALS.ADD_CHILDREN_TO_GROUP}
            onClose={onClose}
            options={[
                { text: 'Cancel', type: 'secondary', onClick: onClose },
                {
                    text: 'Submit',
                    type: 'primary',
                    onClick: onSubmit,
                    loading,
                },
            ]}
        >
            {loading && <LocalLoading />}
            <Stack>
                <div>
                    <h4>Please select children to enroll in group:</h4>
                    <FieldError
                        errors={enrollChildrenForm.formState.errors}
                        field="selectedChildren"
                    />
                </div>
                {Array.isArray(enrollableChildrenQuery.data) &&
                    enrollableChildrenQuery.data.length > 0 &&
                    enrollableChildrenQuery.data.map(
                        (child: FamilyUnitChildInfoInterface) => (
                            <Row key={child.compoundKey}>
                                <Controller
                                    control={enrollChildrenForm.control}
                                    name="selectedChildren"
                                    render={({ field }) => (
                                        <KKCheckboxV2
                                            name={field.name}
                                            value={child.compoundKey}
                                            onChange={toggleChild(
                                                child.compoundKey
                                            )}
                                            state={
                                                Array.isArray(
                                                    selectedChildren
                                                ) &&
                                                selectedChildren.includes(
                                                    child.compoundKey
                                                )
                                                    ? 'checked'
                                                    : 'unchecked'
                                            }
                                            renderLabel={() => (
                                                <div className="flex-col">
                                                    {[
                                                        {
                                                            label: 'Name',
                                                            value: child.name,
                                                        },
                                                        {
                                                            label: 'Age',
                                                            value: getChildAge(
                                                                child.dob
                                                            ),
                                                        },
                                                    ].map(
                                                        ({ label, value }) => (
                                                            <div className="py-1 first:pt-0 last:pb-0">
                                                                <span className="font-extrabold">{`${label}: `}</span>
                                                                <span>{`${value}`}</span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        />
                                    )}
                                />
                            </Row>
                        )
                    )}
            </Stack>
        </Modal>
    );
};

export default AddChildrenToGroupModal;
