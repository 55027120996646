import React from 'react';

import RegistrationPage from '../RegistrationPage';

const ForgotPassword = () => {
    const forgotPasswordForm = (
        <form action="" className="form-modal-form">
            <label className="form-label" htmlFor="forgot-password">
                Email
            </label>
            <input
                className="form-input"
                type="text"
                id="forgot-password"
                name="forgot-password"
                placeholder="Email"
            />
        </form>
    );

    return (
        <RegistrationPage
            form={forgotPasswordForm}
            title="Forgot Password?"
            handleSubmit={null}
            btn="Reset My Password"
        />
    );
};

export default ForgotPassword;
