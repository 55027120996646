import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import DashboardContentSection from '../../../components/DashboardContentSection';
import EntityListItem from '../../../components/EntityListItem';
import KKButton from '../../../components/KKButton';
import ListItem from '../../../components/ListItem';
import Loading from '../../../components/loading';
import Modal from '../../../components/modals/Modal';
import Navbar from '../../../components/Navbar';
import QuickActions, {
    quickActionLgTileFactory,
    quickActionMdTileFactory,
} from '../../../components/QuickActions';
import DashboardCard from '../../../components/tiles/DashboardCard';
import DashboardTiles from '../../../components/tiles/DashboardTiles';
import EntityTile from '../../../components/tiles/EntityTile';
import { navTiles } from '../../../components/tiles/navTiles';
import {
    ALERT_SENDER_TYPE,
    ENUM_ENTITY_TYPE,
    MODALS,
} from '../../../constants/common';
import AlertModal from '../../../forms/AlertFormModal';
import AssignmentModal from '../../../forms/AssignmentFormModal';
import GroupFormModal from '../../../forms/GroupFormModal';
import InviteNewFamilies from '../../../forms/InviteNewFamilies';
import OrganizationFormModal from '../../../forms/OrganizationFormModal';
import SendNewAlertForm from '../../../forms/SendNewAlertForm';
import {
    ARCHIVE_ORGANIZATION_MUTATION_TYPE,
    useArchiveOrganizationMutation,
} from '../../../hooks/mutations/organizations';
import { useEntityAndParentsQuery } from '../../../hooks/queries/entities';
import { GROUPS_QUERY_TYPE } from '../../../hooks/queries/groups';
import {
    ORGANIZATIONS_QUERY_TYPE,
    useEnrolledFamilyCountQuery,
} from '../../../hooks/queries/organizations';
import useGroupsList from '../../../hooks/useGroupsList';
import useOrganizationsList from '../../../hooks/useOrganizationsList';
import {
    ARCHIVE_GROUP_MUTATION_TYPE,
    useArchiveGroupMutation,
} from '../../../hooks/mutations/groups';
import appRepository from '../../../stores/AppDataStore';
import Alerts from '../../../components/Alerts';

const Organization = () => {
    const history = useHistory();
    const { pathname: currentPathname } = useLocation();
    const { organizationId } = useParams<{ organizationId: string }>();

    const entityAndParents = useEntityAndParentsQuery({
        entityId: organizationId,
        entityType: ENUM_ENTITY_TYPE.ORGANIZATION,
    });

    const suborganizationsList = useOrganizationsList({
        type: ORGANIZATIONS_QUERY_TYPE.BY_PARENT_ID,
        parentOrganizationId: organizationId,
    });

    const groupsList = useGroupsList({
        type: GROUPS_QUERY_TYPE.BY_PARENT_ID,
        parentOrganizationId: organizationId,
    });

    const enrolledFamilyUnitsCount =
        useEnrolledFamilyCountQuery(organizationId);

    const organization = useMemo(() => {
        if (!entityAndParents.data) return null;
        const lastEntity =
            entityAndParents.data[entityAndParents.data.length - 1];
        if (lastEntity?.type !== ENUM_ENTITY_TYPE.ORGANIZATION) return null;
        return lastEntity.data;
    }, [entityAndParents.data]);

    const [expandedTile, setExpandedTile] = useState<string | null>(null);

    const expandTile = (tileId: string) => () =>
        setExpandedTile(expandedTile === tileId ? null : tileId);

    const { mutateAsync: archiveOrganization } = useArchiveOrganizationMutation(
        {
            type: ARCHIVE_ORGANIZATION_MUTATION_TYPE.AS_ORGANIZATION_ADMIN,
        },
        { onSuccess: () => setExpandedTile(null) }
    );

    const { mutateAsync: archiveGroup } = useArchiveGroupMutation(
        {
            type: ARCHIVE_GROUP_MUTATION_TYPE.AS_ORGANIZATION_ADMIN,
        },
        { onSuccess: () => setExpandedTile(null) }
    );

    if (entityAndParents.isLoading) {
        return (
            <Navbar>
                <Loading />
            </Navbar>
        );
    }

    return (
        <Navbar>
            <Breadcrumbs entities={entityAndParents.data} />
            <DashboardContentSection>
                <h2>
                    {!!organization && organization.basicInformation.orgName}
                </h2>
                <KKButton
                    type="text"
                    onClick={() => history.push(`${currentPathname}/details`)}
                >
                    View details
                </KKButton>
            </DashboardContentSection>
            <DashboardContentSection>
                <DashboardTiles
                    tiles={[
                        navTiles.subOrganizations(
                            suborganizationsList.data?.filter(
                                ({ archived }) => !archived
                            ).length
                        ),
                        navTiles.groups(
                            groupsList.data?.filter(({ archived }) => !archived)
                                .length
                        ),
                        navTiles.familyUnits(enrolledFamilyUnitsCount.data),
                    ].filter(({ title }) =>
                        title === 'Sub-Organizations'
                            ? Array.isArray(suborganizationsList.data) &&
                              suborganizationsList.data.length > 0
                            : true
                    )}
                />
            </DashboardContentSection>
            <DashboardContentSection>
                <DashboardCard title="Quick Actions">
                    <QuickActions
                        tiles={[
                            quickActionMdTileFactory.inviteNewFamilies(() =>
                                appRepository.setActiveModal(
                                    MODALS.INVITE_FAMILIES
                                )
                            ),
                            quickActionMdTileFactory.alertFamilies(() =>
                                appRepository.setActiveModal(
                                    MODALS.CREATE_ALERT_FOR_FAMILIES
                                )
                            ),
                            quickActionMdTileFactory.myTasks(() =>
                                history.push(`${currentPathname}/tasks`)
                            ),
                            quickActionMdTileFactory.myRewards(() =>
                                history.push(`${currentPathname}/rewards`)
                            ),
                        ]}
                        middleTile={quickActionLgTileFactory.createAssignment(
                            () =>
                                appRepository.setActiveModal(
                                    MODALS.CREATE_ASSIGNMENT
                                ),
                            () => history.push(`${currentPathname}/assignments`)
                        )}
                    />
                </DashboardCard>
                {!!organization && (
                    <AssignmentModal
                        entity={{
                            data: organization,
                            type: ENUM_ENTITY_TYPE.ORGANIZATION,
                        }}
                    />
                )}
            </DashboardContentSection>
            <DashboardContentSection>
                <div className="flex w-full ">
                    <Alerts
                        entityType={ENUM_ENTITY_TYPE.ORGANIZATION}
                        entityId={organizationId}
                    />
                </div>
            </DashboardContentSection>

            {Array.isArray(entityAndParents.data) &&
                entityAndParents.data.length === 1 && (
                    <DashboardContentSection
                        title="Sub-Organizations"
                        headerButtons={[
                            {
                                label: '+ New Sub-Organization',
                                type: 'primary',
                                onClick: () =>
                                    appRepository.setActiveModal(
                                        MODALS.CREATE_ORGANIZATION
                                    ),
                            },
                            {
                                label: suborganizationsList.showArchived
                                    ? 'Hide Archived'
                                    : 'Show Archived',
                                type: 'secondary',
                                onClick:
                                    suborganizationsList.toggleShowArchived,
                            },
                        ]}
                        loading={suborganizationsList.isFetching}
                    >
                        {Array.isArray(suborganizationsList.data) &&
                            suborganizationsList.data.length > 0 &&
                            suborganizationsList.data
                                .sort(
                                    (a, b) =>
                                        Number(a.archived) - Number(b.archived)
                                )
                                .filter(({ archived }) =>
                                    suborganizationsList.showArchived
                                        ? true
                                        : !archived
                                )
                                .map((entity) => (
                                    <EntityListItem
                                        entity={{
                                            data: entity,
                                            type: ENUM_ENTITY_TYPE.ORGANIZATION,
                                        }}
                                        expanded={expandedTile === entity._id}
                                        options={[
                                            {
                                                iconName: 'pencil',
                                                onClick: expandTile(entity._id),
                                                bgColorClassName:
                                                    'bg-kk-background-blue',
                                            },
                                        ]}
                                        expandedOptions={[
                                            {
                                                iconName: 'archive-outline',
                                                iconColorClassName:
                                                    'text-kk-status-red',
                                                label: entity.archived
                                                    ? 'Unarchive'
                                                    : 'Archive',
                                                onClick: () =>
                                                    archiveOrganization(
                                                        entity._id
                                                    ),
                                            },
                                        ]}
                                    />
                                ))}
                    </DashboardContentSection>
                )}
            <DashboardContentSection
                title="Groups"
                loading={groupsList.isFetching}
                headerButtons={[
                    {
                        label: '+ New Group',
                        type: 'primary',
                        onClick: () =>
                            appRepository.setActiveModal(MODALS.CREATE_GROUP),
                    },
                    {
                        label: groupsList.showArchived
                            ? 'Hide Archived'
                            : 'Show Archived',
                        type: 'secondary',
                        onClick: groupsList.toggleShowArchived,
                    },
                ]}
            >
                {Array.isArray(groupsList.data) &&
                    groupsList.data.length > 0 &&
                    groupsList.data
                        .sort((a, b) => Number(a.archived) - Number(b.archived))
                        .filter(({ archived }) =>
                            groupsList.showArchived ? true : !archived
                        )
                        .map((entity) => (
                            <EntityListItem
                                entity={{
                                    data: entity,
                                    type: ENUM_ENTITY_TYPE.GROUP,
                                }}
                                expanded={expandedTile === entity._id}
                                options={[
                                    {
                                        iconName: 'pencil',
                                        onClick: expandTile(entity._id),
                                        bgColorClassName:
                                            'bg-kk-background-blue',
                                    },
                                ]}
                                expandedOptions={[
                                    {
                                        iconName: 'archive-outline',
                                        iconColorClassName:
                                            'text-kk-status-red',
                                        label: entity.archived
                                            ? 'Unarchive'
                                            : 'Archive',
                                        onClick: () => archiveGroup(entity._id),
                                    },
                                ]}
                            />
                        ))}
            </DashboardContentSection>
            {!!organization && (
                <AlertModal
                    entity={{
                        data: organization,
                        type: ENUM_ENTITY_TYPE.ORGANIZATION,
                    }}
                />
            )}
            <InviteNewFamilies
                parentOrgId={organizationId}
                onAfterSubmit={() => appRepository.setActiveModal(null)}
            />
            <OrganizationFormModal
                rootOrganizationId={organizationId}
                onAfterSubmit={() => appRepository.setActiveModal(null)}
            />
            <GroupFormModal parentOrganizationId={organizationId} />
        </Navbar>
    );
};

export default observer(Organization);
