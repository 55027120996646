import { useParams } from 'react-router-dom';
import EntityTasks from '../../../../components/EntityTasks';
import { ENUM_ENTITY_TYPE } from '../../../../constants/common';

const OrganizationTasks = () => {
    const { organizationId } = useParams<{ organizationId: string }>();

    return (
        <EntityTasks
            entityId={organizationId}
            entityType={ENUM_ENTITY_TYPE.ORGANIZATION}
        />
    );
};

export default OrganizationTasks;
