import { useQuery, UseQueryOptions } from 'react-query';
import { OrganizationInterface } from '../../models/Organization';
import {
    getOrganizations,
    getSuborganizationsByParentId,
    getSubOrgsApi as getAllSubOrgs,
    getTotalEnrolledFamilyUnitsCount,
    getOrganizationById,
} from '../../services/api/orgApi';
import { getFamUnitsForOrg } from '../../services/api/familyUnitApi';
import { IFamilyUnit } from '../../models/FamilyUnit';

export enum ORGANIZATIONS_QUERY_TYPE {
    ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS',
    BY_PARENT_ID = 'BY_PARENT_ID',
    ALL_SUBORGANIZATIONS = 'ALL_SUBORGANIZATIONS',
}

export type OrganizationsQueryParams = { params?: { showArchived?: boolean } };

export type OrganizationsQuery = (
    | {
          type: ORGANIZATIONS_QUERY_TYPE.ALL_ORGANIZATIONS;
          parentOrganizationId?: undefined;
      }
    | {
          type: ORGANIZATIONS_QUERY_TYPE.ALL_SUBORGANIZATIONS;
          parentOrganizationId?: undefined;
      }
    | {
          type: ORGANIZATIONS_QUERY_TYPE.BY_PARENT_ID;
          parentOrganizationId: string;
      }
) &
    OrganizationsQueryParams;

export type OrganizationsQueryOptions<TData = OrganizationInterface[]> = Omit<
    UseQueryOptions<OrganizationInterface[], Error, TData>,
    'queryKey' | 'queryFn'
>;

export const ORGANIZATIONS_QUERY_KEY = 'organizations';

export const getOrganizationsQueryOptions = <TData = OrganizationInterface[]>(
    query: OrganizationsQuery,
    options: OrganizationsQueryOptions<TData> = {}
): UseQueryOptions<OrganizationInterface[], Error, TData> => ({
    queryKey: [ORGANIZATIONS_QUERY_KEY, query],
    queryFn: async () => {
        switch (query.type) {
            case ORGANIZATIONS_QUERY_TYPE.ALL_ORGANIZATIONS: {
                const { organizations } = await getOrganizations(query.params);
                return organizations;
            }
            case ORGANIZATIONS_QUERY_TYPE.BY_PARENT_ID: {
                const { organizations } = await getSuborganizationsByParentId(
                    query.parentOrganizationId,
                    query.params
                )();
                return organizations;
            }
            case ORGANIZATIONS_QUERY_TYPE.ALL_SUBORGANIZATIONS: {
                const { organizations } = await getAllSubOrgs(query.params);
                return organizations;
            }
            default:
                throw new Error('Invalid query type');
        }
    },
    ...options,
});

export const useOrganizationsQuery = <TData = OrganizationInterface[]>(
    query: OrganizationsQuery,
    options: OrganizationsQueryOptions<TData> = {}
) => useQuery({ ...getOrganizationsQueryOptions(query, options) });

export const ORGANIZATION_BY_ID_QUERY_KEY = 'organization_by_id';

export const getOrganizationByIdQueryOptions = <TData = OrganizationInterface>(
    organizationId: string | undefined,
    options: Omit<
        UseQueryOptions<OrganizationInterface, Error, TData>,
        'queryFn' | 'queryKey'
    > = {}
): UseQueryOptions<OrganizationInterface, Error, TData> => ({
    queryKey: [ORGANIZATION_BY_ID_QUERY_KEY, { organizationId }],
    queryFn: async () => {
        if (!organizationId) throw new Error('No organization ID provided');
        const { organization } = await getOrganizationById(organizationId)();
        return organization;
    },
    enabled: !!organizationId,
    ...options,
});

export const useOrganizationByIdQuery = <TData = OrganizationInterface>(
    organizationId: string | undefined,
    options: Omit<
        UseQueryOptions<OrganizationInterface, Error, TData>,
        'queryFn' | 'queryKey'
    > = {}
) => useQuery({ ...getOrganizationByIdQueryOptions(organizationId, options) });

export const ORGANIZATIONS_FAMILY_UNIT_COUNT_QUERY_KEY = 'familyUnitCountQuery';

const getEnrolledFamilyCountQueryOptions = (organizationId?: string) => ({
    queryKey: [ORGANIZATIONS_FAMILY_UNIT_COUNT_QUERY_KEY, organizationId],
    queryFn: async () => {
        const { totalEnrolledFamilyUnitsCount } =
            await getTotalEnrolledFamilyUnitsCount(organizationId)();
        return totalEnrolledFamilyUnitsCount;
    },
});

export const useEnrolledFamilyCountQuery = (organizationId?: string) =>
    useQuery({ ...getEnrolledFamilyCountQueryOptions(organizationId) });

export const ORG_ENROLLED_FAMILY_UNITS_QUERY_KEY = 'org_enrolled_family_units';

export const useOrgEnrolledFamilyUnitsQuery = (
    organizationId: string,
    options?: UseQueryOptions<IFamilyUnit[], Error>
) =>
    useQuery<IFamilyUnit[], Error>({
        queryKey: [ORG_ENROLLED_FAMILY_UNITS_QUERY_KEY, organizationId],
        queryFn: async () => {
            if (!organizationId) throw new Error('No organizationId provided');
            const familyUnits = await getFamUnitsForOrg(organizationId)();
            return familyUnits;
        },
        enabled: !!organizationId,
        ...options,
    });
