import React, { useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { ENUM_ENTITY_TYPE, MODALS, ALERT_TYPE } from '../constants/common';
import { useAlertsCreatedQuery } from '../hooks/queries/alerts';
import appRepository from '../stores/AppDataStore';
import KKIonIcon from './KKIonIcon';

import KKTable from './KKTable';
import DashboardCard from './tiles/DashboardCard';
import ApprovalRequests from '../components/ApprovalRequests';

const useAlertsTable = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) => {
    const { data: alerts } = useAlertsCreatedQuery({
        entityId,
        entityType,
    });

    const columns: Column<any>[] = useMemo(
        () => [
            {
                id: 'name',
                accessor: (alert) =>
                    alert.notificationBody.split(':')[1]
                        ? alert.notificationBody.split(':')[1]
                        : alert.notificationBody.split(':')[0],
                Header: 'Alert',
            },
            {
                id: 'sent',
                accessor: (alert) => new Date(alert.timeStamp).toDateString(),
                Header: 'Sent',
            },
            {
                id: 'opened',
                Header: 'Opened',
                Cell: ({ row: { original: alert } }: CellProps<any>) =>
                    alert.readBy.length > 0 ? (
                        <KKIonIcon
                            customStrokeWidth
                            name={`checkmark-outline`}
                            className={`stroke-extrabold text-kk-status-green transition-all `}
                        />
                    ) : (
                        <KKIonIcon
                            customStrokeWidth
                            name={`remove-outline`}
                            className={`stroke-extrabold text-kk-status-red transition-all `}
                        />
                    ),
            },
        ],
        []
    );

    const data: any[] = useMemo(
        () =>
            Array.isArray(alerts)
                ? alerts.filter((alert) => alert.notificationBody)
                : [],
        [alerts]
    );

    return { columns, data };
};

const Alerts: React.FunctionComponent<{
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}> = ({ entityId, entityType }) => {
    const { columns, data } = useAlertsTable({
        entityId,
        entityType,
    });

    let orgAlerts: any[] = [],
        rewardRedemptionAlerts: any[] = [];
    for (const alert of data) {
        if (
            alert.type === ALERT_TYPE.ORG_ALERT ||
            alert.type === ALERT_TYPE.ORG_INVITATION ||
            alert.type === ALERT_TYPE.GROUP_ALERT
        ) {
            orgAlerts.push(alert);
        } else if (
            alert.type === ALERT_TYPE.REWARD_REDEMPTION &&
            alert?.reward.assignment
        ) {
            rewardRedemptionAlerts.push(alert);
        }
    }

    const columnsForRewardRedemption: Column<any>[] = useMemo(
        () => [
            {
                id: 'Assignment',
                accessor: (alert) => alert?.reward?.assignment,

                Header: 'Assignment',
            },
            {
                id: 'Alert',
                accessor: (alert) =>
                    alert.notificationBody.split(':')[1]
                        ? alert.notificationBody.split(':')[1]
                        : alert.notificationBody.split(':')[0],
                Header: 'Alert',
            },
            {
                id: 'redeemed_on',
                accessor: (alert) =>
                    new Date(alert?.reward?.redeemedOn).toDateString(),
                Header: 'Redeemed',
            },
        ],
        []
    );

    const [activeTab, setActiveTab] = useState(
        entityType === ENUM_ENTITY_TYPE.GROUP ? 'tab1' : 'tab2'
    );

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'tab1': {
                if (entityType === ENUM_ENTITY_TYPE.GROUP) {
                    return <ApprovalRequests groupId={entityId} />;
                } else {
                    return null;
                }
            }

            case 'tab2':
                return <OrgAlerts orgAlerts={orgAlerts} columns={columns} />;

            case 'tab3':
                return (
                    <RewardRedemptionAlerts
                        rewardRedemptionAlerts={rewardRedemptionAlerts}
                        columns={columnsForRewardRedemption}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="grow rounded-md bg-white shadow-md">
            <div
                style={{
                    display: 'flex',
                }}
            >
                <button
                    style={
                        activeTab === 'tab1'
                            ? {
                                  ...style.active,
                                  display:
                                      entityType === ENUM_ENTITY_TYPE.GROUP
                                          ? 'inherit'
                                          : 'none',
                              }
                            : {
                                  ...style.button,
                                  display:
                                      entityType === ENUM_ENTITY_TYPE.GROUP
                                          ? 'inherit'
                                          : 'none',
                              }
                    }
                    onClick={() => handleTabChange('tab1')}
                >
                    Approvals
                </button>
                <button
                    style={activeTab === 'tab2' ? style.active : style.button}
                    onClick={() => handleTabChange('tab2')}
                >
                    My Alerts
                </button>

                <button
                    style={activeTab === 'tab3' ? style.active : style.button}
                    onClick={() => handleTabChange('tab3')}
                >
                    Reward-Redemption Alerts
                </button>
            </div>
            <div style={style.separator}></div>
            <div className="tab-content">{renderTabContent()}</div>
        </div>
    );
};

const OrgAlerts = ({
    orgAlerts,
    columns,
}: {
    orgAlerts: any;
    columns: any;
}) => {
    return (
        <DashboardCard
            headerButtons={[
                {
                    label: '+ New Alert',
                    type: 'primary',
                    onClick: () =>
                        appRepository.setActiveModal(
                            MODALS.CREATE_ALERT_FOR_FAMILIES
                        ),
                },
            ]}
            contentContainerClassName="py-3 overflow-auto h-60"
        >
            {orgAlerts.length > 0 ? (
                <KKTable columns={columns} data={orgAlerts} />
            ) : (
                <p className=" text-md text-kk-dark-green">
                    You don't have any recent alert
                </p>
            )}
        </DashboardCard>
    );
};

const RewardRedemptionAlerts = ({
    rewardRedemptionAlerts,
    columns,
}: {
    rewardRedemptionAlerts: any;
    columns: any;
}) => {
    return (
        <DashboardCard contentContainerClassName="py-3 overflow-auto h-60">
            {rewardRedemptionAlerts.length > 0 ? (
                <KKTable columns={columns} data={rewardRedemptionAlerts} />
            ) : (
                <p className=" text-md text-kk-dark-green">
                    You don't have any recent alert
                </p>
            )}
        </DashboardCard>
    );
};

const style = {
    button: {
        padding: 15,
        backgroundColor: 'white',
        margin: '0px 5px 0px 5px',
        fontWeight: 'bold',
        color: 'black',
    },

    active: {
        padding: 15,
        backgroundColor: 'rgb(69, 183, 178)',

        margin: '0px 5px 0px 20px',
        color: 'white',
        fontWeight: 'bold',
    },
    separator: {
        borderTop: '1px solid #ccc',
        margin: 0,
    },
};

export default Alerts;
