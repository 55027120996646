import React, { useCallback, useMemo, useState } from 'react';
import { Column, Row } from 'react-table';
import { ENUM_ENTITY_TYPE, MODALS } from '../constants/common';
import RewardFormModal from '../forms/RewardFormModal';
import { KKTableV2 } from '../forms/TaskFormModal/KKTable';
import { useRewardsQuery } from '../hooks/queries/rewards';
import useEntityAndParents from '../hooks/useEntityAndParents';
import { OrganizationInterface } from '../models/Organization';
import { RewardInterface } from '../models/OrganizationReward';
import appRepository from '../stores/AppDataStore';
import Breadcrumbs from './Breadcrumbs';
import DashboardContentSection from './DashboardContentSection';
import KKButton from './KKButton';
import KKIonIcon from './KKIonIcon';
import Navbar from './Navbar';

const useRewardsTable = ({
    entityId,
    entityType,
    onEditRewardClick,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
    onEditRewardClick: (rewardId: string) => void;
}) => {
    const { data: rewards } = useRewardsQuery({
        entityId,
        entityType,
    });

    const columns: Column<RewardInterface>[] = useMemo(
        () => [
            {
                id: 'name',
                accessor: (reward) => reward.name,
                Header: 'Name',
            },
            {
                id: 'cost',
                accessor: (reward) => reward.cost,
                Header: 'Cost per unit',
                Cell: ({ value }: { value: RewardInterface['cost'] }) =>
                    `${value?.currency} ${value?.amount}`,
            },
            {
                id: 'availableStock',
                accessor: (reward) => reward.availableStock,
                Header: 'Available for redemption',
                Cell: ({
                    value,
                }: {
                    value: RewardInterface['availableStock'];
                }) =>
                    !!value &&
                    !Number.isNaN(Number(value)) &&
                    Number.isFinite(Number(value))
                        ? Number(value).toString()
                        : 'Unlimited',
            },
            {
                id: 'updatedAt',
                accessor: (reward) => reward.updatedAt,
                Header: 'Updated',
                Cell: ({ value }: { value: string }) =>
                    new Date(value).toLocaleDateString(),
            },
            {
                id: 'createdAt',
                accessor: (reward) => reward.createdAt,
                Header: 'Created',
                Cell: ({ value }: { value: string }) =>
                    new Date(value).toLocaleDateString(),
            },
            {
                id: 'options',
                Header: () => (
                    <div className="flex w-full items-center justify-center">
                        Options
                    </div>
                ),
                Cell: ({
                    row: {
                        original: { _id: rewardId },
                    },
                }: {
                    row: Row<RewardInterface>;
                }) => (
                    <div className="flex w-full items-center justify-center">
                        <KKButton
                            type="secondary"
                            onClick={() => onEditRewardClick(rewardId)}
                        >
                            <KKIonIcon
                                name="pencil-outline"
                                className="text-xl text-kk-dark-blue"
                            />
                        </KKButton>
                    </div>
                ),
            },
        ],
        []
    );

    const data: RewardInterface[] = useMemo(
        () => (Array.isArray(rewards) ? rewards : []),
        [rewards]
    );

    return { columns, data };
};

const EntityRewards: React.FunctionComponent<{
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}> = ({ entityId, entityType }) => {
    const [selectedReward, setSelectedReward] = useState<string | null>(null);
    const { entityAndParents } = useEntityAndParents({
        entityId,
        entityType,
    });
    const { columns, data } = useRewardsTable({
        entityId,
        entityType,
        onEditRewardClick: (rewardId) => {
            setSelectedReward(rewardId);
            appRepository.setActiveModal(MODALS.CREATE_REWARD);
        },
    });

    const entity = useMemo(
        () =>
            !!entityAndParents
                ? ([...entityAndParents].pop()?.data as OrganizationInterface)
                : null,
        [entityAndParents]
    );

    const closeRewardModal = useCallback(() => {
        setSelectedReward(null);
        appRepository.setActiveModal(null);
    }, [appRepository]);

    React.useEffect(() => {
    }, [selectedReward]);

    return (
        <Navbar>
            <Breadcrumbs
                entities={entityAndParents}
                suffix={[{ link: '/rewards', name: 'Rewards' }]}
            />
            <DashboardContentSection>
                {!!entity && <h2>{entity.basicInformation.name}</h2>}
            </DashboardContentSection>
            <DashboardContentSection
                title="Rewards"
                headerButtons={[
                    {
                        label: '+ Add Rewards',
                        type: 'primary',
                        onClick: () =>
                            appRepository.setActiveModal(MODALS.CREATE_REWARD),
                    },
                ]}
            >
                <KKTableV2 columns={columns} data={data} />
            </DashboardContentSection>
            <RewardFormModal
                entityId={entityId}
                entityType={entityType}
                modalName={MODALS.CREATE_REWARD}
                onModalClose={closeRewardModal}
                onAfterSubmit={closeRewardModal}
                edit={!!selectedReward}
                rewardId={selectedReward}
            />
        </Navbar>
    );
};

export default EntityRewards;
