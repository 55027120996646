import { Event } from '@sentry/react';
import userRepository from '../stores/UserDataStore';
import { env } from './index';

export const sentryConfig = {
    dsn: 'https://6ef9f1a936f44b2580a7156575f1ed7f@o1178104.ingest.sentry.io/6301646',
    maxBreadcrumbs: 100,
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
    attachStacktrace: true,
    environment: env,
    beforeSend(event: Event) {
        event.user = {
            email: userRepository?.email || 'unknown',
        };

        return event;
    },
};
