export enum SHOULD_BE_APPROVED_BY {
    GROUP_ADMIN = 'GROUP_ADMIN',
    FAMILYUNIT_ADMIN = 'FAMILYUNIT_ADMIN',
}

export enum SHOULD_BE_APPROVED_BY_DISPLAY {
    GROUP_ADMIN = 'Group admin',
    FAMILYUNIT_ADMIN = 'Family Admin',
}

export enum ORGANIZATION_TASK_SIZE {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
}

export enum ORGANIZATION_TASK_SIZE_DISPLAY {
    SMALL = '< 10 minutes',
    MEDIUM = '10 - 30 minutes',
    LARGE = '30+ minutes',
}
