import { useQuery } from 'react-query';
import { ENUM_ENTITY_TYPE } from '../../constants/common';
import { getEntityAndParents } from '../../services/api/entityApi';
import userRepository from '../../stores/UserDataStore';
import orgRepository from '../../stores/OrgDataStore';
import groupRepository from '../../stores/GroupDataStore';

export const ENTITY_AND_PARENTS_QUERY_KEY = 'entity-and-parents';

export const useEntityAndParentsQuery = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: ENUM_ENTITY_TYPE;
}) =>
    useQuery(
        [ENTITY_AND_PARENTS_QUERY_KEY, { entityId, entityType }],
        async () => {
            const { entityAndParents } = await getEntityAndParents({
                entityId,
                entityType,
            })();

            let basicInfo = {};
            let entityAndParentsData: any = {};
            if (entityType == ENUM_ENTITY_TYPE.ORGANIZATION) {
                entityAndParentsData = entityAndParents[0].data;
                orgRepository.setOrgData(entityAndParentsData);

                basicInfo = {
                    entityId: entityId,
                    entityType: entityType,
                    entityName: entityAndParentsData.basicInformation.orgName,
                    entityLogo: entityAndParentsData.basicInformation?.logo,
                    firstName:
                        entityAndParentsData.basicInformation.contactFirstName,
                    lastName:
                        entityAndParentsData.basicInformation.contactLastName,
                    email: entityAndParentsData.basicInformation.contactEmail,
                    parentEntity: entityAndParentsData.parentOrgId,
                };

                if (userRepository.role == 'ORG ADMIN')
                    userRepository.setBasicInfo(basicInfo);
            }

            return entityAndParents;
        }
    );
