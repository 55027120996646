import classNames from 'classnames';
import React, { ReactElement, useEffect, useRef } from 'react';
import { IonIcons } from 'react-ion-icon';
import KKIonIcon from '../../KKIonIcon';

export const KKCheckbox = ({
    // @ts-ignore
    id,
    // @ts-ignore
    name,
    // @ts-ignore
    className,
    // @ts-ignore
    checked,
    // @ts-ignore
    handleBlur,
    // @ts-ignore
    handleChange,
    // @ts-ignore
    title,
    // @ts-ignore
    value,
    ...props
}) => (
    <label htmlFor={id} className="kkcheckbox">
        <input
            type="checkbox"
            name={name}
            className={className}
            id={id}
            checked={checked}
            value={value}
            onBlur={handleBlur}
            onChange={handleChange}
            {...props}
        />
        {title}
    </label>
);

export interface KKCheckboxV2Props {
    name: string;
    id?: string;
    renderIcon?: () => ReactElement;
    label?: string;
    renderLabel?: (args: {
        id?: string;
        renderIcon?: () => ReactElement;
        label?: string;
        size?: 'sm' | 'md' | 'lg';
    }) => ReactElement;
    subLabel?: string;
    labelPosition?: 'top' | 'right' | 'bottom' | 'left';
    value: any;
    className?: string;
    state: 'checked' | 'unchecked' | 'indeterminate';
    size?: 'sm' | 'md' | 'lg';
    onBlur?: () => void;
    onChange?: () => void;
}

const DefaultKKCheckboxV2Label: React.FunctionComponent<{
    id?: string;
    renderIcon?: () => ReactElement;
    label?: string;
    size?: 'sm' | 'md' | 'lg';
}> = ({ label, size, id, renderIcon }) => (
    <label
        htmlFor={id}
        className={`flex items-center ${classNames({
            'text-sm': size === 'sm',
            'text-md': size === 'md',
            'text-lg': size === 'lg',
        })}`}
    >
        {renderIcon && renderIcon()}
        {label}
    </label>
);

export const KKCheckboxV2: React.FunctionComponent<KKCheckboxV2Props> = ({
    name,
    id = name,
    label,
    renderLabel = (options) => {
        return (
            <DefaultKKCheckboxV2Label
                id={options.id}
                label={options.label}
                size={options.size}
                renderIcon={options.renderIcon}
            />
        );
    },
    renderIcon,
    labelPosition = 'right',
    value,
    state,
    onBlur,
    onChange,
    size = 'md',
}) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!checkboxRef.current) return;
        checkboxRef.current.indeterminate = state === 'indeterminate';
    }, [state]);

    return (
        <div
            className={`flex items-center gap-2 ${classNames({
                'flex-col': labelPosition === 'top',
                'flex-row-reverse justify-end': labelPosition === 'right',
                'flex-col-reverse justify-end': labelPosition === 'bottom',
                'flex-row': labelPosition === 'left',
            })}`}
        >
            {renderLabel({ id, label, size, renderIcon })}
            <div className="group relative flex min-h-min items-center justify-center">
                <input
                    ref={checkboxRef}
                    type="checkbox"
                    name={name}
                    className={`z-10 cursor-pointer appearance-none border-2 border-solid border-kk-dark-blue ${classNames(
                        {
                            'h-3 w-3 rounded-sm': size === 'sm',
                            'h-4 w-4 rounded-md': size === 'md',
                            'h-5 w-5 rounded-md': size === 'lg',
                        }
                    )}`}
                    id={id}
                    checked={state === 'checked'}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                />
                {[
                    { iconName: 'checkmark-outline', showOn: 'checked' },
                    { iconName: 'remove-outline', showOn: 'indeterminate' },
                ].map(({ iconName, showOn }) => (
                    <div
                        key={`${iconName}`}
                        className="absolute flex h-full w-full items-center justify-center"
                    >
                        <KKIonIcon
                            customStrokeWidth
                            name={iconName as IonIcons}
                            className={`stroke-extrabold text-kk-dark-blue transition-all ${classNames(
                                {
                                    'scale-0': showOn !== state,
                                    'scale-100': showOn === state,
                                }
                            )}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
