import React from 'react';
import { Col, Row, Stack, TextInput } from '../../components/fields';
import { LocalLoading } from '../../components/loading';
import Modal from '../../components/modals/Modal';
import { MODALS } from '../../constants/common';
import { AssignmentFormData } from './shared';

const AssignmentReview: React.FunctionComponent<{
    formData: AssignmentFormData;
    onFinish: () => void;
    onPrevious: () => void;
    isLoading?: boolean;
}> = ({
    formData,
    onPrevious,
    onFinish,
    isLoading: isParentComponentLoading,
}) => (
    <Modal
        modalName={MODALS.CREATE_ASSIGNMENT}
        title="Assignment Review"
        options={[
            { text: 'Previous', type: 'secondary', onClick: onPrevious },
            {
                text: 'Finish',
                type: 'primary',
                onClick: onFinish,
                disabled: isParentComponentLoading,
            },
        ]}
    >
        {isParentComponentLoading && <LocalLoading />}
        <Stack>
            <Row>Upon completion of...</Row>
            {formData.selectedTasks.map(({ id, name, reps }) => (
                <Row
                    className="items-center justify-center"
                    key={`${id}-${name}`}
                >
                    <Col size={6} className="px-2">
                        <TextInput name={id} value={name} disabled />
                    </Col>
                    <Col size={1} className="px-2">
                        <TextInput name={id} value={reps} disabled />
                    </Col>
                    <Col size={1} className="px-2">
                        {reps > 1 ? 'times' : 'time'}
                    </Col>
                </Row>
            ))}
            <Row>
                {`Once the
                ${
                    formData.selectedTasks.length > 1
                        ? 'tasks have'
                        : 'task has'
                } been approved, they will receive the following message`}
            </Row>
            <Row className="items-center justify-center">
                <Col size={8} className="px-2">
                    <TextInput
                        name="rewardMessage"
                        value={formData.rewardMessage}
                        multiline
                        disabled
                    />
                </Col>
            </Row>
            <Row>
                {`Regarding their ${
                    formData.selectedRewards.length > 1 ? 'rewards' : 'reward'
                }`}
            </Row>
            {formData.selectedRewards.map(({ id, name }) => (
                <Row
                    className="items-center justify-center"
                    key={`${id}-${name}`}
                >
                    <Col size={8}>
                        <TextInput name={id} value={name} disabled />
                    </Col>
                </Row>
            ))}
        </Stack>
    </Modal>
);

export default AssignmentReview;
