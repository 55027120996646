import { useParams } from 'react-router-dom';
import EntityRewards from '../../../../components/EntityRewards';
import { ENUM_ENTITY_TYPE } from '../../../../constants/common';

const OrganizationRewards = () => {
    const { organizationId } = useParams<{ organizationId: string }>();

    return (
        <EntityRewards
            entityId={organizationId}
            entityType={ENUM_ENTITY_TYPE.ORGANIZATION}
        />
    );
};

export default OrganizationRewards;
