import classNames from 'classnames';
import React from 'react';
import {
    Controller,
    DeepMap,
    DeepPartial,
    FieldError as FieldErrorInterface,
    FieldErrors,
    UseFormReturn,
} from 'react-hook-form';
import { KKCheckboxV2, KKCheckboxV2Props } from './KKCheckbox/index';

const labelClasses = (labelVariant: 'primary' | 'secondary') =>
    `font-nunito ${classNames({
        'font-extrabold': labelVariant === 'primary',
        'text-sm text-black/50': labelVariant === 'secondary',
    })}`;

const getErrorForField = (field: any, errors: any) =>
    field.split('.').reduce((result: any, key: any) => {
        if (!!result && typeof result === 'object' && !!result[key])
            return result[key];
        return null;
    }, errors);

export const FieldError = <T extends Record<string, any>>({
    errors,
    field,
    className = '',
}: {
    errors: FieldErrors<T>;
    field: keyof DeepMap<DeepPartial<T>, FieldErrorInterface>;
    className?: string;
}): React.ReactElement<any, any> | null => {
    const errorElement = getErrorForField(field.toString(), errors) as DeepMap<
        DeepPartial<T>,
        FieldErrorInterface
    >;
    return (
        <>
            {!!errorElement && (
                <div className={`text-xs text-kk-status-red ${className}`}>
                    {errorElement.message}
                </div>
            )}
        </>
    );
};

export const FormSection: React.FunctionComponent<{
    title: string;
    spacing?: 'sm' | 'md' | 'lg';
}> = ({ title, spacing = 'sm', children }) => (
    <div
        className={`flex flex-col ${classNames({
            'gap-2': spacing === 'sm',
            'gap-4': spacing === 'md',
            'gap-6': spacing === 'lg',
        })}`}
    >
        <label className={labelClasses('primary')}>{title}</label>
        <div>{children}</div>
    </div>
);

interface TextBaseProps {
    name: string;
    label?: string;
    placeholder?: string;
    type?: string;
    form?: UseFormReturn<any>;
    variant?: 'primary' | 'secondary';
    errorMessage?: string | null;
    size?: 'sm' | 'md' | 'lg';
    disabled?: boolean;
    controlled?: boolean;
    hiddenErrorMessage?: boolean;
    fullWidth?: boolean;
}

type MultilineTextInput = TextBaseProps & { multiline?: true } & Partial<
    Omit<
        React.DetailedHTMLProps<
            React.TextareaHTMLAttributes<HTMLTextAreaElement>,
            HTMLTextAreaElement
        >,
        keyof TextBaseProps
    >
>;

type OneLineTextInput = TextBaseProps & { multiline?: false } & Partial<
    Omit<
        React.DetailedHTMLProps<
            React.InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >,
        keyof TextBaseProps
    >
>;

export const TextInput: React.FunctionComponent<
    MultilineTextInput | OneLineTextInput
> = ({
    name,
    label,
    placeholder,
    type = 'text',
    form,
    disabled,
    variant = 'primary',
    multiline = false,
    errorMessage,
    hiddenErrorMessage = false,
    size = 'md',
    controlled = false,
    fullWidth = false,
    ...htmlInputProps
}) => (
        <div className={`flex flex-col ${classNames({ 'w-full': fullWidth })}`}>
            <div className="flex items-center justify-between">
                {!!label && (
                    <label className={`${labelClasses(variant)} mb-1`}>
                        {label}
                    </label>
                )}
                {!hiddenErrorMessage && (
                    <FieldError
                        errors={{}}
                        field={!!form?.formState.errors ? name : 'error'}
                        className="mb-1"
                    />
                )}
            </div>
            {!multiline && (
                <input
                    className={`appearance-none rounded-md border-[1px] border-solid p-2 focus:border-2 ${classNames(
                        {
                            'focus:border-kk-dark-blue': !!form
                                ? !getErrorForField(name, form.formState.errors)
                                : !errorMessage,
                            'border-kk-status-red placeholder:text-kk-status-red/50':
                                !!form
                                    ? !!getErrorForField(
                                        name,
                                        form.formState?.errors
                                    )
                                    : !!errorMessage,
                            'h-6': size === 'sm',
                            'h-8': size === 'md',
                            'h-10': size === 'lg',
                        }
                    )}`}
                    type={type}
                    placeholder={placeholder || label}
                    disabled={disabled}
                    {...(!!form && !controlled
                        ? form.register(name)
                        : (htmlInputProps as Omit<
                            React.DetailedHTMLProps<
                                React.InputHTMLAttributes<HTMLInputElement>,
                                HTMLInputElement
                            >,
                            keyof TextBaseProps
                        >))}
                />
            )}
            {multiline && (
                <textarea
                    className={`appearance-none rounded-md border-[1px] border-solid p-2 focus:border-2  ${classNames(
                        {
                            'focus:border-kk-dark-blue': !!form
                                ? !form.formState.errors?.[name]
                                : !errorMessage,
                            'border-kk-status-red placeholder:text-kk-status-red/50':
                                !!form
                                    ? !!form.formState?.errors?.[name]
                                    : !!errorMessage,
                        }
                    )}`}
                    disabled={disabled}
                    placeholder={placeholder || label}
                    rows={4}
                    {...(!!form
                        ? form.register(name)
                        : (htmlInputProps as Omit<
                            React.DetailedHTMLProps<
                                React.TextareaHTMLAttributes<HTMLTextAreaElement>,
                                HTMLTextAreaElement
                            >,
                            keyof TextBaseProps
                        >))}
                />
            )}
        </div>
    );

export const Stack: React.FunctionComponent<{
    spacing?: 'sm' | 'md' | 'lg';
    direction?: 'column' | 'row';
    className?: string;
    fullWidth?: boolean;
}> = ({
    spacing = 'sm',
    direction = 'column',
    children,
    className = '',
    fullWidth = false,
}) => (
        <div
            className={`flex ${classNames({
                'gap-4': spacing === 'sm',
                'gap-6': spacing === 'md',
                'gap-8': spacing === 'lg',
                'flex-col': direction !== 'row',
                'w-full': fullWidth,
            })} ${className}`}
        >
            {children}
        </div>
    );

interface ControlledCheckboxSelectionProps {
    name: string;
    label: string;
    options: {
        label: string;
        renderIcon?: KKCheckboxV2Props['renderIcon'];
        value: string;
        labelPosition?: KKCheckboxV2Props['labelPosition'];
        size?: KKCheckboxV2Props['size'];
    }[];
    form: UseFormReturn<any>;
    multiple?: boolean;
    variant?: 'primary' | 'secondary';
}

export const ControlledCheckboxSelection: React.FunctionComponent<ControlledCheckboxSelectionProps> =
    ({ form, name, label, options, multiple = false, variant = 'primary' }) => {
        const selectedOptions: string[] | string = form.watch(name);

        const handleSelectionFor = (value: string) => () => {
            if (!form) return;

            if (!multiple && !Array.isArray(selectedOptions)) {
                form.setValue(name, value);
            }

            if (!multiple && Array.isArray(selectedOptions)) {
                form.setValue(name, [value]);
            }

            if (multiple && Array.isArray(selectedOptions)) {
                const shouldAdd = !selectedOptions?.includes(value);
                form.setValue(
                    name,
                    shouldAdd
                        ? [...selectedOptions, value]
                        : selectedOptions.filter(
                            (selectedOption) => selectedOption !== value
                        )
                );
            }
        };

        const getCheckboxState = (value: string) => {
            if (!Array.isArray(selectedOptions))
                return selectedOptions === value ? 'checked' : 'unchecked';
            if (Array.isArray(selectedOptions))
                return selectedOptions.includes(value)
                    ? 'checked'
                    : 'unchecked';
            return 'unchecked';
        };

        const SIZE_NUMBER_VALUE = {
            sm: 0,
            md: 1,
            lg: 2,
        };

        const biggestCheckboxSize = options
            .map(({ size }) => size || 'md')
            .reduce((biggest, size) =>
                SIZE_NUMBER_VALUE[biggest] > SIZE_NUMBER_VALUE[size]
                    ? biggest
                    : size
            );

        return (
            <>
                {!!form && (
                    <div
                        className={`flex flex-col ${classNames({
                            'gap-1': biggestCheckboxSize === 'sm',
                            'gap-2': biggestCheckboxSize === 'md',
                            'gap-3': biggestCheckboxSize === 'lg',
                        })}`}
                    >
                        <label className={labelClasses(variant)}>{label}</label>
                        <div className="flex justify-evenly">
                            {options.map((option) => (
                                <div
                                    key={`${option.label}-checkbox`}
                                    className="flex flex-col items-center justify-center gap-1"
                                >
                                    <Controller
                                        control={form.control}
                                        name={name}
                                        render={() => (
                                            <KKCheckboxV2
                                                name={name}
                                                label={option.label}
                                                renderIcon={option.renderIcon}
                                                labelPosition={
                                                    option.labelPosition
                                                }
                                                state={getCheckboxState(
                                                    option.value
                                                )}
                                                onChange={handleSelectionFor(
                                                    option.value
                                                )}
                                                value={option.value}
                                                size={option.size}
                                            />
                                        )}
                                    />
                                </div>
                            ))}
                        </div>
                        <FieldError
                            field={name}
                            errors={form.formState.errors}
                        />
                    </div>
                )}
            </>
        );
    };

export const Row: React.FunctionComponent<{ className?: string }> = ({
    className = '',
    children,
}) => (
    <div
        className={`flex flex-wrap ${className} ${classNames({
            'w-full': !className,
        })}`}
    >
        {children}
    </div>
);

export const Col: React.FunctionComponent<{
    size?: number | 'stretch';
    className?: string;
}> = ({ size = 1, className = '', children }) => (
    <div
        className={`${className} ${classNames({
            'w-1/12': size === 1,
            'w-2/12': size === 2,
            'w-3/12': size === 3,
            'w-4/12': size === 4,
            'w-5/12': size === 5,
            'w-6/12': size === 6,
            'w-7/12': size === 7,
            'w-8/12': size === 8,
            'w-9/12': size === 9,
            'w-10/12': size === 10,
            'w-11/12': size === 11,
            'w-full': size >= 12,
            'grow': size === 'stretch',
        })}`}
    >
        {children}
    </div>
);
